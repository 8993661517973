import { DefaultContainerVariants, defaultContainerVariants } from 'jexity/packages/components/container/containerApi';

export const containerVariants: DefaultContainerVariants = {
  ...defaultContainerVariants,
  thin: {
    px: ['1.5rem', '6rem', null, null, null, null, '9rem'],
    maxW: ['calc( 736px + 3rem )', 'calc( 736px + 12rem )', null, null, null, null, 'calc( 736px + 18rem )'],
  },
  normal: {
    px: ['1.1562rem', '3rem', null, null, null, null, '4rem'], // 1.1562rem = 18.95px
    maxW: ['calc(1120px + 2.3124rem)', 'calc(1120px + 6rem)', null, null, null, null, 'calc(1496px + 8rem)'],
  },
  wide: {
    margin: '0 auto',
    px: ['1.1562rem', '2.75rem', null, null, null, null, '3.75rem'],
    maxW: ['calc(1920px + 2.3124rem)', 'calc(1920px + 5.5rem)', null, null, null, null, 'calc(1920px + 7.5rem)'],
  },
};
