import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { TranslationsContent } from './api';

interface TranslationContextData {
  translations: TranslationsContent | null;
  setTranslations: Dispatch<SetStateAction<TranslationsContent>>;
}

const defaultData: TranslationContextData = {
  translations: null,
  setTranslations: () => {
    return;
  },
};

const TranslationContext = createContext<TranslationContextData>(defaultData);

interface TranslationContextProviderProps {
  initialTranslations?: TranslationsContent | null;
}

export const TranslationContextProvider: FC<TranslationContextProviderProps> = ({
  initialTranslations = null,
  children,
}) => {
  const [translations, setTranslations] = useState<TranslationsContent | null>(initialTranslations);
  useEffect(() => {
    setTranslations(initialTranslations);
  }, [initialTranslations, setTranslations]);

  return (
    <TranslationContext.Provider value={{ translations, setTranslations }}>{children}</TranslationContext.Provider>
  );
};

export const useTranslationsContext = (): TranslationContextData => useContext(TranslationContext);
