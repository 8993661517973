export const galleryGrid = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3327 15.3332C25.5418 15.3332 27.3327 17.124 27.3327 19.3332V23.3332C27.3327 25.5423 25.5418 27.3332 23.3327 27.3332H19.3327C17.1235 27.3332 15.3327 25.5423 15.3327 23.3332V19.3332C15.3327 17.124 17.1235 15.3332 19.3327 15.3332H23.3327ZM8.66602 15.3332C10.8752 15.3332 12.666 17.124 12.666 19.3332V23.3332C12.666 25.5423 10.8752 27.3332 8.66602 27.3332H4.66602C2.45688 27.3332 0.666016 25.5423 0.666016 23.3332V19.3332C0.666016 17.124 2.45688 15.3332 4.66602 15.3332H8.66602ZM23.3327 17.9998H19.3327C18.5963 17.9998 17.9993 18.5968 17.9993 19.3332V23.3332C17.9993 24.0696 18.5963 24.6665 19.3327 24.6665H23.3327C24.0691 24.6665 24.666 24.0696 24.666 23.3332V19.3332C24.666 18.5968 24.0691 17.9998 23.3327 17.9998ZM8.66602 17.9998H4.66602C3.92964 17.9998 3.33268 18.5968 3.33268 19.3332V23.3332C3.33268 24.0696 3.92964 24.6665 4.66602 24.6665H8.66602C9.4024 24.6665 9.99935 24.0696 9.99935 23.3332V19.3332C9.99935 18.5968 9.4024 17.9998 8.66602 17.9998ZM8.66602 0.666504C10.8752 0.666504 12.666 2.45736 12.666 4.6665V8.6665C12.666 10.8756 10.8752 12.6665 8.66602 12.6665H4.66602C2.45688 12.6665 0.666016 10.8756 0.666016 8.6665V4.6665C0.666016 2.45736 2.45688 0.666504 4.66602 0.666504H8.66602ZM23.3327 0.666504C25.5418 0.666504 27.3327 2.45736 27.3327 4.6665V8.6665C27.3327 10.8756 25.5418 12.6665 23.3327 12.6665H19.3327C17.1235 12.6665 15.3327 10.8756 15.3327 8.6665V4.6665C15.3327 2.45736 17.1235 0.666504 19.3327 0.666504H23.3327ZM8.66602 3.33317H4.66602C3.92964 3.33317 3.33268 3.93012 3.33268 4.6665V8.6665C3.33268 9.40288 3.92964 9.99984 4.66602 9.99984H8.66602C9.4024 9.99984 9.99935 9.40288 9.99935 8.6665V4.6665C9.99935 3.93012 9.4024 3.33317 8.66602 3.33317ZM23.3327 3.33317H19.3327C18.5963 3.33317 17.9993 3.93012 17.9993 4.6665V8.6665C17.9993 9.40288 18.5963 9.99984 19.3327 9.99984H23.3327C24.0691 9.99984 24.666 9.40288 24.666 8.6665V4.6665C24.666 3.93012 24.0691 3.33317 23.3327 3.33317Z"
      fill="currentColor"
    />
  </svg>
);
