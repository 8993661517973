import { IconProps } from '@chakra-ui/icon';
import { ComponentWithAs } from '@chakra-ui/system';
import { DeLanguageIcon, EnglishLanguageIcon } from './icons';

export type LanguageKey = 'de' | 'en';

export interface Language {
  key: LanguageKey;
  label: string;
  icon: ComponentWithAs<'svg', IconProps>;
  isDefault: boolean;
}

export const LANGUAGES: Language[] = [
  {
    key: 'en',
    label: 'english',
    icon: EnglishLanguageIcon,
    isDefault: false,
  },
  {
    key: 'de',
    label: 'deutsch',
    icon: DeLanguageIcon,
    isDefault: true,
  },
];

export const DEFAULT_LANGUAGE = LANGUAGES.find(({ isDefault }) => isDefault)?.key ?? 'de';
