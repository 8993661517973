import { Box, Flex, Heading, Input, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useDebounce } from 'jexity/packages/components/debounce/useDebounce';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { FC, useEffect, useState } from 'react';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';

export const PickKilometerContent: FC = () => {
  const { headings, paragraph } = useBaseTheme();
  const [mileage, setMileage] = useState('');
  const [year, setYear] = useState('');
  const { setFormAdditionalValues } = useFormContext();
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { pickKilometerLabel, pickKilometerTitle, pickRegistrationYearLabel } =
    requestCarPage?.requestCarFormSection ?? {};

  const debouncedMileage = useDebounce(mileage, 250);
  const debouncedYear = useDebounce(year, 250);

  useEffect(() => {
    setFormAdditionalValues((prev) => ({ ...prev, mileage: debouncedMileage }));
  }, [debouncedMileage, setFormAdditionalValues]);

  useEffect(() => {
    setFormAdditionalValues((prev) => ({ ...prev, registrationYear: debouncedYear }));
  }, [debouncedYear, setFormAdditionalValues]);

  return (
    <Box minH="420px">
      <Box maxWidth={545} textAlign={'center'} margin={'0 auto'} mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {pickKilometerTitle}
        </Heading>
      </Box>
      <Flex flexDir={['column', null, 'row']} justifyContent="center">
        <Box mr={[0, null, '2rem']} mb={['2rem', null, 0]}>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickKilometerLabel}
          </Text>
          <Input
            onChange={(event) => setMileage(event.target.value)}
            minW="256px"
            mt={2}
            placeholder={pickKilometerLabel}
            maxW={['100%', null, '256px']}
            h="auto"
            borderRadius={0}
            p={4}
            value={mileage}
            type="number"
            bg="white"
            color="gray.500"
            border="1px solid #A1A1A1"
          />
        </Box>
        <Box>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickRegistrationYearLabel}
          </Text>
          <Input
            onChange={(event) => setYear(event.target.value)}
            minW="256px"
            mt={2}
            placeholder={pickRegistrationYearLabel}
            maxW={['100%', null, '256px']}
            h="auto"
            borderRadius={0}
            p={4}
            value={year}
            type="number"
            bg="white"
            color="gray.500"
            border="1px solid #A1A1A1"
          />
        </Box>
      </Flex>
    </Box>
  );
};
