import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { isSanityImageAsset, SanityImage } from 'sanity-picture-asset';

export interface UseSlideShowResult {
  galleryIndex: number;
  setGalleryIndex: Dispatch<SetStateAction<number>>;
  fullscreen: boolean;
  setFullscreen: Dispatch<SetStateAction<boolean>>;
  slides: SanityImage[];
  onImageClick: (index: number) => void;
}

export const useSlideshow = (slides: SanityImage[]): UseSlideShowResult => {
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);

  const onImageClick = useCallback(
    (index) => {
      setGalleryIndex(index);
      setFullscreen(true);
    },
    [setGalleryIndex, setFullscreen]
  );

  const mappedSlides =
    Array.isArray(slides) && slides.length > 0 ? slides.filter((slide) => isSanityImageAsset(slide)) : [];

  return {
    galleryIndex,
    setGalleryIndex,
    fullscreen,
    setFullscreen,
    slides: mappedSlides,
    onImageClick,
  };
};
