export const navbarLogo = (
  <svg width="108" height="18" viewBox="0 0 108 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0.914908 16.6363L0.47571 15.4751H0.467423C0.479163 15.6476 0.485032 15.8092 0.485032 15.9601V16.6363H0.260254V15.2459H0.609334L1.02989 16.352H1.0361L1.46909 15.2459H1.8192V16.6363H1.58096V15.9487C1.58096 15.8796 1.58268 15.7896 1.58614 15.6786C1.59028 15.5677 1.59373 15.5005 1.5965 15.477H1.58821L1.13347 16.6363H0.914908Z"
        fill="#C7C7C7"
      />
      <path
        d="M7.66552 16.6363L7.51428 16.2502H6.93524L6.78712 16.6363H6.52609L7.09269 15.2402H7.36201L7.92862 16.6363H7.66552ZM7.44902 16.0543L7.30711 15.6758C7.29675 15.6504 7.28225 15.6105 7.26361 15.5559C7.24565 15.5014 7.23322 15.4615 7.22632 15.4361C7.20767 15.5141 7.18039 15.5994 7.14449 15.6919L7.00775 16.0543H7.44902Z"
        fill="#C7C7C7"
      />
      <path
        d="M13.7521 16.6363H13.4673L13.0882 16.0676L12.7059 16.6363H12.4408L12.9432 15.9154L12.4729 15.2459H12.7484L13.0985 15.7718L13.4486 15.2459H13.7159L13.2425 15.9192L13.7521 16.6363Z"
        fill="#C7C7C7"
      />
      <path d="M18.4642 16.6363V15.2459H18.7118V16.6363H18.4642Z" fill="#C7C7C7" />
      <path
        d="M24.2753 16.6363L23.8361 15.4751H23.8278C23.8396 15.6476 23.8454 15.8092 23.8454 15.9601V16.6363H23.6206V15.2459H23.9697L24.3903 16.352H24.3965L24.8295 15.2459H25.1796V16.6363H24.9414V15.9487C24.9414 15.8796 24.9431 15.7896 24.9465 15.6786C24.9507 15.5677 24.9541 15.5005 24.9569 15.477H24.9486L24.4939 16.6363H24.2753Z"
        fill="#C7C7C7"
      />
      <path
        d="M31.2735 15.2459V16.1456C31.2735 16.2483 31.2493 16.3383 31.201 16.4157C31.1533 16.4924 31.0839 16.5517 30.9928 16.5935C30.9023 16.6347 30.7939 16.6553 30.6675 16.6553C30.4797 16.6553 30.3336 16.6097 30.2293 16.5184C30.1251 16.4271 30.0729 16.3016 30.0729 16.1418V15.2459H30.3215V16.1256C30.3215 16.2404 30.3505 16.325 30.4085 16.3795C30.4666 16.4341 30.5556 16.4613 30.6758 16.4613C30.9092 16.4613 31.0259 16.3488 31.0259 16.1237V15.2459H31.2735Z"
        fill="#C7C7C7"
      />
      <path
        d="M36.8235 16.6363L36.3843 15.4751H36.3761C36.3878 15.6476 36.3937 15.8092 36.3937 15.9601V16.6363H36.1689V15.2459H36.518L36.9385 16.352H36.9447L37.3777 15.2459H37.7278V16.6363H37.4896V15.9487C37.4896 15.8796 37.4913 15.7896 37.4948 15.6786C37.4989 15.5677 37.5024 15.5005 37.5051 15.477H37.4968L37.0421 16.6363H36.8235Z"
        fill="#C7C7C7"
      />
      <path
        d="M48.6964 15.6663C48.6964 15.8115 48.6446 15.9227 48.541 16.0001C48.4374 16.0774 48.29 16.1161 48.0987 16.1161H47.9413V16.6363H47.6937V15.2459H48.1298C48.319 15.2459 48.4606 15.2814 48.5545 15.3524C48.6491 15.4234 48.6964 15.528 48.6964 15.6663ZM47.9413 15.9249H48.0728C48.1999 15.9249 48.2931 15.9047 48.3525 15.8641C48.4119 15.8235 48.4416 15.7601 48.4416 15.6739C48.4416 15.594 48.415 15.5344 48.3618 15.4951C48.3087 15.4558 48.2258 15.4361 48.1132 15.4361H47.9413V15.9249Z"
        fill="#C7C7C7"
      />
      <path
        d="M54.3635 16.6363H53.5069V15.2459H54.3635V15.438H53.7544V15.8165H54.3252V16.0067H53.7544V16.4433H54.3635V16.6363Z"
        fill="#C7C7C7"
      />
      <path
        d="M59.4474 15.8926H59.6194C59.7347 15.8926 59.8183 15.873 59.8701 15.8336C59.9219 15.7943 59.9478 15.736 59.9478 15.6587C59.9478 15.58 59.9198 15.5236 59.8639 15.4894C59.8079 15.4551 59.7237 15.438 59.6111 15.438H59.4474V15.8926ZM59.4474 16.0809V16.6363H59.1999V15.2459H59.6277C59.8231 15.2459 59.9678 15.2795 60.0617 15.3467C60.1556 15.4139 60.2026 15.5154 60.2026 15.651C60.2026 15.8241 60.1045 15.9475 59.9084 16.021L60.3362 16.6363H60.0545L59.6919 16.0809H59.4474Z"
        fill="#C7C7C7"
      />
      <path
        d="M65.3103 16.6363H65.0648V15.2459H65.9194V15.438H65.3103V15.8726H65.8811V16.0657H65.3103V16.6363Z"
        fill="#C7C7C7"
      />
      <path
        d="M72.0464 15.9392C72.0464 16.1656 71.9846 16.3415 71.861 16.467C71.7381 16.5926 71.5637 16.6553 71.3379 16.6553C71.1093 16.6553 70.9336 16.5932 70.8107 16.4689C70.6884 16.344 70.6273 16.1668 70.6273 15.9373C70.6273 15.7078 70.6888 15.5315 70.8117 15.4085C70.9353 15.2855 71.1114 15.224 71.34 15.224C71.5651 15.224 71.7391 15.2865 71.8621 15.4114C71.985 15.5363 72.0464 15.7122 72.0464 15.9392ZM70.8904 15.9392C70.8904 16.1104 70.9281 16.2404 71.0033 16.3291C71.0786 16.4173 71.1901 16.4613 71.3379 16.4613C71.485 16.4613 71.5958 16.4176 71.6704 16.3301C71.7457 16.2426 71.7833 16.1123 71.7833 15.9392C71.7833 15.7687 71.746 15.6393 71.6715 15.5512C71.5976 15.4631 71.4871 15.419 71.34 15.419C71.1915 15.419 71.0793 15.4631 71.0033 15.5512C70.9281 15.6393 70.8904 15.7687 70.8904 15.9392Z"
        fill="#C7C7C7"
      />
      <path
        d="M77.1273 15.8926H77.2992C77.4145 15.8926 77.4981 15.873 77.5499 15.8336C77.6017 15.7943 77.6276 15.736 77.6276 15.6587C77.6276 15.58 77.5996 15.5236 77.5437 15.4894C77.4877 15.4551 77.4035 15.438 77.2909 15.438H77.1273V15.8926ZM77.1273 16.0809V16.6363H76.8797V15.2459H77.3075C77.5029 15.2459 77.6476 15.2795 77.7415 15.3467C77.8354 15.4139 77.8824 15.5154 77.8824 15.651C77.8824 15.8241 77.7843 15.9475 77.5882 16.021L78.016 16.6363H77.7343L77.3717 16.0809H77.1273Z"
        fill="#C7C7C7"
      />
      <path
        d="M83.3993 16.6363L82.9601 15.4751H82.9518C82.9636 15.6476 82.9694 15.8092 82.9694 15.9601V16.6363H82.7447V15.2459H83.0937L83.5143 16.352H83.5205L83.9535 15.2459H84.3036V16.6363H84.0654V15.9487C84.0654 15.8796 84.0671 15.7896 84.0705 15.6786C84.0747 15.5677 84.0781 15.5005 84.0809 15.477H84.0726L83.6179 16.6363H83.3993Z"
        fill="#C7C7C7"
      />
      <path
        d="M90.1499 16.6363L89.9987 16.2502H89.4196L89.2715 16.6363H89.0105L89.5771 15.2402H89.8464L90.413 16.6363H90.1499ZM89.9334 16.0543L89.7915 15.6758C89.7812 15.6504 89.7667 15.6105 89.748 15.5559C89.7301 15.5014 89.7176 15.4615 89.7107 15.4361C89.6921 15.5141 89.6648 15.5994 89.6289 15.6919L89.4922 16.0543H89.9334Z"
        fill="#C7C7C7"
      />
      <path
        d="M96.3826 16.6363H96.0791L95.3343 15.5284H95.326L95.3312 15.5902C95.3409 15.7081 95.3457 15.8159 95.3457 15.9135V16.6363H95.1209V15.2459H95.4213L96.164 16.3482H96.1703C96.1689 16.3336 96.1661 16.2806 96.162 16.1893C96.1578 16.0974 96.1558 16.0258 96.1558 15.9744V15.2459H96.3826V16.6363Z"
        fill="#C7C7C7"
      />
      <path
        d="M101.935 15.4209C101.792 15.4209 101.681 15.4672 101.599 15.5597C101.518 15.6523 101.477 15.7801 101.477 15.943C101.477 16.1136 101.516 16.2426 101.594 16.3301C101.673 16.4176 101.786 16.4613 101.935 16.4613C101.999 16.4613 102.061 16.4556 102.121 16.4442C102.181 16.4322 102.244 16.417 102.309 16.3986V16.5935C102.19 16.6347 102.055 16.6553 101.905 16.6553C101.683 16.6553 101.513 16.5938 101.394 16.4708C101.275 16.3472 101.216 16.1706 101.216 15.9411C101.216 15.7966 101.245 15.6701 101.302 15.5616C101.36 15.4532 101.443 15.3702 101.553 15.3125C101.662 15.2548 101.79 15.2259 101.937 15.2259C102.091 15.2259 102.234 15.2557 102.366 15.3153L102.277 15.5046C102.225 15.4824 102.171 15.4631 102.114 15.4466C102.057 15.4295 101.998 15.4209 101.935 15.4209Z"
        fill="#C7C7C7"
      />
      <path
        d="M108 16.6363H107.143V15.2459H108V15.438H107.391V15.8165H107.961V16.0067H107.391V16.4433H108V16.6363Z"
        fill="#C7C7C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.69156 0C0.757337 0 0 0.757338 0 1.69156L24.4625 1.69156H24.4627C24.7219 1.69167 25.2383 1.84646 25.2432 2.46351V10.6626C25.2432 12.3195 26.5863 13.6626 28.2432 13.6626H28.7492C30.406 13.6626 31.7492 12.3195 31.7492 10.6626V1.69156H31.7493V2.47228C31.7493 1.8477 32.2698 1.69156 32.53 1.69156H51.3975C51.6565 1.69156 52.1733 1.84624 52.1781 2.46351V10.6626C52.1781 12.3195 53.5213 13.6626 55.1781 13.6626H55.6841C57.341 13.6626 58.6841 12.3195 58.6841 10.6626L58.6841 1.69156C58.6841 0.757338 57.9268 0 56.9926 0H1.69156ZM6.506 5.07468H0V10.6626C0 12.3195 1.34315 13.6626 3 13.6626H3.506C5.16285 13.6626 6.506 12.3195 6.506 10.6626V5.07468ZM0 3.253H6.506V3.51324H0V3.253Z"
        fill="#C7C7C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5066 0.84578C60.5066 0.378669 60.8852 0 61.3524 0H106.309C107.199 0 107.929 0.688016 107.995 1.56144H108V1.64468C108 1.66026 108 1.67588 108 1.69156H108V5.07468H101.494V4.94456H100.713C101.338 4.94456 101.494 4.42408 101.494 4.16384V2.46351C101.489 1.84749 100.974 1.69219 100.715 1.69156H61.3524C60.8852 1.69156 60.5066 1.31289 60.5066 0.84578ZM67.0119 5.07468H60.5059V6.76624V10.6626C60.5059 12.3195 61.849 13.6626 63.5059 13.6626H64.0119C65.6687 13.6626 67.0119 12.3195 67.0119 10.6626V7.52902C67.0217 6.92063 67.5326 6.76687 67.7909 6.76624H106.308C107.242 6.76624 108 6.00891 108 5.07468H67.0119ZM60.5059 3.253H96.1587V3.51324H60.5059V3.253Z"
        fill="#D80624"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="108" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
