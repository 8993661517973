import { Box, FormControl, Grid, Heading, Icon, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import Button from 'jexity/packages/components/button/Button';
import { FormFieldSerializer, useFormFetch } from 'jexity/packages/components/form/formApi';
import { FormIconLock } from 'jexity/packages/icons';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { formValidationSchema } from 'sanity-form-builder';
import { FORM_FIELDS, FormFields } from '../../theme/formFields';
import { RightArrow } from '../../theme/icons';
import { DEFAULT_LANGUAGE } from '../../theme/languages';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { customFormFieldSerializer } from '../form/fieldsSerializer';
import FormConfirmation from '../form/response/FormConfirmation';
import FormServerError from '../form/response/FormServerError';

function getInitialValues(formFields: FormFields[] = []) {
  const initialValues = {};
  formFields.forEach((formField) => {
    if (formField._type === 'checkboxFormField') {
      initialValues[formField.name] = false;
    } else {
      initialValues[formField.name] = '';
    }
  });

  return initialValues;
}

export const ContactUsForm: FC = () => {
  const theme = useBaseTheme();
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { headings, paragraph } = theme;
  const { translations } = useTranslationsContext();
  const { contactUsPage } = translations ?? {};
  const {
    formSubmitText,
    responseErrorText,
    responseButtonText,
    responseErrorHeader,
    responseSuccessHeader,
    responseSuccessText,
    SSLTextLeftFirstLine,
    SSLTextLeftSecondLine,
    SSLTextRightFirstLine,
    SSLTextRightSecondLine,
  } = contactUsPage?.formWithImageSection ?? {};

  const [ref, InView] = useInView({
    triggerOnce: true,
  });

  const { onSubmit, showConfirmation, setShowConfirmation, serverError, setServerError } = useFormFetch(
    '/api/kontakt' as any
  );

  const yupSchema = formValidationSchema(FORM_FIELDS as any);

  const { handleChange, handleBlur, isSubmitting, handleSubmit, values, touched, errors, resetForm } = useFormik<any>({
    initialValues: getInitialValues(FORM_FIELDS),
    validationSchema: yupSchema,
    onSubmit: (values, formikHelpers) => {
      const mergedValue = { ...values, locale };

      onSubmit(mergedValue, formikHelpers);
    },
  });

  const baseInputProps = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit}
      style={{
        position: 'relative',
        overflow: showConfirmation || serverError ? 'hidden' : 'initial',
      }}
      noValidate
    >
      <Box minH={['250px', null, null, '300px']}>
        {Array.isArray(FORM_FIELDS) &&
          FORM_FIELDS.map((formField) => {
            const name = formField.name;
            const type: keyof FormFieldSerializer =
              formField._type === 'stringFormField' && formField.multirow
                ? 'multilineStringFormField'
                : formField._type;
            const FieldComponent: FC<{ value: any; label?: string; placeholder?: string }> = customFormFieldSerializer[
              type
            ] as FC;
            const error: { error: string | undefined } = {
              error: touched[name] && errors[name] && contactUsPage?.formErrors[name],
            };

            if (error.error && name === 'email' && values[name].length) {
              const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!emailRegex.test(String(values[name]).toLowerCase())) {
                error.error = contactUsPage?.formErrors.emailInvalid;
              }
            }

            const { multirow, ...rest } = { ...formField };

            return (
              <FormControl key={`field-${formField.name}`}>
                <FieldComponent
                  key={formField.name}
                  value={values[name]}
                  {...error}
                  {...rest}
                  {...baseInputProps}
                  label={formField.label[locale]}
                  placeholder={formField.placeholder?.[locale]}
                />
              </FormControl>
            );
          })}
      </Box>
      {InView && (
        <FormConfirmation
          message={[]}
          showConfirmation={showConfirmation}
          reset={() => {
            setShowConfirmation(false);
            resetForm();
          }}
          translations={{
            title: responseSuccessHeader ?? '',
            subtitle: responseSuccessText ?? '',
            buttonText: responseButtonText ?? '',
          }}
        />
      )}
      {InView && (
        <FormServerError
          serverError={serverError}
          reset={() => {
            setServerError(false);
            resetForm();
          }}
          translations={{
            title: responseErrorHeader ?? '',
            subtitle: responseErrorText ?? '',
            buttonText: responseButtonText ?? '',
          }}
        />
      )}
      <Box>
        <Button type="submit" isLoading={isSubmitting} w="100%" maxH="61px">
          <Heading as="h6" {...headings?.h6} fontSize="20px" lineHeight="26px">
            {formSubmitText}
          </Heading>
          <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
        </Button>
      </Box>
      <Box mt={[6, null, 4]}>
        <Grid gridTemplateColumns={['1fr', null, 'min-content 1fr']} justifyItems={['center', null, 'initial']}>
          <Grid gridTemplateColumns="min-content 1fr">
            <Icon as={FormIconLock} boxSize="28px" mt={1} color="brand.secondary.100" />
            <Box fontWeight={700} textAlign="left" {...paragraph?.body2}>
              {SSLTextLeftFirstLine} <br />
              {SSLTextLeftSecondLine}
            </Box>
          </Grid>
          <Box mt={['0.625rem', null, 0]}>
            <Text textAlign={['center', null, 'right']} {...paragraph?.body2}>
              {SSLTextRightFirstLine} <br />
              {SSLTextRightSecondLine}
            </Text>
          </Box>
        </Grid>
      </Box>
    </form>
  );
};
