export const facebook = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.21419 14.6668V8.66683H11.0358L11.3333 6.00016H9.21419V4.7015C9.21419 4.01483 9.23173 3.3335 10.1913 3.3335H11.1631V1.42683C11.1631 1.39816 10.3283 1.3335 9.4838 1.3335C7.72001 1.3335 6.61561 2.43816 6.61561 4.46683V6.00016H4.66667V8.66683H6.61561V14.6668H9.21419H9.21419Z"
      fill="white"
    />
  </svg>
);
