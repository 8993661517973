import { Box, Heading } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { BrandsList } from '../../components/brands-list/BrandsList';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';

const BrandsSection: FC<{ pageType: 'homePage' | 'orderProcessPage'; isLink?: boolean }> = ({
  pageType,
  isLink = false,
}) => {
  const { sections, headings } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { [pageType]: localizedSection } = translations ?? {};
  const { title } = localizedSection?.brandsSection ?? {};
  const { sectionWrapper, titleOffset } = sections?.brandsSection ?? {};

  const isOrder = pageType === 'orderProcessPage';

  return (
    <SectionContainer {...sectionWrapper} mt="2.5rem" mb="2.5rem">
      <Container>
        <Box
          maxWidth={800}
          textAlign={isOrder ? 'left' : 'center'}
          margin={isOrder ? '' : '0 auto'}
          mb={titleOffset ? titleOffset : [8, null, 16]}
        >
          <Heading as="h5" {...headings?.h5}>
            {title ?? ''}
          </Heading>
        </Box>
        <BrandsList isLink={isLink} />
      </Container>
    </SectionContainer>
  );
};

export default BrandsSection;
