import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { SectionCommonFields } from 'jexity/packages/sections/common/sectionsApi';
import { FC } from 'react';
import { BaseSectionProps } from '../common/sectionsApi';

interface BlockContentSectionData extends SectionCommonFields {
  content?: Content[];
}

const BlockContentSection: FC<BaseSectionProps<BlockContentSectionData>> = ({ data }) => {
  const theme = useBaseTheme();
  const { sections } = theme;
  const { sectionWrapper } = sections?.blockContentSection ?? {};
  const content = data.content ?? [];
  const layout = data.layout ?? 'thin';

  return (
    <SectionContainer {...sectionWrapper}>
      <Container variant={layout}>
        <Box>
          <BlockContent blocks={content} />
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default BlockContentSection;
