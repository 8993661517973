import { Box, FormControl, Grid, Heading, Icon, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import Button from 'jexity/packages/components/button/Button';
import { FormFieldSerializer, useFormFetch } from 'jexity/packages/components/form/formApi';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { FormIconLock } from 'jexity/packages/icons';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { formValidationSchema } from 'sanity-form-builder';
import { FormFields, REQUEST_CAR_FORM_FIELDS } from '../../theme/formFields';
import { RightArrow } from '../../theme/icons';
import { DEFAULT_LANGUAGE } from '../../theme/languages';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { customFormFieldSerializer } from '../form/fieldsSerializer';
import FormConfirmation from '../form/response/FormConfirmation';
import FormServerError from '../form/response/FormServerError';

function getInitialValues(formFields: FormFields[] = []) {
  const initialValues = {};
  formFields.forEach((formField) => {
    if (formField._type === 'checkboxFormField') {
      initialValues[formField.name] = false;
    } else {
      initialValues[formField.name] = '';
    }
  });

  return initialValues;
}

export const RequestCarContactUsForm: FC = () => {
  const theme = useBaseTheme();
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { headings, paragraph } = theme;
  const { formAdditionalValues } = useFormContext();
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const {
    formSubmitText,
    responseErrorText,
    responseButtonText,
    responseErrorHeader,
    responseSuccessHeader,
    responseSuccessText,
    SSLTextLeftFirstLine,
    SSLTextLeftSecondLine,
    SSLTextRightFirstLine,
    SSLTextRightSecondLine,
  } = requestCarPage?.requestCarFormSection.contactForm ?? {};

  const [ref, InView] = useInView({
    triggerOnce: true,
  });

  // TODO: useFormFetch don't accept just string. That would not work and has to be refactored!
  const { onSubmit, showConfirmation, setShowConfirmation, serverError, setServerError } = useFormFetch(
    '/api/request-car' as any
  );

  const yupSchema = formValidationSchema(REQUEST_CAR_FORM_FIELDS as any);

  const { handleChange, handleBlur, isSubmitting, handleSubmit, values, touched, errors, resetForm } = useFormik<any>({
    initialValues: getInitialValues(REQUEST_CAR_FORM_FIELDS),
    validationSchema: yupSchema,
    onSubmit: (values, formikHelpers) => {
      const mergedValue = { ...values, ...formAdditionalValues, locale };

      onSubmit(mergedValue, formikHelpers);
    },
  });

  const baseInputProps = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit}
      style={{
        width: '100%',
        position: 'relative',
        overflow: showConfirmation || serverError ? 'hidden' : 'initial',
      }}
      noValidate
    >
      <Box minH={['250px', null, null, '300px']}>
        {Array.isArray(REQUEST_CAR_FORM_FIELDS) &&
          REQUEST_CAR_FORM_FIELDS.map((formField) => {
            const name = formField.name;
            const type: keyof FormFieldSerializer =
              formField._type === 'stringFormField' && formField.multirow
                ? 'multilineStringFormField'
                : formField._type;
            const FieldComponent: FC<{ value: any; label?: string; placeholder?: string }> = customFormFieldSerializer[
              type
            ] as FC;
            const error = { error: touched[name] && errors[name] && requestCarPage?.formErrors[name] };

            return (
              <FormControl key={`field-${formField.name}`}>
                <FieldComponent
                  key={formField.name}
                  value={values[name]}
                  {...error}
                  {...formField}
                  {...baseInputProps}
                  label={formField.label[locale]}
                  placeholder={formField.placeholder?.[locale]}
                />
              </FormControl>
            );
          })}
      </Box>
      {InView && (
        <FormConfirmation
          message={[]}
          showConfirmation={showConfirmation}
          reset={() => {
            setShowConfirmation(false);
            resetForm();
          }}
          translations={{
            title: responseSuccessHeader ?? '',
            subtitle: responseSuccessText ?? '',
            buttonText: responseButtonText ?? '',
          }}
        />
      )}
      {InView && (
        <FormServerError
          serverError={serverError}
          reset={() => {
            setServerError(false);
            resetForm();
          }}
          translations={{
            title: responseErrorHeader ?? '',
            subtitle: responseErrorText ?? '',
            buttonText: responseButtonText ?? '',
          }}
        />
      )}
      <Box>
        <Button type="submit" isLoading={isSubmitting} w="calc(100% - 2px)" ml={0.75} maxH="61px">
          <Heading as="h6" {...headings?.h6} fontSize="20px" lineHeight="26px">
            {formSubmitText}
          </Heading>
          <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
        </Button>
      </Box>
      <Box mt={[6, null, 4]}>
        <Grid gridTemplateColumns={['1fr', null, 'min-content 1fr']} justifyItems={['center', null, 'initial']}>
          <Grid gridTemplateColumns="min-content 1fr">
            <Icon as={FormIconLock} boxSize="1.75rem" mt={1} color="brand.secondary.100" />
            <Box fontWeight={700} textAlign="left" {...paragraph?.body2}>
              <Text fontWeight="bold" fontSize={12} lineHeight="1.125rem" color="#F1F1F1">
                {SSLTextLeftFirstLine} <br />
                {SSLTextLeftSecondLine}
              </Text>
            </Box>
          </Grid>
          <Box mt={['0.625rem', null, 0]}>
            <Text textAlign={['center', null, 'right']} {...paragraph?.body2} lineHeight="1.125rem">
              {SSLTextRightFirstLine} <br />
              {SSLTextRightSecondLine}
            </Text>
          </Box>
        </Grid>
      </Box>
    </form>
  );
};
