import { FC } from 'react';
import { Box, FormLabel, FormLabelProps, Input, InputProps } from '@chakra-ui/react';
import { EmailFormField } from 'sanity-form-builder';
import { ErrorMessage } from 'jexity/packages/components/form/fieldsSerializer';
import { formErrorColor } from 'jexity/packages/components/form/formApi';
import { customFormFieldStyles } from '../fieldsSerializer';

const EmailField: FC<InputProps & EmailFormField> = (props) => {
  const { label, error } = props;
  const { labelStyle, fieldStyle } = customFormFieldStyles;

  return (
    <>
      <Box pos="relative">
        <FormLabel {...(labelStyle as FormLabelProps)}>{label} </FormLabel>
        <Input
          {...props}
          {...fieldStyle}
          isInvalid={!!error}
          backgroundColor={!!error ? '#FFEBEB' : 'white'}
          errorBorderColor={formErrorColor}
        />
      </Box>
      <ErrorMessage error={error} />
    </>
  );
};

export default EmailField;
