import { BoxProps } from '@chakra-ui/react';
import { SectionsTheme } from 'jexity/packages/baseTheme/baseTheme';

const defaultSectionStyle: BoxProps = {
  py: 0,
  pt: [6, null, 16, 20],
  mb: [6, null, 0],
};

export const sectionsStyles: SectionsTheme = {
  blockTextImageSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  blockTextSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  commissionOverviewSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  orderProcessSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  offerSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  ctaSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  brandsSection: {
    titleOffset: ['2.6875rem', null, null, '3.6875rem'],
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  showroomSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  requestCarFormSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  referenceSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  carsSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
      pt: [0, null, 4],
      mt: ['-0.5rem', null, 0],
    },
  },
  carDetailsSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  carTeaserSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  imageGallerySection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
  blockContentSection: {
    sectionWrapper: {
      ...defaultSectionStyle,
    },
  },
};
