import { Box, Grid, Icon, Link, LinkProps } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { isValidCta } from 'jexity/packages/components/block-content/blockContentUtils';
import { CtaLink } from 'jexity/packages/components/block-content/cta';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import { BreakOutContainer } from 'jexity/packages/components/container/BreakOutContainer';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { isSanityImageAsset, PictureAsset, SanityImage } from 'sanity-picture-asset';
import { DEFAULT_LANGUAGE } from 'src/theme/languages';
import { ImagePlacement, ImagePosition } from '../../feature/page/content/common';
import { RightArrow } from '../../theme/icons';
import { BaseSectionProps } from '../common/sectionsApi';
import { getInternalLink, useSiteSettingsContext } from 'jexity/packages/feature/common/index';

export interface BlockTextImageSectionData {
  image: SanityImage;
  imagePlacement: ImagePlacement;
  imagePosition: ImagePosition;
  allowImageZoom?: boolean;
  content: Content[];
  cta?: CtaLink;
  title?: Content[];
}

export interface BlockTextImageSectionProps extends BaseSectionProps<BlockTextImageSectionData> {
  serializer?: Partial<BlockContentSerializer>;
}

const BlockTextImageSection: FC<BlockTextImageSectionProps> = ({ data }) => {
  const theme = useBaseTheme();
  const { siteSettings } = useSiteSettingsContext();
  const { paragraph, sections, gridGap } = theme;
  const { sectionWrapper } = sections?.blockTextImageSection ?? {};
  const { image, imagePlacement, imagePosition, content, cta, title } = data;
  const { locale = DEFAULT_LANGUAGE } = useRouter();

  const isImageLeft = imagePlacement === 'left';
  const isImageOverflow = imagePosition === 'overflow';

  return (
    <SectionContainer {...sectionWrapper}>
      <Grid pos="relative" templateColumns="auto" templateRows="auto">
        <Container variant={isImageOverflow ? 'wide' : 'normal'}>
          <Grid
            pos="relative"
            zIndex={2}
            w="100%"
            h="100%"
            columnGap={gridGap}
            rowGap={'3.25rem'}
            gridTemplateColumns={[
              '1fr',
              null,
              null,
              isImageOverflow ? (isImageLeft ? '1fr 40%' : '40% 1fr') : '1fr 1fr',
              null,
              null,
              isImageOverflow ? (isImageLeft ? '1fr 43%' : '43% 1fr') : null,
            ]}
          >
            <Box
              order={[2, null, null, isImageLeft ? 2 : 1]}
              h="100%"
              d="flex"
              flexDir="column"
              style={{
                placeContent: 'center',
              }}
              alignItems={['flex-start', null, null, isImageLeft ? 'flex-start' : 'flex-end']}
            >
              <Box
                textAlign="left"
                maxW={isImageOverflow ? ['100%', '100%', '100%', '100%', '440px', '420px', '635px', '615px'] : '100%'}
              >
                <Box>
                  <BlockContent blocks={title as Content[]} />
                </Box>
                <Box mt={['1.6875rem', null, null, '1rem']}>
                  <BlockContent blocks={content as Content[]} />
                </Box>
                {!!cta && isValidCta(cta) && (
                  <Box mt={['2.5rem', null, null, '3.25rem']} d="inline-flex">
                    <NextLink
                      {...getInternalLink({
                        startPageId: siteSettings.startPage?._id,
                        pageId: cta.reference._id,
                        _type: cta.reference._type,
                        slug: cta.reference.slug,
                      })}
                      locale={locale}
                      passHref
                    >
                      <Link
                        {...(paragraph?.body4 as LinkProps)}
                        textTransform="uppercase"
                        color="inherit"
                        fontFamily="heading"
                        letterSpacing="2px"
                        fontWeight={400}
                        _hover={{
                          color: 'brand.primary.500',
                          textDecor: 'none',
                          transition: '.4s ease-out',
                        }}
                      >
                        {cta.label}
                        <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
                      </Link>
                    </NextLink>
                  </Box>
                )}
              </Box>
            </Box>

            <Box w={['100%']} h={['100%']} pos="relative" order={[1, null, null, isImageLeft ? 1 : 2]}>
              <BreakOutContainer>
                {isSanityImageAsset(image) && (
                  <Box pos="relative">
                    <PictureAsset objectFit="contain" image={image} alt={image.alt ?? 'Sportwagen'} isFluid={true} />
                  </Box>
                )}
              </BreakOutContainer>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </SectionContainer>
  );
};

export default BlockTextImageSection;
