import { css, Global } from '@emotion/core';
import React, { FC } from 'react';

export const Fonts: FC = () => (
  <Global
    styles={css`
      /* oswald-regular - latin */
      @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        src: url('/project-fonts/oswald-v35-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''), url('/project-fonts/oswald-v35-latin-regular.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/oswald-v35-latin-regular.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/oswald-v35-latin-regular.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/oswald-v35-latin-regular.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/oswald-v35-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
      }
      /* oswald-500 - latin */
      @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 500;
        src: url('/project-fonts/oswald-v35-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''), url('/project-fonts/oswald-v35-latin-500.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/oswald-v35-latin-500.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/oswald-v35-latin-500.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/oswald-v35-latin-500.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/oswald-v35-latin-500.svg#Oswald') format('svg'); /* Legacy iOS */
      }
      /* oswald-600 - latin */
      @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 600;
        src: url('/project-fonts/oswald-v35-latin-600.eot'); /* IE9 Compat Modes */
        src: local(''), url('/project-fonts/oswald-v35-latin-600.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/oswald-v35-latin-600.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/oswald-v35-latin-600.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/oswald-v35-latin-600.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/oswald-v35-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
      }
      /* oswald-700 - latin */
      @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 700;
        src: url('/project-fonts/oswald-v35-latin-700.eot'); /* IE9 Compat Modes */
        src: local(''), url('/project-fonts/oswald-v35-latin-700.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/oswald-v35-latin-700.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/oswald-v35-latin-700.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/oswald-v35-latin-700.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/oswald-v35-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
      }
      /* roboto-regular - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: url('/project-fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
        src: local('Roboto'), local('Roboto-Regular'),
          url('/project-fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/roboto-v20-latin-regular.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/roboto-v20-latin-regular.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/roboto-v20-latin-regular.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
      }
      /* roboto-700 - latin */
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: url('/project-fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
        src: local('Roboto Bold'), local('Roboto-Bold'),
          url('/project-fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/roboto-v20-latin-700.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/roboto-v20-latin-700.woff') format('woff'),
          /* Modern Browsers */ url('/project-fonts/roboto-v20-latin-700.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('/project-fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
      }
      /* roboto-condensed-regular - latin */
      @font-face {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        src: url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.woff2') format('woff2'),
          /* Super Modern Browsers */ url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.woff')
            format('woff'),
          /* Modern Browsers */ url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.ttf')
            format('truetype'),
          /* Safari, Android, iOS */
            url('/project-fonts/roboto-condensed/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
      }
    `}
  />
);
