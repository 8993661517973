import { Icon } from '@chakra-ui/react';
import { BulletList } from 'jexity/packages/baseTheme/baseTheme';
import { BulletListIcon } from '../icons';

export const bulletListSerializer: BulletList = {
  display: 'flex',
  icon: <Icon as={BulletListIcon} color="brand.primary.500" display="block" mt={1} mr={2} />,
  mt: 0,
  ml: 0,
  mb: 1,
};
