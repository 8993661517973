import { Box, Flex, Heading, Input, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useDebounce } from 'jexity/packages/components/debounce/useDebounce';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { FC, useEffect, useState } from 'react';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';

export const PickBudgetContent: FC = () => {
  const { headings, paragraph } = useBaseTheme();
  const { setFormAdditionalValues } = useFormContext();
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { pickBudgetMaximumBudgetLabel, pickBudgetTitle, pickBudgetMinimumBudgetLabel } =
    requestCarPage?.requestCarFormSection ?? {};
  const [minBudget, setMinBudget] = useState('');
  const [maxBudget, setMaxBudget] = useState('');

  const debouncedMinBudget = useDebounce(minBudget, 250);
  const debouncedMaxBudget = useDebounce(maxBudget, 250);

  useEffect(() => {
    setFormAdditionalValues((prev) => ({ ...prev, minimumBudget: debouncedMinBudget }));
  }, [debouncedMinBudget, setFormAdditionalValues]);

  useEffect(() => {
    setFormAdditionalValues((prev) => ({ ...prev, maximumBudget: debouncedMaxBudget }));
  }, [debouncedMaxBudget, setFormAdditionalValues]);

  return (
    <Box minH="420px">
      <Box maxWidth={545} textAlign={'center'} margin={'0 auto'} mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {pickBudgetTitle}
        </Heading>
      </Box>
      <Flex flexDir={['column', null, 'row']} justifyContent="center">
        <Box mr={[0, null, '2rem']} mb={['2rem', null, 0]}>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickBudgetMinimumBudgetLabel}
          </Text>
          <Input
            type="number"
            onChange={(event) => setMinBudget(event.target.value)}
            value={minBudget as string}
            minW="256px"
            mt={2}
            placeholder={pickBudgetMinimumBudgetLabel}
            maxW={['100%', null, '256px']}
            h="auto"
            borderRadius={0}
            bg="white"
            color="gray.500"
            border="1px solid #A1A1A1"
            p={4}
          />
        </Box>
        <Box>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickBudgetMaximumBudgetLabel}
          </Text>
          <Input
            onChange={(event) => setMaxBudget(event.target.value)}
            type="number"
            value={maxBudget as string}
            minW="256px"
            mt={2}
            placeholder={pickBudgetMaximumBudgetLabel}
            maxW={['100%', null, '256px']}
            h="auto"
            borderRadius={0}
            p={4}
            bg="white"
            color="gray.500"
            border="1px solid #A1A1A1"
          />
        </Box>
      </Flex>
    </Box>
  );
};
