import { Box, Grid, Icon, Text, ThemeProvider } from '@chakra-ui/react';
import { useAnimation } from 'framer-motion';
import { BaseTheme, useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { overrideThemeTextWith } from 'jexity/packages/baseTheme/themeUtil';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { isBlockContent, isValidCta } from 'jexity/packages/components/block-content/blockContentUtils';
import { InternalButton } from 'jexity/packages/components/button/InternalButton';
import { Container } from 'jexity/packages/components/container/Container';
import { getInternalLink, useSiteSettingsContext } from 'jexity/packages/feature/common';
import React, { FC, useEffect } from 'react';
import Floor from 'src/components/Floor';
import MainLogoDark from 'src/theme/icons/MainLogoDark';
import { HeroSection } from '../../feature/page/content/home-page/home';
import { RightArrow } from '../../theme/icons';
import { BaseSectionProps } from '../common/sectionsApi';
import { Dream } from './Dream';
import { Live } from './Live';
import { Your } from './Your';
import { MotionBox } from '../../components/animation/MotionBox';

export type HomeHeroSectionProps = BaseSectionProps<HeroSection>;

const transitionLogo = { easings: 'easeInOut', duration: 0.5, delay: 0.2 };
const transitionLiveYour = { easings: 'easeIn', duration: 0.7 };
const transitionDreams = { easings: 'easeInOut', duration: 1 };
const transitionContent = { easings: 'easeInOut', duration: 0.5 };
const variantsContent = {
  visible: { y: 0, opacity: 1 },
  hidden: { y: 50, opacity: 0 },
};
const variantsContentContainer = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
      delay: 0.3,
    },
  },
};

const HomeHeroSection: FC<HomeHeroSectionProps> = ({ data }) => {
  const { siteSettings } = useSiteSettingsContext();
  const theme = useBaseTheme();
  const { content = [], cta = null } = data;
  const controlsLogo = useAnimation();
  const controlsLiveYour = useAnimation();
  const controlsDreams = useAnimation();
  const controlsContent = useAnimation();
  const link = getInternalLink({
    startPageId: siteSettings.startPage?._id,
    pageId: cta?.reference._id,
    _type: cta?.reference._type,
    slug: cta?.reference.slug,
  });

  useEffect(() => {
    const sequence = async () => {
      await controlsLogo.start('visible');
      await controlsLiveYour.start('visible');
      await controlsDreams.start('visible');
      await controlsContent.start('visible');
    };
    void sequence();
  }, [controlsLiveYour, controlsDreams, controlsLogo, controlsContent]);

  const overrideText: BaseTheme = overrideThemeTextWith(theme, {
    fontSize: '20px',
    lineHeight: ['30px', '28px'],
    fontFamily: 'body',
  });

  return (
    <Box pos="relative">
      <Box h={['25rem', null, null, '28rem', '29rem']} w="100vw" pos="absolute" top={0} left={0} zIndex={1}>
        <Box
          boxSize="100%"
          zIndex={1}
          pos="absolute"
          bgImage="linear-gradient(to bottom, transparent, #0007 80%, #000 100%), url(/assets/png/home/piece.png)"
          bgPosition="left top"
          bgRepeat="repeat"
          w="100%"
          h="100%"
          top="0"
          left="0"
          right="0"
          bottom="0"
        >
          <Box
            pos="absolute"
            width="100%"
            height="100%"
            bg="radial-gradient(circle at top, rgba(5,5,5,0.65), rgba(5,5,5,0.8))"
            z-index="1"
            left="0"
            top="0"
            _after={{
              content: '""',
              pos: 'absolute',
              w: '65%',
              h: '50%',
              borderRadius: '50%',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -40%)',
              filter: 'blur(30px)',
              zIndex: '-1',
              mixBlendMode: 'color-dodge',
              bg: 'rgba(117, 117, 117, 0.6)',
            }}
            _before={{
              content: '""',
              pos: 'absolute',
              w: '100%',
              h: '100%',
              bg: 'radial-gradient(at center,rgba(121, 121, 121, 0.1),rgba(0,0,0,0.3),rgba(0,0,0,0.9))',
              left: '0px',
              top: '0px',
            }}
          />
        </Box>
      </Box>
      <Floor
        pos="absolute"
        w={['550vw', null, '410vw', null, '250vw']}
        maxW="3200px"
        h="auto"
        top={['50%', null, null, null, '57%']}
        style={{
          mixBlendMode: 'lighten',
          backdropFilter: 'opacity(1)',
        }}
      />
      <Box pt={['9rem', null, '10rem']} pos="relative" textAlign="center" width="100%" zIndex={2}>
        <Container variant="wide" minHeight={{ base: '60vh', sm: '50vh', xl: '40vh' }}>
          <Box width="100%" textAlign="center">
            <Grid placeItems="center">
              <MotionBox
                animate={controlsLogo}
                initial="hidden"
                transition={transitionLogo}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0.2 },
                }}
                w={{
                  sm: '18.75rem',
                  xl: '30rem',
                }}
              >
                <MainLogoDark
                  id="home-hero-section-logo"
                  maxW={['240px', null, '300px', '477px']}
                  sx={{ width: '100%', h: 'auto' }}
                />
              </MotionBox>
            </Grid>
            <Box
              mt={{
                base: '6rem',
                xl: '6.25rem',
              }}
            >
              <Grid pos="relative" templateColumns="repeat(2, 1fr)" minH="18.75rem">
                <MotionBox
                  animate={controlsLiveYour}
                  initial="hidden"
                  transition={transitionLiveYour}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: -500, opacity: 0 },
                  }}
                >
                  <Box pr={[5, null, 10]}>
                    <Box textAlign="right">
                      <Live />
                    </Box>
                    <Box textAlign="right">
                      <Live opacity="0.4" filter="blur(3px)" transform="rotateX(180deg)" />
                    </Box>
                  </Box>
                </MotionBox>
                <MotionBox
                  animate={controlsLiveYour}
                  initial="hidden"
                  transition={transitionLiveYour}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 500, opacity: 0 },
                  }}
                >
                  <Box pos="relative" textAlign="left">
                    <Your />
                  </Box>
                  <Box pos="relative" textAlign="left">
                    <Your opacity="0.4" filter="blur(3px)" transform="rotateX(180deg)" />
                  </Box>
                </MotionBox>
                <Box pos="absolute" left="0" w="100%" h="100%" minH={{ xl: '300' }} zIndex="1">
                  <MotionBox
                    h={['7.5rem', '10.5rem', '14rem', '18.5rem']}
                    mt={{ base: '-2rem', lg: '-3rem' }}
                    d="grid"
                    justifyItems="center"
                    initial="hidden"
                    transition={transitionDreams}
                    variants={{
                      visible: { scale: 1, opacity: 1 },
                      hidden: { scale: 10, opacity: 0 },
                    }}
                    animate={controlsDreams}
                  >
                    <Dream />
                    <Dream opacity="0.4" filter="blur(3px)" transform="rotateX(180deg)" mt="0" />
                  </MotionBox>
                </Box>
              </Grid>

              <MotionBox
                mt={{ base: '-13em', sm: '-11em', md: '-8em', lg: '-7em' }}
                zIndex={9}
                position="relative"
                initial="hidden"
                variants={variantsContentContainer}
                animate={controlsContent}
              >
                <MotionBox
                  d="flex"
                  justifyContent="center"
                  mt={['2rem', null, 0]}
                  textAlign="center"
                  variants={variantsContent}
                  transition={transitionContent}
                >
                  <Box maxW={['35.75rem']}>
                    <ThemeProvider theme={overrideText}>
                      {isBlockContent(content) && <BlockContent blocks={content} />}
                    </ThemeProvider>
                  </Box>
                </MotionBox>
                {cta && isValidCta(cta) && (
                  <MotionBox
                    d="flex"
                    justifyContent="center"
                    mt={['1.625rem', '2.625rem']}
                    variants={variantsContent}
                    transition={transitionContent}
                  >
                    <InternalButton
                      variant="secondary"
                      link={link}
                      subline={cta.subline ?? ''}
                      mt={0}
                      width={{
                        base: 'auto',
                        md: 'initial',
                      }}
                    >
                      <Box d="grid" placeItems="center">
                        <Box d="flex">
                          <Text
                            fontSize={{
                              base: 20,
                              sm: 20,
                            }}
                            lineHeight="1.5625rem"
                          >
                            {cta.label}
                          </Text>
                          <Icon as={RightArrow} mt="0.25rem" ml="0.5rem" w="1rem" h="0.875rem" />
                        </Box>
                      </Box>
                    </InternalButton>
                  </MotionBox>
                )}
              </MotionBox>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HomeHeroSection;
