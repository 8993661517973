export type FormFieldsType = 'stringFormField' | 'emailFormField' | 'checkboxFormField';

export interface FormFields {
  _type: FormFieldsType;
  isRequired: boolean;
  label: {
    en?: string;
    de?: string;
  };
  placeholder?: {
    en?: string;
    de?: string;
  };
  name: string;
  multirow?: boolean;
}

export const FORM_FIELDS: FormFields[] = [
  {
    _type: 'stringFormField',
    isRequired: false,
    label: {
      en: 'Name',
      de: 'Name',
    },
    name: 'name',
    placeholder: {
      en: 'Type your name here',
      de: 'Ihr Name',
    },
    multirow: false,
  },
  {
    _type: 'emailFormField',
    isRequired: true,
    label: {
      en: 'E-Mail',
      de: 'E-Mail',
    },
    name: 'email',
    placeholder: {
      en: 'Type your E-Mail here',
      de: 'Ihre E-Mail Adresse',
    },
    multirow: false,
  },
  {
    _type: 'stringFormField',
    isRequired: true,
    label: {
      en: 'Message',
      de: 'Nachricht',
    },
    placeholder: {
      en: 'Type your message here...',
      de: 'Ihre Nachricht...',
    },
    name: 'message',
    multirow: true,
  },
  {
    _type: 'checkboxFormField',
    isRequired: true,
    label: {
      en: 'I agree that my data from the form above will be collected and processed to answer my request.',
      de:
        'Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden.',
    },
    name: 'dataprotection',
    multirow: true,
  },
];

export const REQUEST_CAR_FORM_FIELDS: FormFields[] = [
  {
    _type: 'stringFormField',
    isRequired: true,
    label: {
      en: 'Name*:',
      de: 'Name*:',
    },
    name: 'name',
    placeholder: {
      en: 'Type your name here',
      de: 'Ihr Name',
    },
    multirow: false,
  },
  {
    _type: 'emailFormField',
    isRequired: true,
    label: {
      en: 'E-Mail*:',
      de: 'E-Mail*:',
    },
    name: 'email',
    placeholder: {
      en: 'Type your E-Mail here',
      de: 'Ihre E-Mail Adresse',
    },
    multirow: false,
  },
  {
    _type: 'stringFormField',
    isRequired: false,
    label: {
      en: 'Phone:',
      de: 'Telefon:',
    },
    name: 'phone',
    placeholder: {
      en: 'Type your phone here',
      de: 'Ihr Telefon',
    },
    multirow: false,
  },
  {
    _type: 'stringFormField',
    isRequired: false,
    label: {
      en: 'Location:',
      de: 'Ort:',
    },
    name: 'location',
    placeholder: {
      en: 'e.g. Berlin, Germany',
      de: 'z.B. Berlin',
    },
    multirow: false,
  },
  {
    _type: 'checkboxFormField',
    isRequired: true,
    label: {
      en: 'I confirm that I have read the terms of use and data protection declaration and accept them',
      de:
        'Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden.',
    },
    name: 'dataprotection',
    multirow: true,
  },
];
