import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import React, { FC } from 'react';
import QRCodeWithSocial, { QRCodeWithSocialProps } from 'src/sections/showroom-section/QRCodeWithSocial';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';
import { BaseContentPageProps } from '../contentApi';
import { AboutUsDocument } from './aboutUs';
import { useTranslationsContext } from '../../../../theme/translations/TranslationContext';

export const team: QRCodeWithSocialProps[] = [
  {
    name: 'Vladislaw Maier',
    position: ['CEO', 'purchasingAndSales', 'marketing'],
    contact: '+49 172 5633714',
    qrURL: '/assets/png/qrcode/vladi.png',
  },
  /*{
    name: 'Daniel Kuszlik',
    position: ['purchasingAndSales'],
    contact: '+49 162 1660960',
    qrURL: '/assets/png/qrcode/daniel.png',
  },*/
  {
    name: 'Costa',
    position: ['purchasingAndSales'],
    contact: '+39 351 8245723',
    qrURL: '/assets/png/qrcode/costa.png',
  },
  {
    name: 'Mihail Toshkov',
    position: ['photographer', 'designer'],
    contact: '+49 160 95628934',
    qrURL: '/assets/png/qrcode/mihail.png',
    viber: false,
    telegram: false,
  },
];

const AboutUsPage: FC<BaseContentPageProps<AboutUsDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();
  const { translations: t } = useTranslationsContext();

  const { title, ctaSection } = content ?? {};

  return (
    <Box pt={headerHeight}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/about-us.png"
        mobileImage="/assets/png/pages/about-us-mobile.png"
      />
      {title && <BlockTextSection data={{ title }} />}

      <Container mt={24}>
        <Heading as="h3" mb={4}>
          {t?.aboutUsPage.ourTeamSection.title}
        </Heading>
        <SimpleGrid gridTemplateColumns={['1fr', null, '1fr 1fr']}>
          {team.map((social) => {
            return <QRCodeWithSocial key={social.name} {...social} />;
          })}
        </SimpleGrid>
      </Container>
      {ctaSection && <CtaSection data={ctaSection} />}
    </Box>
  );
};

export default AboutUsPage;
