export const externalLink = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H12C12.5523 3 13 3.44772 13 4C13 4.55229 12.5523 5 12 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12ZM17.58 5H16C15.4477 5 15 4.55229 15 4C15 3.44771 15.4477 3 16 3H20C20.5523 3 21 3.44771 21 4V8C21 8.55229 20.5523 9 20 9C19.4477 9 19 8.55229 19 8V6.42L12.71 12.7L12.7042 12.7058C12.3121 13.0947 11.6789 13.0921 11.29 12.7L11.2842 12.6942C10.8953 12.3021 10.8979 11.6689 11.29 11.28L17.58 5Z"
      fill="currentColor"
    />
  </svg>
);
