export interface BrandImage {
  name: string;
  value: string;
  href: string;
}

export const BRANDS_LIST: BrandImage[] = [
  {
    name: 'ferrari',
    value: 'Ferrari',
    href: 'https://carconfigurator.ferrari.com/de_de',
  },
  {
    name: 'lamborghini',
    value: 'Lamborghini',
    href: 'https://configurator.lamborghini.com/configurator/?lang=deu&country=de',
  },
  {
    name: 'pagani',
    value: 'Pagani',
    href: 'http://virtualexp.pagani.com/',
  },
  {
    name: 'bugatti',
    value: 'Bugatti',
    href: 'https://www.bugatti.com/de/',
  },
  {
    name: 'mercedes',
    value: 'Mercedes-Benz',
    href: 'https://www.mercedes-benz.de/passengercars/configurator.html?group=all&subgroup=see-all&view=BODYTYPE',
  },
  {
    name: 'porsche',
    value: 'Porsche',
    href: 'https://www.porsche.com/germany/modelstart/',
  },
  {
    name: 'bmw',
    value: 'BMW',
    href: 'https://www.bmw.de/de/konfigurator.html',
  },
  {
    name: 'bentley',
    value: 'Bentley',
    href: 'https://www.bentleymotors.com/en/misc/car-configurator.html',
  },
  {
    name: 'landrover',
    value: 'Land Rover',
    href: 'https://www.landrover.de/build-your-own/index.html',
  },
  {
    name: 'audi',
    value: 'Audi',
    href: 'http://configurator.audi.com/acc/configurator/v1/b2b/ger',
  },
  {
    name: 'aston',
    value: 'Aston Martin',
    href: 'https://configurator.astonmartin.com/#/',
  },
  {
    name: 'rimac',
    value: 'Rimac',
    href: 'https://ctwo.rimac-automobili.com/',
  },
];
