export const leftArrow = (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.00005H3.14L6.77 2.64005C7.12346 2.2148 7.06526 1.58351 6.64 1.23005C6.21474 0.876592 5.58346 0.934795 5.23 1.36005V1.36005L0.23 7.36005H0.23C0.196361 7.40778 0.166279 7.45791 0.14 7.51005C0.14 7.56005 0.14 7.59005 0.0699999 7.64005H0.07C0.0246735 7.75471 0.0009411 7.87676 0 8.00005L1.32145e-08 8.00006C0.000941348 8.12334 0.0246739 8.2454 0.0700006 8.36006C0.0700006 8.41006 0.0700006 8.44006 0.140001 8.49006C0.16628 8.5422 0.196361 8.59233 0.230001 8.64006L5.23 14.6401C5.42036 14.8686 5.70257 15.0005 6 15.0001C6.23365 15.0005 6.46009 14.9191 6.64 14.7701C7.06517 14.4176 7.12409 13.7871 6.7716 13.362C6.77107 13.3613 6.77053 13.3607 6.77 13.3601L3.14 9.00005H15C15.5523 9.00005 16 8.55234 16 8.00005C16 7.44777 15.5523 7.00005 15 7.00005V7.00005Z"
      fill="currentColor"
    />
  </svg>
);
