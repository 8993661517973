import { Box, BoxProps, Flex, Heading, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import NextLink from 'next/link';
import { FC } from 'react';
import Image from 'next/image';
import { TranslationsContent } from 'src/theme/translations/api';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';

export interface QRCodeWithSocialProps {
  name: string;
  position?: (keyof TranslationsContent['positions'])[];
  contact: string;
  qrURL: string;
  whatsApp?: boolean;
  viber?: boolean;
  telegram?: boolean;
}

const QRCodeWithSocial: FC<QRCodeWithSocialProps & Omit<BoxProps, 'position'>> = ({
  name,
  contact,
  position,
  qrURL,
  whatsApp = true,
  viber = true,
  telegram = true,
  ...boxProps
}) => {
  const theme = useBaseTheme();
  const phone = contact.split('+').join('').split(' ').join('');
  const { headings, paragraph } = theme;
  const { translations } = useTranslationsContext();
  const possiblePositions = translations?.positions;
  let positionsOutput: string | undefined = undefined;
  if (position && possiblePositions) {
    positionsOutput = position.map((pos) => possiblePositions[pos]).join(' / ');
  }

  return (
    <Box color="white" mb={[8, null, null, 8]} {...boxProps}>
      <Flex alignItems="center">
        <Box
          width={{
            base: '100px',
            sm: '140px',
          }}
          h="100%"
          borderRadius="0.5rem"
        >
          <Image src={qrURL} alt={`${name} QR Code`} layout="responsive" width={252} height={252} />
        </Box>
        <Box flex="1" ml="0.9375rem">
          <Heading {...headings?.h6} as="h6" mb="0.5rem" textTransform="uppercase">
            {name}
          </Heading>
          {positionsOutput && (
            <Text {...paragraph?.body5} as="h6" fontSize={['sm', null, 'lg']} mb="0.5rem">
              {positionsOutput}
            </Text>
          )}
          <Text {...paragraph?.body5} as="h6" mb="0.5rem">
            {contact}
          </Text>
          <Box pr="2rem">
            <Flex>
              {whatsApp && (
                <NextLink href={`https://api.whatsapp.com/send?phone=${phone}`}>
                  <a target="_blank">
                    <Box cursor="pointer" mr={4} transition="0.1s" _hover={{ transform: 'scale(1.3)' }}>
                      <Image src="/assets/icons/whatsapp.svg" width={20} height={20} />
                    </Box>
                  </a>
                </NextLink>
              )}

              {viber && (
                <NextLink href={`https://msng.link/o/?${phone}=vi`}>
                  <a target="_blank">
                    <Box cursor="pointer" mr={4} transition="0.1s" _hover={{ transform: 'scale(1.3)' }}>
                      <Image src="/assets/icons/viber.svg" width={20} height={20} />
                    </Box>
                  </a>
                </NextLink>
              )}
              {telegram && (
                <NextLink href={`https://msng.link/o/?${phone}=tg`}>
                  <a target="_blank">
                    <Box cursor="pointer" mr={4} transition="0.1s" _hover={{ transform: 'scale(1.3)' }}>
                      <Image src="/assets/icons/telegram.svg" width={20} height={20} />
                    </Box>
                  </a>
                </NextLink>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default QRCodeWithSocial;
