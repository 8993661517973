import { Box, ThemeProvider } from '@chakra-ui/react';
import { BaseTheme, Headings, useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { BaseSectionProps } from '../common/sectionsApi';

interface BlockTextSectionProps {
  title?: Content[];
  content?: Content[];
}

const overrideSpecificHeadingsTheme = (theme: BaseTheme, headings: Headings): BaseTheme => {
  return {
    ...theme,
    headings: {
      ...theme.headings,
      ...headings,
    },
  };
};

const BlockTextSection: FC<BaseSectionProps<BlockTextSectionProps>> = ({ data }) => {
  const theme = useBaseTheme();
  const { sections, headings } = theme;
  const { sectionWrapper } = sections?.blockTextSection ?? {};
  const { title, content } = data;

  const titleTheme = overrideSpecificHeadingsTheme(theme, {
    h4: {
      color: 'rgba(255,255,255,0.7)',
      fontSize: ['1rem', null, null, '1.25rem'],
      fontWeight: '400',
      letterSpacing: '2px',
      lineHeight: '1.5625rem',
      textTransform: 'uppercase',
    },
    h1: {
      ...headings?.h1,
      mb: ['16px', null, null, '10px'],
      letterSpacing: '0',
    },
  });

  return (
    <SectionContainer {...sectionWrapper}>
      <Container>
        {!!title && (
          <Box maxW="832px">
            <ThemeProvider theme={titleTheme}>
              <BlockContent blocks={title} />
            </ThemeProvider>
          </Box>
        )}
        {!!content && (
          <Box>
            <BlockContent blocks={content} />
          </Box>
        )}
      </Container>
    </SectionContainer>
  );
};

export default BlockTextSection;
