import { Box, Grid, Link, Text } from '@chakra-ui/react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import NextLink from 'next/link';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { MobileMenuProps } from 'jexity/packages/components/header/headerApi';
import { MotionBox, MotionList, MotionListItem } from 'jexity/packages/components/motion/Motion';
import { FC, useCallback, useMemo, useState } from 'react';
import { getPrimaryMenuList } from '../../../../theme/menu/menuListItem';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import { MenuModalDetails } from './MenuModalDetails';
import { DEFAULT_LANGUAGE } from '../../../../theme/languages';

const MotionContainer = motion(Box);

const coverVariants: Variants = {
  open: {
    height: '100%',
    transition: { delay: 0.1, easings: 'easeIn' },
  },
  close: { height: '0', transition: { delay: 0.2, easings: 'easeOut' } },
};

const gridVariants: Variants = {
  open: {
    height: '100%',
    transition: { delay: 0.3, duration: 0.2, easings: 'easeIn' },
  },
  close: { height: '0%', transition: { duration: 2, easings: 'easeOut' } },
};

const imageTextVariants: Variants = {
  show: { left: '15%', opacity: 1, transition: { duration: 0.3, easings: 'easeIn' } },
  hidden: { left: '100%', opacity: 0, transition: { duration: 0.3, easings: 'easeOut' } },
};

const listItemVariants: Variants = {
  show: { scale: 1, opacity: 1 },
  hidden: { scale: 0.6, opacity: 0 },
};

export const DesktopMenuModal: FC<Pick<MobileMenuProps, 'open' | 'setOpen'>> = ({ open, setOpen }) => {
  const { headings, headerHeight } = useBaseTheme();
  const router = useRouter();

  const { locale = DEFAULT_LANGUAGE } = router;
  const [hoveredItemHref, setHoveredItemHref] = useState<string | undefined>();

  const localizedMenuList = useMemo(() => {
    return getPrimaryMenuList(locale);
  }, [locale]);

  const selectedMenuItem = useMemo(() => {
    const selectedItem = getPrimaryMenuList(locale).find(({ link }) => router.asPath === link.href);
    if (selectedItem) {
      setHoveredItemHref(selectedItem.link.href.toString());
    }
    return selectedItem;
  }, [locale, router.asPath, setHoveredItemHref]);

  const menuItemOnHoverEnd = useCallback(() => {
    setHoveredItemHref(undefined);
  }, [setHoveredItemHref]);

  return (
    <Box pos="absolute" top={headerHeight} w="100%" h={headerHeight?.map((h) => `calc(100% - ${h}px)`)}>
      <MotionBox pos="relative" maxH="750px" variants={coverVariants} initial="close" animate={open ? 'open' : 'close'}>
        <Box pos="absolute" top={0} left={0} h="100%" w="100%">
          <MotionContainer
            position="relative"
            margin="0 auto"
            maxW={['1216px', null, null, null, null, null, '1496px']}
            background="linear-gradient(270deg, #C7C7C7 0%, rgba(225, 225, 225, 0) 130.97%)"
            variants={gridVariants}
            initial="close"
            animate={open ? 'open' : 'close'}
            overflow="hidden"
          >
            <Grid
              gridTemplateColumns="1fr min-content"
              pt={'3rem'}
              h="100%"
              pb={'2.5rem'}
              pl={['2.875rem']}
              position="relative"
            >
              <Box zIndex={1} position="absolute" w="100%" h="100%" backgroundColor="brand.primary.600">
                <NextImage
                  src="/assets/png/menu/menu-background.png"
                  alt="carbon pattern"
                  role="presentation"
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center center"
                />
              </Box>
              <Grid gridTemplateColumns="max-content 1fr" pos="relative" zIndex={1}>
                <MotionList
                  variants={{
                    show: {
                      transition: { staggerChildren: 0.1, delayChildren: 0.2 },
                    },
                    hidden: {
                      transition: {
                        staggerChildren: 0.1,
                        delayChildren: 0,
                        staggerDirection: -1,
                      },
                    },
                  }}
                  initial="hidden"
                  animate={open ? 'show' : 'hidden'}
                  p={0}
                  m={0}
                  styleType="none"
                  w="100%"
                  h="100%"
                >
                  {localizedMenuList.map(({ title, link }) => {
                    return (
                      <MotionListItem
                        onHoverStart={() => setHoveredItemHref(link.href.toString())}
                        onHoverEnd={menuItemOnHoverEnd}
                        pos="relative"
                        variants={listItemVariants}
                        key={`${title}_key`}
                        py={4}
                        maxW="100%"
                      >
                        <AnimatePresence exitBeforeEnter>
                          {open &&
                            (selectedMenuItem?.link.href.toString() === link.href.toString() ||
                              hoveredItemHref === link.href.toString()) && (
                              <MotionBox
                                pos="absolute"
                                top="50%"
                                left="-32%"
                                height="4px"
                                width={34}
                                backgroundColor="brand.primary.500"
                                initial="hidden"
                                exit="hidden"
                                animate="show"
                                variants={{
                                  show: {
                                    opacity: 1,
                                    left: '-16%',
                                    transition: {
                                      easings: 'easeIn',
                                      duration: 0.25,
                                    },
                                  },
                                  hidden: {
                                    opacity: 0,
                                    left: '-32%',
                                  },
                                }}
                              />
                            )}
                        </AnimatePresence>
                        <Text
                          as="span"
                          d="block"
                          {...headings?.h5}
                          lineHeight="2.5rem"
                          fontWeight={500}
                          fontSize="2rem"
                        >
                          <NextLink {...link}>
                            <Link
                              transition="color .3s ease-out"
                              color={
                                selectedMenuItem?.link.href.toString() === link.href.toString()
                                  ? 'brand.primary.500'
                                  : 'white'
                              }
                              _hover={{ textDecor: 'none', color: 'brand.primary.500' }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              {title}
                            </Link>
                          </NextLink>
                        </Text>
                      </MotionListItem>
                    );
                  })}
                </MotionList>
                <Box pos="relative">
                  <AnimatePresence exitBeforeEnter={true}>
                    {localizedMenuList.map(({ title, link }) => {
                      return hoveredItemHref === link.href.toString() ? (
                        <MotionBox
                          key={`hovered-${hoveredItemHref}`}
                          pos="absolute"
                          left="100%"
                          top={0}
                          initial="hidden"
                          exit="hidden"
                          variants={imageTextVariants}
                          animate="show"
                        >
                          <Text
                            color="brand.primary.600"
                            opacity={1}
                            fontWeight={600}
                            textShadow="-2px -2px 0 #E21331, 2px -2px 0 #E21331, -2px 2px 0 #E21331, 2px 2px 0 #E21331"
                            fontSize="200px"
                            fontFamily="heading"
                            lineHeight="296.4px"
                            whiteSpace="nowrap"
                          >
                            {title}
                          </Text>
                        </MotionBox>
                      ) : null;
                    })}
                  </AnimatePresence>
                </Box>
              </Grid>
              <MenuModalDetails pos="absolute" right={0} top={0} h="100%" px="2rem" setOpen={setOpen} zIndex={4} />
            </Grid>
          </MotionContainer>
        </Box>
      </MotionBox>
    </Box>
  );
};
