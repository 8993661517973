import { FC } from 'react';
import { Box, FormLabel, FormLabelProps, InputProps, Textarea, TextareaProps } from '@chakra-ui/react';
import { MultilineStringFormField } from 'sanity-form-builder';
import { formErrorColor } from 'jexity/packages/components/form/formApi';
import { ErrorMessage } from 'jexity/packages/components/form/fieldsSerializer';
import { customFormFieldStyles } from '../fieldsSerializer';

export const MultiLineScrollStyle: FC = () => {
  return (
    <style jsx global>
      {`
        .multiline-field::-webkit-scrollbar {
          width: 7.37px;
          height: 7.37px;
        }
        .multiline-field::-webkit-scrollbar-track {
          height: 100%;
          background: #c4c4c4;
          border-radius: 3px;
        }
        .multiline-field::-webkit-scrollbar-thumb {
          background: #f9f9f9;
          border-radius: 3px;
        }
      `}
    </style>
  );
};

const MultilineStringField: FC<InputProps & MultilineStringFormField> = (props) => {
  const { label, error } = props;
  const { labelStyle, fieldStyle } = customFormFieldStyles;

  return (
    <>
      <Box pos="relative">
        <FormLabel {...(labelStyle as FormLabelProps)}>{label} </FormLabel>
        <MultiLineScrollStyle />
        <Textarea
          {...(props as TextareaProps)}
          {...(fieldStyle as TextareaProps)}
          resize="none"
          isInvalid={!!error}
          backgroundColor={!!error ? '#FFEBEB' : 'white'}
          errorBorderColor={formErrorColor}
          minH="127px"
          className="multiline-field"
        />
      </Box>
      <ErrorMessage error={error} />
    </>
  );
};

export default MultilineStringField;
