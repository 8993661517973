import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import BlockTextImageSection from '../../../../sections/block-text-image-section/BlockTextImageSection';
import BrandsSection from '../../../../sections/brands-section/BrandsSection';
import CarTeaserSection from '../../../../sections/car-teaser-section/CarTeaserSection';
import HomeHeroSection from '../../../../sections/home-hero-section/HomeHeroSection';
import ShowroomSection from '../../../../sections/showroom-section/ShowroomSection';
import { BaseContentPageProps } from '../contentApi';
import { HomePageDocument } from './home';

const HomePage: FC<BaseContentPageProps<HomePageDocument>> = ({ content }) => {
  const {
    hero,
    carTeaserSection,
    aboutUsTeaserSection,
    orderingProcessTeaserSection,
    referenceTeaserSection1,
    referenceTeaserSection2,
    showRoomSection,
  } = content ?? {};
  return (
    <Box overflow="hidden" pb={[12, null, 16, 20]} bgColor="#000">
      {hero && <HomeHeroSection data={hero} />}
      {carTeaserSection && <CarTeaserSection data={carTeaserSection} />}
      {aboutUsTeaserSection && <BlockTextImageSection data={aboutUsTeaserSection} />}
      {orderingProcessTeaserSection && <BlockTextImageSection data={orderingProcessTeaserSection} />}
      <BrandsSection pageType="homePage" />
      {referenceTeaserSection1 && <BlockTextImageSection data={referenceTeaserSection1} />}
      {referenceTeaserSection2 && <BlockTextImageSection data={referenceTeaserSection2} />}
      {showRoomSection && <ShowroomSection _type="homePage" data={showRoomSection} />}
    </Box>
  );
};

export default HomePage;
