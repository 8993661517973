import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import NextImage from 'next/image';

interface BlurBackgroundImageProps {
  desktopImage: string;
  mobileImage: string;
}

export const BlurBackgroundImage: FC<BlurBackgroundImageProps> = ({ desktopImage, mobileImage }) => {
  return (
    <Box pos="absolute" top={0} left={0} w="100%" h="100%" pointerEvents="none" zIndex={-1}>
      <Box
        d={['none', null, 'block']}
        pos="relative"
        _before={{
          content: '""',
          pos: 'absolute',
          top: '0',
          left: '0',
          w: '100%',
          h: '100%',
          bg: 'linear-gradient(to top, black, transparent)',
          zIndex: '1',
          mixBlendMode: 'color-burn',
        }}
      >
        <NextImage
          src={desktopImage}
          alt="background image"
          role="presentation"
          layout="responsive"
          width={3500}
          height={2000}
        />
      </Box>
      <Box pos="relative" d={['block', null, 'none']} opacity={0.75} height={930}>
        <NextImage src={mobileImage} alt="background image" role="presentation" layout="fill" />
      </Box>
    </Box>
  );
};
