export const bulletList = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675V1.66675ZM12.5013 10.8334H10.8346V12.5001C10.8346 12.9603 10.4615 13.3334 10.0013 13.3334C9.54107 13.3334 9.16797 12.9603 9.16797 12.5001V10.8334H7.5013C7.04107 10.8334 6.66797 10.4603 6.66797 10.0001C6.66797 9.53984 7.04107 9.16675 7.5013 9.16675H9.16797V7.50008V7.50008C9.16797 7.03984 9.54107 6.66675 10.0013 6.66675C10.4615 6.66675 10.8346 7.03984 10.8346 7.50008V9.16675H12.5013C12.9615 9.16675 13.3346 9.53984 13.3346 10.0001C13.3346 10.4603 12.9615 10.8334 12.5013 10.8334Z"
      fill="currentColor"
    />
  </svg>
);
