import { CloseIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import { MobileMenuProps } from 'jexity/packages/components/header/headerApi';
import { useMedia } from 'jexity/packages/components/media-query/useMedia';
import { FC, useEffect, useState } from 'react';
import { DesktopMenuModal } from './DesktopMenuModal';
import { MobileMenuModal } from './MobileMenuModal';

export const MenuModal: FC<Pick<MobileMenuProps, 'open' | 'setOpen'>> = ({ open, setOpen }) => {
  const { headerHeight } = useBaseTheme();
  const isDesktop = useMedia(['(min-width: 992px)'], [true], false);
  const [clientDesktop, setClientDesktop] = useState(false);

  useEffect(() => {
    return setClientDesktop(isDesktop);
  }, [isDesktop]);

  let isFirefox = false;
  if (typeof window !== 'undefined') {
    isFirefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  return (
    <Box
      id="modal-header"
      as="nav"
      pos="fixed"
      top={0}
      left={0}
      zIndex={100}
      w="100%"
      height={'100vh'}
      overflow="hidden"
      pointerEvents={open ? 'all' : 'none'}
      _before={{
        content: '""',
        pos: 'absolute',
        top: 0,
        left: 0,
        w: '100%',
        h: '100%',
        opacity: open ? 1 : 0,
        transition: '0.2s ease-out',
      }}
      d="grid"
      gridTemplateRows="min-content 1fr"
    >
      <Box
        transition="0.15s"
        opacity={open ? 1 : 0}
        pointerEvents="none"
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        bgColor={isFirefox ? 'rgba(0, 0, 0, 0.8)' : ''}
        sx={{
          backdropFilter: open && !isFirefox ? 'blur(20px)' : 'none',
        }}
      />

      <Container pos="relative" zIndex={1} variant="wide">
        <Box pos="relative" h={headerHeight} section-data-key="home-close-wrapper">
          <Box
            data-section-key="close-icon"
            pos="absolute"
            top={['20px', '30px', null, null, '34px']}
            right={[0, null, null, null, null, null, 8]}
            // initial="hidden"
            transition="0.15s"
            opacity={open ? 1 : 0}
          >
            <IconButton
              pos="relative"
              zIndex={10}
              backgroundColor="transparent"
              aria-label="close button"
              icon={<CloseIcon />}
              onClick={() => setOpen(!open)}
              transition="0.15s"
              _hover={{ backgroundColor: 'none', transform: 'scale(1.2)' }}
              _active={{ backgroundColor: 'none' }}
            />
          </Box>
        </Box>
      </Container>
      <Box h="100%">
        {!clientDesktop && <MobileMenuModal open={open} setOpen={setOpen} />}
        {clientDesktop && <DesktopMenuModal open={open} setOpen={setOpen} />}
      </Box>
    </Box>
  );
};
