export const Menu = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.06C3 7.57914 3.42086 8 3.94 8H20.06C20.5791 8 21 7.57914 21 7.06V6.94C21 6.42086 20.5791 6 20.06 6H3.94C3.42086 6 3 6.42086 3 6.94V7.06ZM7 12.06C7 12.5791 7.42086 13 7.94 13H20.06C20.5791 13 21 12.5791 21 12.06V11.94C21 11.4209 20.5791 11 20.06 11H7.94C7.42086 11 7 11.4209 7 11.94V12.06ZM4.70711 12.7071C5.09763 12.3166 5.09763 11.6834 4.70711 11.2929C4.31659 10.9024 3.68342 10.9024 3.2929 11.2929C2.90238 11.6834 2.90238 12.3166 3.2929 12.7071C3.68342 13.0976 4.31659 13.0976 4.70711 12.7071ZM3.94 18C3.42086 18 3 17.5791 3 17.06V16.94C3 16.4209 3.42086 16 3.94 16H20.06C20.5791 16 21 16.4209 21 16.94V17.06C21 17.5791 20.5791 18 20.06 18H3.94Z"
      fill="currentColor"
    />
  </svg>
);
