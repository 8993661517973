import React, { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { Tracking } from 'jexity/packages/components/tracking/Tracking';
import { CookiesShowContext } from 'src/components/cookies/cookiesContext';
import { Box, ThemeProvider } from '@chakra-ui/react';
import { defaultCookieCategories, initializeCookies } from 'src/components/cookies/cookiesUtils';
import { BaseTheme, useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { AcceptedCookie } from 'src/components/cookies/cookiesApi';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { MenuCta, MenuItem } from 'jexity/packages/components/header/headerApi';

const CookiesSnackbar = dynamic(import('src/components/cookies/CookiesSnackbar'), { ssr: false });
export interface NavigationLinks {
  menuItems: MenuItem[];
  menuCtaItems: MenuCta[];
  footerLinks: MenuItem[];
}

export const Layout: FC<NavigationLinks> = ({ footerLinks = [], children }) => {
  const theme = useBaseTheme();
  const { cookieCategories } = theme;
  const [showCookies, setShowCookies] = useState(false);
  const [cookies, setCookies] = useState<AcceptedCookie | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setCookies(initializeCookies(cookieCategories ?? defaultCookieCategories));
    }, 4000);
  }, [cookieCategories]);

  return (
    <>
      <CookiesShowContext.Provider value={{ showCookies, setShowCookies }}>
        <Tracking />
        <Header />
        <Box minH="100%" pos="relative" overflow="hidden">
          {children}
        </Box>
        <Footer footerLinks={footerLinks} />
        <div id="portal-root" />
        <ThemeProvider
          theme={
            {
              ...theme,
              paragraph: {
                ...theme.paragraph,
                body3: {
                  ...theme.paragraph?.body3,
                  color: 'rgba(0,0,0,.8)',
                },
                body6: {
                  ...theme.paragraph?.body6,
                  lineHeight: '1.3rem',
                  color: '#0F0F0F',
                },
              },
              headings: {
                ...theme.headings,

                h4: {
                  ...theme.headings?.h4,
                  fontSize: 'lg',
                  fontFamily: 'heading',
                  textTransform: 'uppercase',
                  color: 'gray.800',
                },
              },
            } as BaseTheme
          }
        >
          {cookies && <CookiesSnackbar cookies={cookies} />}
        </ThemeProvider>
      </CookiesShowContext.Provider>
    </>
  );
};
