import { Box, Grid, Heading, Icon, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { OrderProcessSection as OrderProcessSectionData } from '../../feature/page/content/order-process-page/orderProcess';
import { BaseSectionProps } from '../common/sectionsApi';
import { ORDER_PROCESS_IMAGES } from './orderProcessImages';

export interface OrderProcessSectionProps extends BaseSectionProps<OrderProcessSectionData[]> {
  serializer?: Partial<BlockContentSerializer>;
}

const OrderProcessSection: FC<OrderProcessSectionProps> = ({ data }) => {
  const { sections, headings, paragraph } = useBaseTheme();
  const { sectionWrapper } = sections?.orderProcessSection ?? {};

  return (
    <SectionContainer {...sectionWrapper}>
      <Container>
        {data.length &&
          data.map(({ text, title }, idx) => {
            const isLastElement = idx === data.length - 1;

            return (
              <Box maxW="832px" key={`grid-${title}-${idx}`} role="group" position="relative">
                <Grid
                  mb={isLastElement ? 0 : ['56px', null, '40px']}
                  gridGap={['1rem', null, null, '2rem']}
                  gridTemplateColumns={['1fr', '5rem 1fr', null, '10rem 1fr']}
                >
                  <Box
                    pos="relative"
                    transition=".4s ease-out"
                    color="gray.400"
                    _groupHover={{ backgroundColor: 'brand.primary.500', color: 'white' }}
                    backgroundColor="gray.200"
                    d="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxSize={['5rem', null, '10rem']}
                  >
                    <Box color="gray.800" _groupHover={{ color: 'white' }} maxW={['32px', null, '64px']}>
                      <Icon
                        as={ORDER_PROCESS_IMAGES[idx]}
                        w="100%"
                        h="100%"
                        color="brand.primary.500"
                        transition=".4s ease-out"
                        _groupHover={{
                          color: 'white',
                        }}
                      />
                    </Box>
                    <Box pos="absolute" zIndex={2} bottom={0} right={['6px', null, '11px']}>
                      <Text
                        fontFamily="heading"
                        fontWeight={700}
                        fontSize={['32px', null, '64px']}
                        lineHeight={0.75}
                        color="inherit"
                      >
                        {idx + 1}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    flexDir="column"
                    mt={[0, null, '1.5rem']}
                    color="white"
                    _groupHover={{ color: 'brand.primary.500' }}
                    transition=".4s ease-out"
                  >
                    <Heading as="h5" {...headings?.h5} color="brand.primary.500">
                      {title}
                    </Heading>
                    <Text mt="8px" {...paragraph?.body4}>
                      {text}
                    </Text>
                  </Box>
                </Grid>
              </Box>
            );
          })}
      </Container>
    </SectionContainer>
  );
};

export default OrderProcessSection;
