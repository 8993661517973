import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, ComponentWithAs, Flex, Icon, IconProps, Link, List, ListItem, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import Button from 'jexity/packages/components/button/Button';
import { Container } from 'jexity/packages/components/container/Container';
import { HeaderMenuLink } from 'jexity/packages/components/header/HeaderMenuLink';
import { useSiteSettingsContext } from 'jexity/packages/feature/common';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef, useState } from 'react';
import MainLogoDark from 'src/theme/icons/MainLogoDark';
import { CarIcon, EnglishLanguageIcon, MenuIcon, Phone } from '../../../theme/icons';
import { LANGUAGES } from '../../../theme/languages';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';
import { LanguageSelector } from './language-selector/LanguageSelector';
import { MenuModal } from './menu-modal/MenuModal';

export const Header: FC = () => {
  const theme = useBaseTheme();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isLanguageOpen, setLanguageOpen] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<ComponentWithAs<'svg', IconProps>>(EnglishLanguageIcon);
  const router = useRouter();
  const [scrolled, setScrolled] = useState<boolean>(false);
  const { companyInfo } = useSiteSettingsContext();
  const { translations } = useTranslationsContext();
  const { carsLabel, menuLabel } = translations?.header ?? {};
  const { phone = '' } = companyInfo;
  const { headerHeight, paragraph } = theme;
  const ref = useRef<HTMLElement | null>();

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled]);

  useEffect(() => {
    ref.current = document.getElementById('__next');
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    const lang = LANGUAGES.find((lang) => lang.key === router.locale);

    if (!!lang) {
      setSelectedIcon(lang.icon);
    }
  }, [router.locale]);

  return (
    <>
      <Box
        id="header-nav"
        pos="fixed"
        top={0}
        right={0}
        left={0}
        zIndex={5}
        w="100vw"
        h={headerHeight}
        transition=".3s background-color ease-in"
        backgroundColor={scrolled ? 'black' : 'transparent'}
      >
        <Container variant="wide" position="relative" mb={0} w="100%" h="100%" textTransform="uppercase">
          <Box
            pos="relative"
            zIndex={1}
            d="grid"
            gridTemplateColumns="max-content auto"
            alignItems="center"
            gridTemplateAreas='"logo menu"'
            h="100%"
            color="white"
          >
            <Flex id="navbar-logo" gridArea="logo">
              <NextLink href="/" passHref>
                <Link
                  gridArea="logo"
                  fontFamily="heading"
                  aria-label="home"
                  _hover={{
                    textDecor: 'none',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <Box w={[120, null, 190]} h="100%">
                    <MainLogoDark id="header-logo" width="172px" />
                  </Box>
                </Link>
              </NextLink>

              {!!phone && (
                <Box role="group" d={['none', null, null, 'flex']} alignItems="center" pl={'4rem'}>
                  <Link
                    d="flex"
                    alignItems="center"
                    href={`tel:015143187621`}
                    color="white"
                    transition=".4s ease-out"
                    _groupHover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{
                      boxShadow: 'none',
                      color: 'brand.primary.500',
                    }}
                  >
                    <Icon as={Phone} boxSize="20px" mr="5px" color="inherit" />
                    <Text {...paragraph?.body4} letterSpacing="2px" color="inherit">
                      {phone}
                    </Text>
                  </Link>
                </Box>
              )}
            </Flex>
            <Box as="nav" gridArea="menu">
              <List d={'flex'} p={0} m={0} h={headerHeight} listStyleType="none" justifyContent="flex-end">
                <ListItem
                  key={`phone_key`}
                  d={['flex', null, null, 'none']}
                  alignItems="center"
                  role="group"
                  px={[2, 4]}
                  _last={{
                    pr: 0,
                  }}
                >
                  <Link
                    d="flex"
                    alignItems="center"
                    href={`tel:${phone.replace(/\s/g, '')}`}
                    color="white"
                    transition=".4s ease-out"
                    _groupHover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <Icon as={Phone} boxSize="20px" mr="5px" color="inherit" />
                  </Link>
                </ListItem>
                <ListItem
                  key={`cars_key`}
                  d="flex"
                  alignItems="center"
                  role="group"
                  px={[2, 4]}
                  _last={{
                    pr: 0,
                  }}
                >
                  <HeaderMenuLink
                    link={{ href: '/cars', locale: router.locale }}
                    underlineColor="none"
                    d="flex"
                    transition=".4s ease-out"
                    _groupHover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <Icon as={CarIcon} mr="5px" boxSize="24px" color="inherit" />
                    <Text d={['none', null, null, 'block']} {...paragraph?.body4} letterSpacing="2px" color="inherit">
                      {carsLabel}
                    </Text>
                  </HeaderMenuLink>
                </ListItem>
                <ListItem
                  key={`menu_key`}
                  d="flex"
                  alignItems="center"
                  role="group"
                  px={[2, 4]}
                  _last={{
                    pr: 0,
                  }}
                >
                  <Button
                    pos="relative"
                    backgroundColor="transparent"
                    alignItems="center"
                    minW="auto"
                    textTransform="uppercase"
                    fontFamily="heading"
                    role="group"
                    lineHeight={1}
                    py={0}
                    px={0}
                    d="flex"
                    transition=".4s ease-out"
                    outline={0}
                    _active={{ backgroundColor: 'transparent' }}
                    _hover={{ backgroundColor: 'transparent' }}
                    _groupHover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                    onClick={() => setOpen(true)}
                  >
                    <Icon as={MenuIcon} mr="5px" boxSize="24px" color="inherit" />
                    <Text d={['none', null, null, 'block']} letterSpacing="2px" {...paragraph?.body4} color="inherit">
                      {menuLabel}
                    </Text>
                  </Button>
                </ListItem>
                <ListItem
                  key={`language_key`}
                  d={['none', null, null, 'flex']}
                  alignItems="center"
                  role="group"
                  px={4}
                  _last={{
                    pr: 0,
                  }}
                >
                  <HeaderMenuLink
                    underlineColor="none"
                    d="flex"
                    transition=".4s ease-out"
                    onClick={() => setLanguageOpen((prev) => !prev)}
                    _groupHover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <Icon as={selectedIcon} mr="5px" boxSize="24px" />
                    <Icon as={ChevronDownIcon} boxSize="24px" color="inherit" />
                  </HeaderMenuLink>
                </ListItem>
              </List>
            </Box>
          </Box>
          <MenuModal open={isOpen} setOpen={setOpen} />
          <LanguageSelector open={isLanguageOpen} setOpen={setLanguageOpen} setSelectedIcon={setSelectedIcon} />
        </Container>
      </Box>
    </>
  );
};
