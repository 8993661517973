import { Box, Flex, Grid, Link, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import { MenuItem } from 'jexity/packages/components/header/headerApi';
import { useSiteSettingsContext } from 'jexity/packages/feature/common';
import React, { FC } from 'react';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';
import { FooterLinks } from './FooterLinks';
import { FooterSocialMedia } from './FooterSocialMedia';
import NextLink from 'next/link';
import MainLogoDark from 'src/theme/icons/MainLogoDark';

interface FooterProps {
  footerLinks: MenuItem[];
}

export function createPhoneLink(phone?: string): string {
  return phone ? `tel:${phone.split(' ').join('')}` : '';
}

export const Footer: FC<FooterProps> = ({ footerLinks = [] }) => {
  const {
    siteSettings,
    companyInfo: { name },
  } = useSiteSettingsContext();
  const { paragraph } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { termsAndConditionText, privacyPolicyText, poweredByText } = translations?.footer ?? {};
  const socialMedia = siteSettings.socialMedia ?? [];

  return (
    <Box
      minH={['247px']}
      pt={['2.375rem', null, '2.5rem', null, '4.125rem']}
      pb={['2.0625rem', null, '2.5rem', null, '3.1875rem']}
      bg="brand.secondary.900"
      color="white"
    >
      <Container variant="normal" w="100%" fontSize="sm">
        <Grid
          rowGap="2.5rem"
          alignItems="center"
          pos="relative"
          pb={['4.6875rem', null, '2.625rem']}
          templateColumns={['1fr', null, null, null, 'min-content 1fr']}
          gridGap={'2rem'}
        >
          <Box justifySelf={['flex-start', null, 'center', null, 'flex-start']}>
            <Box w={[120, null, 190, 194]}>
              <MainLogoDark id="footer-logo" width="100%" />
            </Box>
          </Box>
          <FooterLinks footerLinks={footerLinks} />
        </Grid>
        <Grid pos="relative" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']}>
          <Flex flexDir={['column', null, 'row']} justifyContent={['flex-start', null, 'center', null, 'flex-start']}>
            <FooterSocialMedia socialMedia={socialMedia} />
            <Flex pt={['4.1875rem', null, 0]}>
              <Text
                {...paragraph?.body3}
                letterSpacing="1px"
                color="gray.800"
                mr={['3.125rem', null, '1.5rem', '1.5rem']}
              >
                <NextLink href="/impressum" passHref>
                  <Link
                    transition=".4s ease-out"
                    _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {termsAndConditionText}
                  </Link>
                </NextLink>
              </Text>
              <Text {...paragraph?.body3} letterSpacing="1px" color="gray.800">
                <NextLink href="/datenschutz" passHref>
                  <Link
                    transition=".4s ease-out"
                    _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {privacyPolicyText}
                  </Link>
                </NextLink>
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir={['column', 'row']}
            pt={['3.625rem', null, '2.625rem', null, 0]}
            justifyContent={['flex-start', null, 'center', null, 'flex-end']}
          >
            <Text {...paragraph?.body3} letterSpacing="1px" color="gray.800" mr={1}>
              @ {new Date().getFullYear()} {name}
              {' | '}
            </Text>
            <Text {...paragraph?.body3} letterSpacing="1px" color="gray.800">
              {poweredByText}{' '}
              <Link
                href="https://evelan.de/"
                isExternal={true}
                transition=".4s ease-out"
                _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
              >
                Evelan
              </Link>
            </Text>
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};
