import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import NextImage from 'next/image';

const Floor: FC<BoxProps & { home?: boolean; priority?: boolean }> = ({ ...otherProps }) => (
  <Box
    h="auto"
    w="100vw"
    pos="relative"
    // No harm in setting a crazy value here
    left="-5000px"
    right="-5000px"
    margin="auto"
    zIndex={1}
    overflow="hidden"
    userSelect="none"
    {...otherProps}
  >
    <NextImage
      src={`/assets/png/home/floor-cropped.jpg`}
      alt="Asphalt"
      layout="responsive"
      role="presentation"
      priority={false}
      width={3840}
      height={700}
    />
  </Box>
);

export default Floor;
