export const DeLanguage = (
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="20" fill="white" />
    <mask id="de-lang-icon-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
      <rect width="28" height="20" fill="white" />
    </mask>
    <g mask="url(#mask_for_de_flag)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 6.66667H28V0H0V6.66667Z" fill="#262626" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3334H28V6.66675H0V13.3334Z" fill="#F01515" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 19.9999H28V13.3333H0V19.9999Z" fill="#FFD521" />
    </g>
  </svg>
);
