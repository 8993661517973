import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import { BaseContentPageProps } from '../contentApi';
import { CarsDocument } from './cars';
import CarsSection from '../../../../sections/cars-section/CarsSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';

const CarsPage: FC<BaseContentPageProps<CarsDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();
  const { title, cars, ctaSection } = content ?? {};

  return (
    <Box pt={headerHeight}>
      {title && <BlockTextSection data={{ title }} />}
      {Array.isArray(cars) && <CarsSection data={cars} />}
      {ctaSection && <CtaSection data={ctaSection} />}
    </Box>
  );
};

export default CarsPage;
