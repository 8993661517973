import { Box, Icon, ThemeProvider } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { overrideBulletList, overrideThemeTextWith } from 'jexity/packages/baseTheme/themeUtil';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { isBlockContent, isBlockTitle } from 'jexity/packages/components/block-content/blockContentUtils';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { OfferSection as OfferSectionProps } from '../../feature/page/content/commission-sales-page/commissionSales';
import { ListIcon } from '../../theme/icons';
import { BaseSectionProps } from '../common/sectionsApi';

const OfferSection: FC<BaseSectionProps<OfferSectionProps>> = ({ data }) => {
  const theme = useBaseTheme();
  const { sections } = theme;
  const { sectionWrapper } = sections?.offerSection ?? {};
  const { title, content } = data;

  const headingTheme = overrideThemeTextWith(theme, {
    fontFamily: 'heading',
    fontSize: ['24px', null, null, '40px'],
    fontWeight: 400,
    lineHeight: ['34px', null, null, '48px'],
  });

  const listTheme = overrideBulletList(theme, {
    icon: <Icon as={ListIcon} display="block" mt={1} mr={2} />,
    display: 'flex',
    mt: 0,
    ml: 0,
    mb: '1rem',
    _before: {
      content: '""',
    },
  });

  return (
    <SectionContainer {...sectionWrapper}>
      <Container>
        {isBlockTitle(title) && (
          <Box>
            <ThemeProvider theme={headingTheme}>
              <BlockContent blocks={title} />
            </ThemeProvider>
          </Box>
        )}
        {isBlockContent(content) && (
          <Box mt={['1.5rem', null, null, '2rem']}>
            <ThemeProvider theme={listTheme}>
              <BlockContent blocks={content} />
            </ThemeProvider>
          </Box>
        )}
      </Container>
    </SectionContainer>
  );
};

export default OfferSection;
