import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import CommissionOverviewSection from '../../../../sections/commission-overview-section/CommissionOverviewSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';
import OfferSection from '../../../../sections/offer-section/OfferSection';
import { BaseContentPageProps } from '../contentApi';
import { CommissionSalesDocument } from './commissionSales';

const CommissionSalesPage: FC<BaseContentPageProps<CommissionSalesDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();
  const { offerSection, commissionOverviewSection, ctaSection } = content ?? {};
  return (
    <Box pt={headerHeight}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/commission-sales.png"
        mobileImage="/assets/png/pages/commission-sales-mobile.png"
      />
      {commissionOverviewSection && <CommissionOverviewSection data={commissionOverviewSection} />}
      {offerSection && <OfferSection data={offerSection} />}
      {ctaSection && <CtaSection data={ctaSection} />}
    </Box>
  );
};

export default CommissionSalesPage;
