export const galleryContinuous = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3327 15.3332C25.5418 15.3332 27.3327 17.124 27.3327 19.3332V23.3332C27.3327 25.5423 25.5418 27.3332 23.3327 27.3332H4.66602C2.45688 27.3332 0.666016 25.5423 0.666016 23.3332V19.3332C0.666016 17.124 2.45688 15.3332 4.66602 15.3332H23.3327ZM23.3327 17.9998H4.66602C3.92964 17.9998 3.33268 18.5968 3.33268 19.3332V23.3332C3.33268 24.0696 3.92964 24.6665 4.66602 24.6665H23.3327C24.0691 24.6665 24.666 24.0696 24.666 23.3332V19.3332C24.666 18.5968 24.0691 17.9998 23.3327 17.9998ZM23.3327 0.666504C25.5418 0.666504 27.3327 2.45736 27.3327 4.6665V8.6665C27.3327 10.8756 25.5418 12.6665 23.3327 12.6665H4.66602C2.45688 12.6665 0.666016 10.8756 0.666016 8.6665V4.6665C0.666016 2.45736 2.45688 0.666504 4.66602 0.666504H23.3327ZM23.3327 3.33317H4.66602C3.92964 3.33317 3.33268 3.93012 3.33268 4.6665V8.6665C3.33268 9.40288 3.92964 9.99984 4.66602 9.99984H23.3327C24.0691 9.99984 24.666 9.40288 24.666 8.6665V4.6665C24.666 3.93012 24.0691 3.33317 23.3327 3.33317Z"
      fill="currentColor"
    />
  </svg>
);
