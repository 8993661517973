import { Skeleton, Box } from '@chakra-ui/react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { FC } from 'react';
import { mapStyle } from 'src/components/map/mapStyle';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';

export const libraries: any = ['places'];
export const Map: FC<{ location: { lat: number; lng: number } }> = ({ location }) => {
  const { translations } = useTranslationsContext();

  if (process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY === undefined) {
    throw new Error('Missing api key for google map');
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const renderMap = () => {
    return (
      <Box w="100%" h="100%">
        <style jsx global>
          {`
            .gmnoprint,
            .gm-style-cc {
              display: none !important;
            }
          `}
        </style>
        <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={15}
          center={location}
          options={{
            styles: mapStyle,
          }}
        >
          <Marker position={location} />
        </GoogleMap>
      </Box>
    );
  };

  if (loadError) {
    return <Box>{translations?.map.failedToLoadError}</Box>;
  }

  return (
    <Skeleton isLoaded={isLoaded} startColor="brand.primary.300" endColor="brand.primary.400" w="100%" h="100%">
      {isLoaded && renderMap()}
    </Skeleton>
  );
};
