import { Box, Heading, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { isValidCta } from 'jexity/packages/components/block-content/blockContentUtils';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { CtaBannerLink } from '../../components/cta-banner-link/CtaBannerLink';
import { CtaSection as CtaSectionProps } from '../../feature/page/content/commission-sales-page/commissionSales';
import { RightArrow } from '../../theme/icons';
import { BaseSectionProps } from '../common/sectionsApi';

const CtaSection: FC<BaseSectionProps<CtaSectionProps> & { onClickFirstCta?: () => void }> = ({
  data,
  onClickFirstCta,
}) => {
  const { sections, headings } = useBaseTheme();
  const { sectionWrapper } = sections?.ctaSection ?? {};
  const { primaryCta, secondaryCta } = data;

  return (
    <SectionContainer {...sectionWrapper}>
      <Container pb={[12, null, 16, 20]}>
        <Box border="1px solid rgba(255, 255, 255, 0.4)" mb="1.25rem" />

        <SimpleGrid columns={[1, null, 2]}>
          {!!primaryCta && isValidCta(primaryCta) && (
            <CtaBannerLink
              whiteSpace="normal"
              p={['44px 16px', null, null, '44px 32px']}
              w="100%"
              textAlign="start"
              backgroundColor="brand.primary.500"
              d="flex"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="start"
              _hover={{ outline: 0, backgroundColor: 'brand.primary.800' }}
              onClick={!!onClickFirstCta ? onClickFirstCta : undefined}
              cta={primaryCta}
            >
              <Text
                mb="8px"
                as="p"
                {...headings?.h5}
                fontWeight={500}
                fontSize={['24px', null, null, '32px']}
                lineHeight={['40px', null, null, '42px']}
                textTransform="none"
              >
                {primaryCta.label}
              </Text>
              <Box d="flex" flexDir="row" alignItems="center">
                <Heading as="h6" {...headings?.h6} fontWeight={500} fontSize="1rem" lineHeight="24px">
                  {primaryCta.subline}
                </Heading>
                <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
              </Box>
            </CtaBannerLink>
          )}
          {!!secondaryCta && isValidCta(secondaryCta) && (
            <CtaBannerLink
              whiteSpace="normal"
              cta={secondaryCta}
              p={['44px 16px', null, null, '44px 32px']}
              w="100%"
              textAlign="start"
              backgroundColor="brand.secondary.900"
              _hover={{ outline: 0, backgroundColor: 'brand.primary.800' }}
            >
              <Text
                mb="8px"
                as="p"
                {...headings?.h5}
                fontWeight={500}
                fontSize={['24px', null, null, '32px']}
                lineHeight={['40px', null, null, '42px']}
                textTransform="none"
              >
                {secondaryCta.label}
              </Text>
              <Box d="flex" flexDir="row" alignItems="center">
                <Heading as="h6" {...headings?.h6} fontWeight={500} fontSize="1rem" lineHeight="24px">
                  {secondaryCta.subline}
                </Heading>
                <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
              </Box>
            </CtaBannerLink>
          )}
        </SimpleGrid>
      </Container>
    </SectionContainer>
  );
};

export default CtaSection;
