export const orderProcessPhone = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0797 40.6933C56.9758 40.9012 57.7015 41.5563 57.9997 42.4266L57.9994 42.4265C58.2244 43.0594 58.394 43.7108 58.506 44.3731C58.6134 45.0433 58.6669 45.721 58.6661 46.3998L58.6661 46.4C58.6661 53.1747 53.1741 58.6667 46.3994 58.6667C23.7312 58.6371 5.36229 40.2681 5.33301 17.5997C5.3331 10.8251 10.825 5.33325 17.5997 5.33325C18.2912 5.35432 18.979 5.44364 19.653 5.59992C20.3072 5.69672 20.9503 5.85749 21.573 6.07992C22.4433 6.37809 23.0984 7.10378 23.3063 7.99992L26.9597 23.8133C27.1598 24.6957 26.8987 25.6194 26.2663 26.2666C25.9392 26.6441 25.897 26.6653 23.0465 28.1011C22.8766 28.1867 22.6968 28.2773 22.5063 28.3733C25.133 34.1355 29.7415 38.763 35.493 41.4133C37.2263 38.1066 37.253 38.0799 37.6263 37.7333C38.2735 37.1009 39.1972 36.8398 40.0797 37.0399L56.0797 40.6933ZM34.6663 10.6666C44.9756 10.6666 53.333 19.0239 53.333 29.3333C53.333 30.806 54.5269 31.9999 55.9997 31.9999C57.4724 31.9999 58.6663 30.806 58.6663 29.3333C58.6663 16.0784 47.9212 5.33325 34.6663 5.33325C33.1936 5.33325 31.9997 6.52716 31.9997 7.99992C31.9997 9.47268 33.1936 10.6666 34.6663 10.6666ZM34.6663 21.3333C39.0846 21.3333 42.6663 24.915 42.6663 29.3333C42.6663 30.806 43.8602 31.9999 45.333 31.9999C46.8058 31.9999 47.9997 30.806 47.9997 29.3333C47.9997 21.9695 42.0301 15.9999 34.6663 15.9999C33.1936 15.9999 31.9997 17.1938 31.9997 18.6666C31.9997 20.1393 33.1936 21.3333 34.6663 21.3333Z"
      fill="currentColor"
    />
  </svg>
);
