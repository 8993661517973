import { Box, BoxProps, Link, SimpleGrid } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import { BRANDS_LIST } from '../../sections/brands-section/brandsImages';
import NextImage from 'next/image';

export const BrandIcon: FC<{ name: string; enableHover?: boolean } & Pick<BoxProps, 'color' | 'onClick'>> = ({
  name,
  color = 'white',
  onClick,
  enableHover = false,
}) => (
  <Box
    pos="relative"
    h="100%"
    w="100%"
    d="flex"
    alignItems="center"
    justifyContent="center"
    color={color}
    transition=".4s ease-out"
    onClick={onClick}
  >
    <Box
      minH="7.5rem"
      h="100%"
      w="100%"
      d="grid"
      placeItems="center"
      transition=".4s transform"
      {...(enableHover && {
        _hover: {
          transform: 'scale(1.3)',
        },
      })}
    >
      <NextImage src={`/assets/png/car-brands/${name}.png`} width={120} height={name === 'rimac' ? 29 : 120} priority />
    </Box>
  </Box>
);

export const BrandsList: FC<{ isLink: boolean }> = ({ isLink }) => {
  const { gridGap } = useBaseTheme();

  return (
    <SimpleGrid gridGap={gridGap} gridRowGap={['2.75rem']} columns={[3, null, null, 6]}>
      {BRANDS_LIST.map(({ name, href }, idx) =>
        isLink ? (
          <Link key={`brand-list-item-${idx}`} role="group" isExternal={true} href={href}>
            <BrandIcon name={name} enableHover />
          </Link>
        ) : (
          <BrandIcon key={`brand-list-item-${idx}`} name={name} />
        )
      )}
    </SimpleGrid>
  );
};
