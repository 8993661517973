import React, { FC } from 'react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { Container } from 'jexity/packages/components/container/Container';
import { SimpleGrid } from '@chakra-ui/react';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import { BaseSectionProps } from '../common/sectionsApi';
import { ReferenceSectionData } from '../../feature/page/content/reference-page/reference';
import ReferenceItem from 'src/feature/page/content/reference-page/ReferenceItem';

export interface ReferenceSectionProps extends BaseSectionProps<ReferenceSectionData[]> {
  serializer?: Partial<BlockContentSerializer>;
}

const ReferenceSection: FC<ReferenceSectionProps> = ({ data }) => {
  const theme = useBaseTheme();
  const { sections } = theme;
  const { sectionWrapper } = sections?.referenceSection ?? {};

  return (
    <SectionContainer {...sectionWrapper}>
      <Container
        px={{
          sm: '3rem',
        }}
      >
        <SimpleGrid columns={[1, null, null, 2]} gridColumnGap="2rem" gridRowGap={['60px', null, null, '73px']}>
          {Array.isArray(data) &&
            data.map((reference, idx) => {
              return <ReferenceItem key={`reference-item-${idx}`} {...reference} />;
            })}
        </SimpleGrid>
      </Container>
    </SectionContainer>
  );
};

export default ReferenceSection;
