import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { CheckboxFormField } from 'sanity-form-builder';
import { JexityCheckbox, JexityCheckboxProps } from 'jexity/packages/components/checkbox/JexityCheckbox';
import { ErrorMessage } from 'jexity/packages/components/form/fieldsSerializer';

const CheckboxField: FC<JexityCheckboxProps & CheckboxFormField> = (props) => {
  const propsLabel: any = props.label;

  return (
    <>
      <Box d="flex" alignItems="flex-start" mb={3} pr={3} pl={0.8}>
        <JexityCheckbox {...props} />
        <Box ml="5">{propsLabel}</Box>
      </Box>
      <ErrorMessage error={props.error} />
    </>
  );
};

export default CheckboxField;
