export const orderProcessConfiguration = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 18.6667H11.1463C12.6537 22.8198 17.2426 24.9647 21.3957 23.4573C23.6233 22.6488 25.3778 20.8943 26.1863 18.6667H55.9997C57.4724 18.6667 58.6663 17.4728 58.6663 16C58.6663 14.5272 57.4724 13.3333 55.9997 13.3333H26.1863C24.6789 9.18016 20.0901 7.03533 15.937 8.54272C13.7094 9.35122 11.9548 11.1057 11.1463 13.3333H7.99967C6.52691 13.3333 5.33301 14.5272 5.33301 16C5.33301 17.4728 6.52691 18.6667 7.99967 18.6667ZM50.6663 24C47.2843 24.0095 44.2737 26.1447 43.1463 29.3333H7.99967C6.52691 29.3333 5.33301 30.5272 5.33301 32C5.33301 33.4728 6.52691 34.6667 7.99967 34.6667H43.1463C44.6191 38.8322 49.1899 41.0152 53.3555 39.5424C57.5211 38.0696 59.704 33.4988 58.2312 29.3333C57.0984 26.1291 54.0648 23.9905 50.6663 24ZM36.853 45.3333H55.9997C57.4724 45.3333 58.6663 46.5272 58.6663 48C58.6663 49.4728 57.4724 50.6667 55.9997 50.6667H36.853C36.0445 52.8943 34.29 54.6488 32.0624 55.4573C27.9092 56.9647 23.3204 54.8198 21.813 50.6667H7.99967C6.52691 50.6667 5.33301 49.4728 5.33301 48C5.33301 46.5272 6.52691 45.3333 7.99967 45.3333H21.813C22.6215 43.1057 24.376 41.3512 26.6036 40.5427C30.7568 39.0353 35.3456 41.1802 36.853 45.3333Z"
      fill="currentColor"
    />
  </svg>
);
