import React, { ReactElement } from 'react';
import NextApp from 'next/app';
import Router from 'next/router';
import { CSSReset, ThemeProvider } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import theme from '../theme/theme';
import { GlobalStyles } from 'jexity/packages/styles/global';

// TODO: should be moved to jsx css --> Css imports can only be done via custom app file (This is for slideshow)
import 'react-image-gallery/styles/css/image-gallery.css';
import { Fonts } from '../styles/Fonts';
import { CustomStyles } from '../styles/CustomStyles';
import { initInternalLinkMapper } from '../feature/page/contentPageSerializer';

const PreviewComponent = dynamic(() => import('jexity/packages/feature/common/previewMode/PreviewComponent'));

initInternalLinkMapper();

const onPageChange = (): void => {
  const path = window.location.pathname;

  // Facebook track page view
  if (typeof window['fbq'] === 'function') {
    const fbq = window['fbq'];
    fbq('track', 'PageView');
  }

  // GA
  if (!!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID) {
    if (typeof window['dataLayer'] === 'function') {
      const gtag = window['dataLayer'];
      if (!window['gaLoaded']) {
        gtag.push('js', new Date());
        window['dataLayer'] = true;
      }

      gtag.push('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID, {
        anonymize_ip: true,
        page_path: window.location.pathname,
        cookie_flags: 'secure;samesite=none',
      });
    }
  }

  // hotjar
  if (typeof window['hj'] === 'function') {
    const hj = window['hj'];
    hj('stateChange', path);
  }
};

class JexityApp extends NextApp {
  componentDidMount(): void {
    onPageChange();

    Router.events.on('routeChangeComplete', onPageChange);
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', onPageChange);
  }

  render(): ReactElement {
    const { Component, pageProps } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <CSSReset />
        <GlobalStyles />
        <Fonts />
        <CustomStyles />
        {pageProps?.preview ? (
          <PreviewComponent preview={pageProps?.preview} previewData={pageProps?.previewData}>
            <Component {...pageProps} />
          </PreviewComponent>
        ) : (
          <Component {...pageProps} />
        )}
      </ThemeProvider>
    );
  }
}

export default JexityApp;
