import { InternalButton, InternalButtonProps } from 'jexity/packages/components/button/InternalButton';
import { FC } from 'react';
import { Button, JexityButtonProps } from 'jexity/packages/components/button/Button';
import { Link } from '@chakra-ui/react';
import { CtaLink } from 'jexity/packages/components/block-content/cta';
import { useRouter } from 'next/router';
import { getInternalLink, useSiteSettingsContext } from 'jexity/packages/feature/common/index';
import { DEFAULT_LANGUAGE } from '../../theme/languages';

interface CtaBannerLinkData {
  cta?: CtaLink | null;
  externalLink?: string;
  target?: string;
}

type CtaBannerLinkProps = CtaBannerLinkData & Omit<InternalButtonProps, 'children'> & JexityButtonProps;

export const CtaBannerLink: FC<CtaBannerLinkProps> = ({ externalLink, target, cta, children, onClick, ...others }) => {
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { siteSettings } = useSiteSettingsContext();

  if (!!onClick) {
    return (
      <Button
        ml={0}
        mt={0}
        padding={['1.5rem 1.25rem', '1.5rem 1.6875rem']}
        letterSpacing="2px"
        onClick={onClick}
        _focus={{
          outline: 'none',
        }}
        {...others}
      >
        {children}
      </Button>
    );
  }

  return !!externalLink ? (
    <Link href={externalLink} isExternal={!!externalLink} _hover={{ textDecor: 'none' }} _focus={{ outline: 'none' }}>
      <Button ml={0} mt={0} padding={['1.5rem 1.25rem', '1.5rem 1.6875rem']} letterSpacing="2px" {...others}>
        {children}
      </Button>
    </Link>
  ) : (
    <InternalButton
      link={{
        ...getInternalLink({
          startPageId: siteSettings.startPage?._id,
          pageId: cta?.reference._id,
          _type: cta?.reference._type,
          slug: cta?.reference.slug,
        }),
        locale,
      }}
      ml={0}
      mt={0}
      padding={['1.5rem 1.25rem', '1.5rem 1.6875rem']}
      letterSpacing="2px"
      _focus={{
        outline: 'none',
      }}
      {...others}
    >
      {children}
    </InternalButton>
  );
};
