import { ChevronLeftIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { isBlockContent } from 'jexity/packages/components/block-content/blockContentUtils';
import { Container } from 'jexity/packages/components/container/Container';
import { FormContext } from 'jexity/packages/components/form/useFormContext';
import JexityModal from 'jexity/packages/components/modal/JexityModal';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import { CarCard } from 'src/components/car-card/CarCard';
import Floor from 'src/components/Floor';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';
import CarDetailForm from '../../../../components/car-detail-form/CarDetailForm';
import CarDetailsSection from '../../../../sections/car-details-section/CarDetailsSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';
import ImageGallerySection from '../../../../sections/image-gallery-section/ImageGallerySection';
import { DEFAULT_LANGUAGE } from '../../../../theme/languages';
import { BaseContentPageProps } from '../../contentPageSerializer';
import { CarDetailsDocument } from './carDetail';

const CarDetailPage: FC<BaseContentPageProps<CarDetailsDocument>> = ({ content }) => {
  const { headerHeight, headings } = useBaseTheme();
  const { locale = DEFAULT_LANGUAGE, push } = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formAdditionalValues, setFormAdditionalValues] = useState({});
  const {
    availablityPurchase,
    category,
    condition,
    coverImage,
    ctaSection,
    firstRegistration,
    gearBox,
    gallery,
    mileage,
    name,
    power,
    price,
    priceExclVat,
    relatedCars,
    yearConstruction,
    moreDetails,
  } = content;
  const { translations } = useTranslationsContext();
  const t = translations?.carDetailsPage;
  const nextCar = relatedCars?.nextCar;
  const previousCar = relatedCars?.previousCar;
  const [showDetails, setShowDetails] = useState(false);
  const images = gallery?.images;

  return (
    <FormContext.Provider value={{ formAdditionalValues, setFormAdditionalValues }}>
      <Box pt={headerHeight} pos="relative">
        <Container pos="relative">
          <Box>
            <NextLink href="/cars" locale={locale} passHref>
              <Link
                fontFamily="heading"
                fontWeight={600}
                fontSize="1rem"
                lineHeight="1.375rem"
                letterSpacing={2}
                color="gray.400"
                textTransform="uppercase"
                d="flex"
                alignItems="center"
                transition=".3s ease-in-out"
                _hover={{
                  textDecor: 'none',
                  color: 'brand.primary.500',
                }}
                _focus={{
                  outline: 'none',
                }}
              >
                <Icon
                  // name="chevron-left"
                  as={ChevronLeftIcon}
                  mr={1}
                  w="25px"
                  h="auto"
                  ml="-10px"
                  color="inherit"
                />
                {t?.backToOverviewPageLabel}
              </Link>
            </NextLink>
          </Box>
        </Container>
        <CarDetailsSection data={content} onOpen={onOpen} />
        <Box pt={Array.isArray(images) ? 4 : '5rem'}>
          {Array.isArray(images) && <ImageGallerySection images={images} />}
        </Box>

        {moreDetails && isBlockContent(moreDetails) && (
          <Container
            color="white"
            my={{
              base: '1.625rem',
              md: 0,
            }}
          >
            <Box bg="brand.secondary.900">
              <Accordion allowToggle>
                <AccordionItem
                  border="none"
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <AccordionButton
                    w="100%"
                    onClick={() => {
                      setShowDetails((bool): boolean => !bool);
                    }}
                    transition="0.5s ease-in"
                    p={0}
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    <Heading
                      bg={showDetails ? 'brand.secondary.900' : 'brand.primary.500'}
                      as="h5"
                      {...headings?.h5}
                      py="1.5rem"
                      textAlign="center"
                      flex="1"
                      _focus={{
                        boxShadow: 'none',
                      }}
                      transition="0.25s ease-in"
                      _hover={{ outline: 0, backgroundColor: 'brand.primary.800' }}
                    >
                      <Box d="flex" justifyContent="center" alignItems="center">
                        {t?.moreDetails}
                        <Box
                          as="span"
                          transition="0.25s transform ease-in"
                          transform={`rotate(${showDetails ? 0 : 180}deg)`}
                          d="grid"
                        >
                          <ChevronUpIcon />
                        </Box>
                      </Box>
                    </Heading>
                  </AccordionButton>
                  <AccordionPanel>
                    <Container variant="thin" mb="2.375rem">
                      <BlockContent blocks={moreDetails} />
                    </Container>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Container>
        )}

        {ctaSection && (
          <CtaSection
            data={ctaSection}
            onClickFirstCta={() => {
              setFormAdditionalValues({
                category,
                name,
                price,
                availablityPurchase,
                firstRegistration,
                yearConstruction,
                mileage,
                power,
                gearBox,
                condition,
              });
              // Add a delay here to make sure additional form values are set
              window.setTimeout(() => {
                onOpen();
              }, 350);
            }}
          />
        )}
      </Box>

      <Container pb={['3.438rem', null, '4.5rem', '5rem']}>
        <SimpleGrid
          pos="relative"
          justifyContent="space-between"
          gridTemplateColumns={['1fr', null, 'min-content min-content']}
          _after={{
            content: '""',
            pos: 'absolute',
            w: '114%',
            bottom: '6.12rem',
            left: '-100px',
            zIndex: '-1',
            transform: 'scale(.9)',
            boxShadow: '-5px 15px 38px 8px rgb(255 255 255 / 50%)',
          }}
        >
          <Box minW="350px" w="100%">
            {previousCar && (
              <Box
                cursor="pointer"
                onClick={() => {
                  if (previousCar.slug.current) {
                    void push(`/cars/${previousCar.slug.current}`, `/cars/${previousCar.slug.current}`, { locale });
                  }
                }}
              >
                <Text as="span" d="block" mb={2} {...headings?.h4}>
                  {t?.previousCarLabel}
                </Text>
                <CarCard
                  name={previousCar.name}
                  coverImage={previousCar.coverImage}
                  showDetails={false}
                  showViewDetailsButton={false}
                />
              </Box>
            )}
          </Box>
          <Box minW="350px" w="100%" textAlign="right">
            {nextCar && (
              <Box
                cursor="pointer"
                onClick={() => {
                  if (nextCar.slug.current) {
                    void push(`/cars/${nextCar.slug.current}`, `/cars/${nextCar.slug.current}`, { locale });
                  }
                }}
              >
                <Text as="span" d="block" mb={2} {...headings?.h4}>
                  {t?.nextCarLabel}
                </Text>
                <CarCard
                  textAlign="left"
                  name={nextCar.name}
                  coverImage={nextCar.coverImage}
                  showDetails={false}
                  showViewDetailsButton={false}
                />
              </Box>
            )}
          </Box>
          <Floor
            zIndex={-1}
            pos="absolute"
            w={['400vw', null, null, '130vw']}
            maxW="3200px"
            h="auto"
            bottom={['0', '0', '75px']}
            transform="translateY(50%)"
          />
        </SimpleGrid>
      </Container>

      <JexityModal
        blockScrollOnMount
        size="md"
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        bodyComponent={
          <CarDetailForm
            coverImage={coverImage}
            category={category}
            name={name}
            price={price}
            priceExclVat={priceExclVat}
          />
        }
        overlayStyle={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, .15)',
            backdropFilter: 'blur(20px)',
          },
        }}
        modalContentStyle={{
          borderRadius: 0,
          overflow: 'hidden',
          maxW: ['calc(100% - 16px)', null, null, null, '1216px'],
          background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF',
        }}
        modalBodyStyle={{
          p: [0, null, null, '3rem'],
          backgroundColor: 'brand.secondary.900',
        }}
      />
    </FormContext.Provider>
  );
};

export default CarDetailPage;
