import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import { RequestCarDocument } from './requestCar';
import { BaseContentPageProps } from '../contentApi';
import RequestCarFormSection from '../../../../sections/request-car-form-section/RequestCarFormSection';

export const RequestCarContext = createContext(
  {} as {
    brand?: string;
    setBrand?: Dispatch<SetStateAction<string>>;
  }
);

const RequestCarPage: FC<BaseContentPageProps<RequestCarDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();
  const [brand, setBrand] = useState('ferrari');
  const { title } = content ?? {};

  return (
    <Box pt={headerHeight} pb={[12, null, 16, 20]}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/request-car.png"
        mobileImage="/assets/png/pages/request-car-mobile.png"
      />
      {title && <BlockTextSection data={{ title }} />}
      <RequestCarContext.Provider
        value={{
          brand,
          setBrand,
        }}
      >
        <RequestCarFormSection />
      </RequestCarContext.Provider>
    </Box>
  );
};

export default RequestCarPage;
