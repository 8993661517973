import React, { FC } from 'react';
import { Box, Heading, Icon, Text, ThemeProvider } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { overrideThemeTextWith } from 'jexity/packages/baseTheme/themeUtil';
import { MotionBox } from 'jexity/packages/components/motion/Motion';
import { reponseWrapperVariants } from 'jexity/packages/components/form/formApi';
import Button from 'jexity/packages/components/button/Button';
import { RightArrow } from '../../../theme/icons';
import { CheckIcon } from '@chakra-ui/icons';

export interface FormConfirmationProps {
  message?: any;
  showConfirmation?: boolean;
  reset?: any;
  translations: {
    title: string;
    subtitle: string;
    buttonText: string;
  };
}

const FormConfirmation: FC<FormConfirmationProps> = ({ showConfirmation = false, reset, translations }) => {
  const theme = useBaseTheme();
  const { formStyles, headings, paragraph } = theme;
  const showFormBtnStyles = formStyles?.response?.showFormBtn;
  const responseStyles = formStyles?.response as any;
  const overrideText = overrideThemeTextWith(theme, { ...responseStyles?.confirmation });
  const { title, subtitle, buttonText } = translations;

  return (
    <AnimatePresence>
      {showConfirmation && (
        <MotionBox
          key="form-confirmation"
          zIndex={2}
          pos="absolute"
          top="100%"
          left={0}
          d="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          mr="auto"
          ml="auto"
          w="100%"
          h="100%"
          p="0 1.25em"
          bg="white"
          textAlign="center"
          variants={reponseWrapperVariants}
          initial="hidden"
          animate="show"
          exit="hidden"
          {...responseStyles?.wrapper}
        >
          <Box
            d="flex"
            justifyContent="center"
            alignItems="center"
            mr="auto"
            ml="auto"
            w="100px"
            h="100px"
            minH="100px"
            borderRadius="50%"
            backgroundColor="green.300"
          >
            <Icon as={CheckIcon} boxSize="50px" color="white" />
          </Box>
          <ThemeProvider theme={overrideText}>
            <Heading mt="2rem" as="h3" {...headings?.h3} fontWeight={400}>
              {title}
            </Heading>
            <Text mt="2rem" {...paragraph?.body4}>
              {subtitle}
            </Text>
          </ThemeProvider>
          <Button
            onClick={() => {
              reset();
            }}
            {...showFormBtnStyles}
          >
            {buttonText}
            <Icon ml={2} as={RightArrow} w="16px" h="14px" />
          </Button>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default FormConfirmation;
