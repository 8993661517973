export const vodafoneIcon = (
  <svg width="77" height="20" viewBox="0 0 77 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.291 10.0001C19.291 15.1906 15.1077 19.3987 9.94545 19.3987C4.78322 19.3987 0.598145 15.1906 0.598145 10.0001C0.598145 4.80958 4.78322 0.601562 9.94545 0.601562C15.1077 0.601562 19.291 4.80958 19.291 10.0001Z"
      fill="white"
    />
    <path
      d="M10.0193 15.2407C7.45189 15.2495 4.77976 13.0461 4.76774 9.50632C4.75915 7.1666 6.01623 4.91322 7.62019 3.57674C9.18638 2.27479 11.3313 1.43732 13.2753 1.43042C13.526 1.43042 13.7888 1.4511 13.9485 1.50636C12.2483 1.86034 10.8951 3.45237 10.9002 5.25681C10.9002 5.3172 10.9054 5.3811 10.9122 5.41047C13.7578 6.10807 15.0492 7.8348 15.0578 10.2228C15.0646 12.6126 13.1894 15.2303 10.0192 15.2407"
      fill="#D81A1A"
    />
    <path
      d="M25.3274 13.8922L22.4698 7.0354H24.3365L26.1191 11.5888L27.8467 7.0354H29.6292L26.8558 13.8922H25.3274Z"
      fill="white"
    />
    <path
      d="M34.6025 10.4283C34.6025 10.1434 34.5699 9.87232 34.5029 9.61504C34.4377 9.35776 34.3381 9.13674 34.2058 8.95198C34.0736 8.76549 33.9139 8.61871 33.725 8.50994C33.5361 8.39942 33.3146 8.34417 33.0604 8.34417C32.8062 8.34417 32.583 8.39943 32.3958 8.50994C32.2069 8.6187 32.0455 8.76549 31.9149 8.95198C31.7827 9.13674 31.6831 9.35776 31.6179 9.61504C31.5509 9.87232 31.5182 10.1434 31.5182 10.4283C31.5182 10.7236 31.5509 10.9981 31.6179 11.2502C31.6831 11.5023 31.7793 11.7234 31.9064 11.9133C32.0334 12.1032 32.1949 12.2535 32.3889 12.364C32.5813 12.4727 32.8062 12.528 33.0604 12.528C33.3146 12.528 33.5361 12.4727 33.725 12.364C33.9139 12.2535 34.0736 12.1032 34.2058 11.9133C34.3381 11.7234 34.4377 11.5023 34.5029 11.2502C34.5699 10.9981 34.6025 10.7236 34.6025 10.4283ZM36.3284 10.4358C36.3284 10.8831 36.2597 11.3216 36.1241 11.7533C35.9867 12.185 35.784 12.5701 35.5144 12.9119C35.2465 13.2538 34.9065 13.5284 34.4961 13.7373C34.0856 13.9463 33.6065 14.0499 33.0604 14.0499C32.5126 14.0499 32.0334 13.9462 31.6247 13.7373C31.2143 13.5284 30.8743 13.2539 30.6046 12.912C30.3367 12.5701 30.1341 12.185 29.9967 11.7533C29.8611 11.3217 29.7924 10.8831 29.7924 10.4358C29.7924 9.99036 29.861 9.55521 29.9967 9.12699C30.1341 8.70048 30.3367 8.31889 30.6046 7.98217C30.8743 7.64546 31.2143 7.37608 31.6247 7.17234C32.0334 6.96858 32.5126 6.86499 33.0604 6.86499C33.6065 6.86499 34.0839 6.96861 34.4892 7.17234C34.8945 7.37608 35.2345 7.64546 35.5076 7.98217C35.7823 8.31889 35.9867 8.70048 36.1241 9.12699C36.2597 9.55521 36.3284 9.99036 36.3284 10.4358Z"
      fill="white"
    />
    <path
      d="M41.5988 8.54449C41.2605 8.41153 40.9463 8.34419 40.6543 8.34419C40.3555 8.34419 40.0962 8.40296 39.8815 8.51686C39.6651 8.63076 39.4866 8.78449 39.3457 8.97961C39.2049 9.17474 39.1002 9.39403 39.0297 9.6375C38.9593 9.87924 38.9233 10.1296 38.9233 10.3869C38.9233 10.6718 38.9542 10.9446 39.016 11.2071C39.0761 11.4678 39.1706 11.6975 39.2976 11.8926C39.423 12.0877 39.5827 12.2448 39.7751 12.364C39.9674 12.4814 40.1958 12.5418 40.4586 12.5418C40.6749 12.5418 40.8947 12.4815 41.1197 12.364C41.3447 12.2448 41.5044 12.1033 41.5988 11.9427L41.5988 8.54449ZM41.7397 13.8921L41.6692 13.3672C41.5095 13.5468 41.2983 13.7057 41.0338 13.8421C40.7711 13.9802 40.4603 14.0493 40.1031 14.0493C39.6342 14.0493 39.2186 13.9492 38.8632 13.7506C38.5042 13.552 38.2037 13.2861 37.9599 12.9545C37.716 12.623 37.5288 12.2379 37.4017 11.8011C37.2764 11.366 37.2128 10.9101 37.2128 10.4353C37.2128 9.98976 37.2781 9.55289 37.4086 9.1264C37.5408 8.69989 37.74 8.3183 38.0079 7.98158C38.2776 7.64487 38.6124 7.3755 39.0177 7.17175C39.4213 6.96799 39.897 6.86438 40.4414 6.86438C40.7986 6.86438 41.185 6.93168 41.5988 7.06296V4.40381H43.3522V13.8921H41.7397Z"
      fill="white"
    />
    <path
      d="M48.2912 10.6771C48.1023 10.6871 47.9065 10.7048 47.7073 10.7272C47.5081 10.7514 47.3192 10.7825 47.1389 10.8204C46.7576 10.8964 46.4949 11.0259 46.3472 11.2107C46.2012 11.3972 46.1274 11.5975 46.1274 11.8168C46.1274 12.043 46.2098 12.226 46.3764 12.3642C46.543 12.5006 46.7765 12.5696 47.0805 12.5696C47.3089 12.5696 47.5304 12.523 47.7434 12.428C47.9563 12.3331 48.1401 12.2139 48.2912 12.0723V10.6771ZM48.432 13.8871L48.3633 13.3742C48.1727 13.5849 47.9323 13.7507 47.6386 13.8698C47.3449 13.989 47.0238 14.0494 46.6735 14.0494C46.3798 14.0494 46.0965 13.9993 45.8286 13.8992C45.5572 13.8008 45.3185 13.6574 45.1107 13.4727C44.9029 13.2879 44.7346 13.0617 44.6076 12.7906C44.4788 12.5195 44.4152 12.2139 44.4152 11.872C44.4152 11.5319 44.4805 11.2297 44.6144 10.9689C44.7467 10.7082 44.9253 10.4889 45.1537 10.3076C45.3803 10.128 45.6414 9.98125 45.935 9.86729C46.227 9.75339 46.5361 9.66697 46.8572 9.61C47.0839 9.56338 47.3329 9.52879 47.6025 9.50461C47.8722 9.48042 48.0971 9.45976 48.2774 9.44071V9.3406C48.2774 8.96072 48.1813 8.69133 47.9855 8.52903C47.7914 8.36844 47.5236 8.28729 47.1835 8.28729C46.9843 8.28729 46.7937 8.31147 46.6134 8.35984C46.4331 8.40645 46.2733 8.46522 46.1308 8.53597C45.9883 8.60852 45.8629 8.67928 45.753 8.75009C45.6448 8.82088 45.5572 8.88476 45.4903 8.94174L44.9098 7.70541C44.9854 7.63812 45.1004 7.55864 45.2567 7.46368C45.413 7.3687 45.6002 7.27545 45.8234 7.18566C46.0449 7.09581 46.2939 7.01989 46.5722 6.95773C46.8504 6.89558 47.1509 6.8645 47.4703 6.8645C47.8481 6.8645 48.1933 6.91464 48.5041 7.01474C48.815 7.11486 49.0846 7.2703 49.3113 7.4844C49.5362 7.69679 49.7131 7.96617 49.8419 8.29424C49.969 8.62059 50.0325 9.01599 50.0325 9.48048V13.8871H48.432Z"
      fill="white"
    />
    <path
      d="M55.4523 5.9682C55.3957 5.94063 55.2858 5.89916 55.1209 5.84729C54.9543 5.79554 54.7362 5.7696 54.4615 5.7696C54.2176 5.7696 54.0115 5.84391 53.8467 5.99063C53.6818 6.13739 53.5994 6.37741 53.5994 6.70893V7.03529H55.0711V8.4581H53.6131V13.8921H51.858V8.4581H50.953V7.03529H51.858V6.66576C51.858 6.22027 51.9147 5.84386 52.028 5.53477C52.1414 5.22742 52.2994 4.97531 52.502 4.78192C52.7047 4.58679 52.9434 4.44348 53.2164 4.35369C53.4912 4.26385 53.7883 4.21899 54.1094 4.21899C54.5336 4.21899 54.8839 4.25871 55.1621 4.33991C55.4403 4.4211 55.6035 4.4746 55.6498 4.50395L55.4523 5.9682Z"
      fill="white"
    />
    <path
      d="M60.2351 10.4283C60.2351 10.1434 60.2007 9.87232 60.1354 9.61504C60.0702 9.35776 59.9706 9.13674 59.8383 8.95198C59.7061 8.76549 59.5464 8.61871 59.3575 8.50994C59.1686 8.39942 58.9471 8.34417 58.6929 8.34417C58.4387 8.34417 58.2155 8.39943 58.0283 8.50994C57.8394 8.6187 57.678 8.76549 57.5457 8.95198C57.4152 9.13674 57.3156 9.35776 57.2486 9.61504C57.1834 9.87232 57.1508 10.1434 57.1508 10.4283C57.1508 10.7236 57.1834 10.9981 57.2486 11.2502C57.3156 11.5023 57.4118 11.7234 57.5389 11.9133C57.666 12.1032 57.8274 12.2535 58.0197 12.364C58.2138 12.4727 58.4387 12.528 58.6929 12.528C58.9471 12.528 59.1686 12.4727 59.3575 12.364C59.5464 12.2535 59.7061 12.1032 59.8383 11.9133C59.9706 11.7234 60.0702 11.5023 60.1354 11.2502C60.2007 10.9981 60.2351 10.7236 60.2351 10.4283ZM61.9609 10.4358C61.9609 10.8831 61.8923 11.3216 61.7549 11.7533C61.6192 12.185 61.4166 12.5701 61.1469 12.9119C60.879 13.2538 60.539 13.5284 60.1286 13.7373C59.7181 13.9463 59.239 14.0499 58.6929 14.0499C58.1451 14.0499 57.666 13.9462 57.2572 13.7373C56.8468 13.5284 56.5068 13.2539 56.2372 12.912C55.9693 12.5701 55.7666 12.185 55.6292 11.7533C55.4918 11.3217 55.4249 10.8831 55.4249 10.4358C55.4249 9.99036 55.4918 9.55521 55.6292 9.12699C55.7666 8.70048 55.9693 8.31889 56.2372 7.98217C56.5068 7.64546 56.8468 7.37608 57.2572 7.17234C57.666 6.96858 58.1451 6.86499 58.6929 6.86499C59.239 6.86499 59.7164 6.96861 60.1217 7.17234C60.527 7.37608 60.867 7.64546 61.1401 7.98217C61.4148 8.31889 61.6192 8.70048 61.7549 9.12699C61.8923 9.55521 61.9609 9.99036 61.9609 10.4358Z"
      fill="white"
    />
    <path
      d="M67.4065 13.892V9.98097C67.4065 9.4595 67.2984 9.05545 67.082 8.77054C66.8639 8.48735 66.5634 8.34403 66.1752 8.34403C65.9022 8.34403 65.6497 8.41483 65.4196 8.55814C65.1878 8.69973 64.9869 8.85686 64.8169 9.02781V13.892H63.0635V7.03518H64.6623L64.7464 7.57565C65.0212 7.34946 65.3286 7.17332 65.6738 7.05072C66.0172 6.92642 66.3641 6.86597 66.7128 6.86597C67.0717 6.86597 67.4014 6.91947 67.7036 7.02827C68.0059 7.13879 68.2652 7.31145 68.4816 7.548C68.6997 7.78456 68.8663 8.09366 68.9847 8.47181C69.1015 8.85169 69.1616 9.30754 69.1616 9.83765V13.892H67.4065Z"
      fill="white"
    />
    <path
      d="M74.6192 9.63938C74.6003 9.40281 74.5505 9.1956 74.4698 9.0212C74.3891 8.84508 74.2895 8.70349 74.171 8.5947C74.0525 8.48418 73.9254 8.40476 73.7881 8.35123C73.649 8.29948 73.5098 8.27355 73.3673 8.27355C73.0633 8.27355 72.7748 8.38746 72.5001 8.61545C72.2253 8.84337 72.0639 9.18352 72.0158 9.63938H74.6192ZM71.9746 10.9051C72.0123 11.4835 72.191 11.9014 72.5138 12.1569C72.8349 12.4125 73.2523 12.542 73.7623 12.542C73.9993 12.542 74.2225 12.5196 74.4303 12.4781C74.6381 12.435 74.8236 12.3814 74.985 12.3141C75.1464 12.2484 75.2838 12.1811 75.3972 12.1155C75.5105 12.0482 75.5947 11.986 75.653 11.929L76.1201 13.3673C76.0532 13.414 75.9467 13.4779 75.8007 13.559C75.6548 13.6402 75.4727 13.7144 75.2563 13.7869C75.04 13.8577 74.7841 13.9182 74.4922 13.9717C74.2002 14.0234 73.8791 14.0494 73.5305 14.0494C73.0857 14.0494 72.6649 13.9786 72.2631 13.837C71.863 13.6937 71.5109 13.4727 71.2104 13.1739C70.9081 12.8752 70.6677 12.504 70.4891 12.0585C70.3088 11.613 70.2195 11.0812 70.2195 10.4647C70.2195 9.88626 70.3019 9.37344 70.4668 8.92794C70.6316 8.48245 70.8583 8.10773 71.1451 7.80383C71.4336 7.50165 71.7685 7.26855 72.1497 7.10623C72.5327 6.94566 72.9397 6.8645 73.3742 6.8645C73.8087 6.8645 74.2088 6.93705 74.578 7.0786C74.9455 7.2202 75.2632 7.44123 75.5328 7.73996C75.8007 8.03868 76.0137 8.42544 76.1682 8.90029C76.3245 9.37342 76.4018 9.93808 76.4018 10.5925V10.905L71.9746 10.9051Z"
      fill="white"
    />
  </svg>
);
