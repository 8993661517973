import {
  OrderProcessCalendar,
  OrderProcessCar,
  OrderProcessConfiguration,
  OrderProcessCurrency,
  OrderProcessPhone,
} from 'src/theme/icons';

export const BASE_NAME = 'order-process';

export const ORDER_PROCESS_IMAGES = [
  OrderProcessPhone,
  OrderProcessConfiguration,
  OrderProcessCurrency,
  OrderProcessCar,
  OrderProcessCalendar,
];
