import { FC } from 'react';
import HomePage from './content/home-page/homePage';
import AboutUsPage from './content/about-us-page/aboutUsPage';
import CommissionSalesPage from './content/commission-sales-page/commissionSalesPage';
import InvestitionPage from './content/investition-page/investitionPage';
import ContactUsPage from './content/contact-us-page/contactUsPage';
import { AboutUsDocument } from './content/about-us-page/aboutUs';
import { CommissionSalesDocument } from './content/commission-sales-page/commissionSales';
import { HomePageDocument } from './content/home-page/home';
import { InvestitionDocument } from './content/investition-page/investition';
import { ContactUsDocument } from './content/contact-us-page/contactUs';
import { OrderProcessDocument } from './content/order-process-page/orderProcess';
import OrderProcessPage from './content/order-process-page/orderProcessPage';
import { RequestCarDocument } from './content/request-car/requestCar';
import RequestCarPage from './content/request-car/requestCarPage';
import { ReferenceDocument } from './content/reference-page/reference';
import ReferencePage from './content/reference-page/referencePage';
import CarsPage from './content/cars-page/carsPage';
import { CarsDocument } from './content/cars-page/cars';
import CarDetailPage from './content/car-detail-page/carDetailPage';
import { CarDetailsDocument } from './content/car-detail-page/carDetail';
import { ImpressumDocument } from './content/impressum-page/impressum';
import ImpressumPage from './content/impressum-page/impressumPage';
import { DataProtectionDocument } from './content/data-protection-page/dataProtection';
import DataProtectionPage from './content/data-protection-page/dataProtectionPage';
import VehicleStoragePage from './content/vehicle-storage-page/VehicleStoragePage';
import { VehicleStorageDocument } from './content/vehicle-storage-page/vehicleStorage';
import {
  GetInternalLink,
  InternalLinkMapper,
  registerInternalLinkMapper,
} from 'jexity/packages/feature/common/navigation/getInternalLink';
import { LinkProps } from 'next/link';
import { PartnersDocument } from './content/partners-page/partners';
import PartnersPage from './content/partners-page/PartnersPage';
import { ImpressionsDocument } from './content/impressions-page/impressions';
import ImpressionsPage from './content/impressions-page/impressionsPage';

export interface BaseContentPageProps<T> {
  content: T;
}

export type SerializerPageContent<P> = FC<BaseContentPageProps<P>>;

export interface ContentPageSerializer {
  homePage: SerializerPageContent<HomePageDocument>;
  commissionSalesPage: SerializerPageContent<CommissionSalesDocument>;
  aboutUsPage: SerializerPageContent<AboutUsDocument>;
  investitionPage: SerializerPageContent<InvestitionDocument>;
  contactUsPage: SerializerPageContent<ContactUsDocument>;
  orderProcessPage: SerializerPageContent<OrderProcessDocument>;
  requestCarPage: SerializerPageContent<RequestCarDocument>;
  referencePage: SerializerPageContent<ReferenceDocument>;
  carsPage: SerializerPageContent<CarsDocument>;
  car: SerializerPageContent<CarDetailsDocument>;
  imprintPage: SerializerPageContent<ImpressumDocument>;
  dataProtectionPage: SerializerPageContent<DataProtectionDocument>;
  vehicleStoragePage: SerializerPageContent<VehicleStorageDocument>;
  partnersPage: SerializerPageContent<PartnersDocument>;
  impressionsPage: SerializerPageContent<ImpressionsDocument>;
}

export const defaultContentPageSerializer: ContentPageSerializer = {
  homePage: (props) => <HomePage {...props} />,
  commissionSalesPage: (props) => <CommissionSalesPage {...props} />,
  aboutUsPage: (props) => <AboutUsPage {...props} />,
  investitionPage: (props) => <InvestitionPage {...props} />,
  contactUsPage: (props) => <ContactUsPage {...props} />,
  orderProcessPage: (props) => <OrderProcessPage {...props} />,
  requestCarPage: (props) => <RequestCarPage {...props} />,
  referencePage: (props) => <ReferencePage {...props} />,
  carsPage: (props) => <CarsPage {...props} />,
  car: (props) => <CarDetailPage {...props} />,
  imprintPage: (props) => <ImpressumPage {...props} />,
  dataProtectionPage: (props) => <DataProtectionPage {...props} />,
  vehicleStoragePage: (props) => <VehicleStoragePage {...props} />,
  partnersPage: (props) => <PartnersPage {...props} />,
  impressionsPage: (props) => <ImpressionsPage {...props} />,
};

/** ----------------------
 * Register Internal link mapper for the new page document types
 * Since jexity doesn't know anything about custom page types used in this project
 * we have to register those here to be able to use the <code>getInternalLink</code> function
 */
class ContentPageMapper implements InternalLinkMapper {
  public map({ startPageId, pageId, slug, _type }: GetInternalLink): LinkProps | undefined {
    const isHome = pageId === startPageId;
    // console.table({ startPageId, pageId, slug, isHome }, ['startPageId', 'pageId', 'slug', 'isHome']);
    return {
      href: isHome ? '/' : `/${slug?.current}`,
    };
  }
}

export function initInternalLinkMapper(): void {
  const contentPageMapper = new ContentPageMapper();

  for (const documentType in defaultContentPageSerializer) {
    if (defaultContentPageSerializer.hasOwnProperty(documentType)) {
      // console.log('registerInternalLinkMapper for documentType', documentType);
      registerInternalLinkMapper(documentType, contentPageMapper);
    }
  }
}
