import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, Heading, HeadingProps } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Floor from 'src/components/Floor';
import { CarCard } from '../../components/car-card/CarCard';
import { CarData } from '../../feature/page/content/cars-page/cars';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { BaseSectionProps } from '../common/sectionsApi';
import CarMakesList, { Makes } from './CarMakesList';

const ALL_VALUE = 'all';
const OTHER_VALUE = 'Other';
const BASE_COUNT = 9;

const CarsSection: FC<BaseSectionProps<CarData[]>> = ({ data }) => {
  const theme = useBaseTheme();
  const { buttonStyle } = theme;
  const [showFilter, setShowFilter] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const { translations } = useTranslationsContext();
  const {
    sectionTitlePluralCarsPart,
    sectionTitleSingularCarsPart,
    sectionTitleFirstPart,
    sectionTitleLastPart,
    loadMore,
    all: allTranslatedLabel,
    other: otherTranslatedLabel,
  } = translations?.carsPage ?? {};

  const [currentFilter, setCurrentFilter] = useState<Makes['value']>(ALL_VALUE);
  const { sections, headings } = theme;
  const { sectionWrapper } = sections?.carsSection ?? {};

  const filtered = useMemo(() => {
    if (currentFilter === ALL_VALUE) {
      return data;
    }

    return data.filter((car) => car.make === currentFilter);
  }, [currentFilter, data]);

  const defaultCarMakes = data.map(({ make }) => make);
  const uniqueCarMakes = useMemo(() => {
    return [...new Set([...defaultCarMakes])];
  }, [defaultCarMakes]);
  const carMakes = uniqueCarMakes
    .filter((m) => m !== OTHER_VALUE)
    .map((make) => ({
      value: make,
      label: make,
    }));
  const makes = [
    {
      label: allTranslatedLabel ?? 'All Cars',
      value: ALL_VALUE,
    },
    ...carMakes,
  ];

  if (uniqueCarMakes.includes(OTHER_VALUE)) {
    makes.push({
      value: OTHER_VALUE,
      label: otherTranslatedLabel ?? 'Other',
    });
  }

  const carsData = useMemo(() => (showMore ? filtered : filtered.slice(0, BASE_COUNT)), [filtered, showMore]);

  useEffect(() => {
    const localFilter = sessionStorage['carFilter'];

    if (typeof localFilter === 'string' && uniqueCarMakes.includes(localFilter)) {
      setCurrentFilter(localFilter);
    } else {
      sessionStorage.removeItem('carFilter');
    }
  }, [translations?.carsPage, uniqueCarMakes]);

  const headingStyle: HeadingProps = {
    ...headings?.h1,
    as: 'h1',
    fontWeight: 400,
    letterSpacing: 2,
  };

  const filterStyles: HeadingProps = {
    ...headings?.h6,
    letterSpacing: 2,
    lineHeight: '1.25rem',
    fontSize: 'md',
    fontWeight: '400',
    minWidth: '15.625rem',
  };

  const isSingular = carsData.length <= 1;

  const getFilterLabel = (currentFilter: string) => {
    if (currentFilter === ALL_VALUE) {
      return translations?.carsPage.all;
    } else if (currentFilter === OTHER_VALUE) {
      return translations?.carsPage.other;
    } else {
      return carMakes.find((c) => c.value === currentFilter)?.label;
    }
  };

  return (
    <SectionContainer mb={['1.5rem', null, null, 0]} {...sectionWrapper} pt="0">
      <Container>
        <Box
          d={{
            base: 'static',
            lg: 'flex',
          }}
          mb={['1rem', null, null, null, '4.5rem']}
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Heading {...headingStyle}>
            {`${sectionTitleFirstPart} `}
            <Box {...headingStyle} as="span" color="brand.primary.500">{`${filtered.length} ${
              isSingular ? sectionTitleSingularCarsPart : sectionTitlePluralCarsPart
            } `}</Box>
            {`${sectionTitleLastPart}`}
          </Heading>

          <Box
            {...filterStyles}
            d="inline-flex"
            justifyContent={{
              base: 'flex-start',
              lg: 'center',
            }}
            alignContent="center"
            alignItems="center"
            mt={{
              base: '2.125rem',
              sm: 0,
            }}
            mb={{
              base: '1.125rem',
              sm: 0,
            }}
          >
            <Box textTransform="uppercase" pos="relative">
              Filter:
              <Box pos="absolute">
                <Box zIndex={3} pos="relative">
                  {showFilter && (
                    <CarMakesList
                      currentFilter={currentFilter}
                      makes={makes}
                      onChange={(newMake) => {
                        setShowMore(false);
                        setCurrentFilter(newMake);
                        sessionStorage.setItem('carFilter', newMake);
                        window.scrollTo(0, 0);
                        setShowFilter(false);
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              color="brand.primary.500"
              onClick={() => setShowFilter((e: boolean) => !e)}
              cursor="pointer"
              textTransform="uppercase"
              px={1}
            >
              {getFilterLabel(currentFilter)}
            </Box>
            <Box onClick={() => setShowFilter((e: boolean) => !e)} cursor="pointer">
              <ChevronDownIcon boxSize="1.5rem" />
            </Box>
          </Box>
        </Box>
      </Container>
      <Box pos="relative">
        <Container overflow="hidden">
          {carsData.length ? (
            <Grid
              pos="relative"
              zIndex={2}
              gridTemplateColumns={['1fr', '1fr 1fr', null, '1fr 1fr 1fr']}
              gridGap="2rem"
            >
              {carsData.map((car, idx) => (
                <CarCard key={`car-card-${idx}`} {...car} />
              ))}
            </Grid>
          ) : (
            <Box
              h={{
                base: '5rem',
                md: '25rem',
              }}
              pos="relative"
            />
          )}

          <Flex justifyContent="center">
            {!showMore && (
              <Button {...buttonStyle} onClick={() => setShowMore(true)}>
                {loadMore}
              </Button>
            )}
          </Flex>
        </Container>
        {carsData.length ? (
          <Floor
            zIndex={-1}
            pos="absolute"
            w={['400vw', null, null, '150vw']}
            maxW="3200px"
            h="auto"
            bottom="0"
            transform="translateY(30%)"
          />
        ) : (
          <></>
        )}
      </Box>
    </SectionContainer>
  );
};

export default CarsSection;
