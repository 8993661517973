import React, { ReactNode } from 'react';
import {
  BlockContentSerializer,
  defaultBlockContentSerializers,
  MarkProps,
} from 'jexity/packages/components/block-content/serializers';
import { InternalLink } from 'jexity/packages/components/link/InternalLink';

export const blockContentSerializer: BlockContentSerializer = {
  ...defaultBlockContentSerializers,
  marks: {
    ...defaultBlockContentSerializers.marks,
    internalLink: ({ children, mark }: MarkProps): ReactNode => {
      if (!mark?.reference?.slug) return null;
      return (
        <InternalLink pageId={mark?.reference?._id} _type={mark?.reference?._type} slug={mark?.reference?.slug}>
          {children}
        </InternalLink>
      );
    },
  },
  types: {
    ...defaultBlockContentSerializers.types,
  },
};
