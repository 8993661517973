export const rightArrow = (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8H12.86L9.23 12.36C8.87654 12.7853 8.93474 13.4165 9.36 13.77C9.78526 14.1235 10.4165 14.0653 10.77 13.64L15.77 7.64C15.8036 7.59228 15.8337 7.54214 15.86 7.49C15.86 7.44 15.91 7.41 15.93 7.36C15.9753 7.24534 15.9991 7.12329 16 7V7C15.9991 6.87671 15.9753 6.75466 15.93 6.64C15.93 6.59 15.88 6.56 15.86 6.51C15.8337 6.45786 15.8036 6.40772 15.77 6.36L10.77 0.36V0.36C10.5796 0.131461 10.2974 -0.00048209 10 1.91035e-06V1.91032e-06C9.76635 -0.000454599 9.53991 0.0809208 9.36 0.230002L9.36 0.230002C8.93483 0.582492 8.87591 1.21291 9.2284 1.63808C9.22893 1.63872 9.22947 1.63936 9.23 1.64L12.86 6H1C0.447715 6 0 6.44772 0 7C2.41411e-08 7.55229 0.447715 8 1 8L1 8Z"
      fill="currentColor"
    />
  </svg>
);
