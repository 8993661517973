export const CUBIC_CAPACITY_OPTIONS_EN: string[] = [
  'Any',
  '1,000 cm³',
  '1,200 cm³',
  '1,400 cm³',
  '1,600 cm³',
  '1,800 cm³',
  '2,000 cm³',
  '2,600 cm³',
  '3,000 cm³',
  '5,000 cm³',
  '7,500 cm³',
  '8,000 cm³',
  '9,000 cm³',
];

export const CUBIC_CAPACITY_OPTIONS_DE: string[] = [
  'Beliebig',
  '1,000 cm³',
  '1,200 cm³',
  '1,400 cm³',
  '1,600 cm³',
  '1,800 cm³',
  '2,000 cm³',
  '2,600 cm³',
  '3,000 cm³',
  '5,000 cm³',
  '7,500 cm³',
  '8,000 cm³',
  '9,000 cm³',
];
