import React, { FC } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { isSanityImageAsset, PictureAsset } from 'sanity-picture-asset';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { Container } from 'jexity/packages/components/container/Container';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { BreakOutContainer } from 'jexity/packages/components/container/BreakOutContainer';
import { BaseSectionProps } from '../common/sectionsApi';
import { FormWithImageSection as FormWithImageSectionProps } from '../../feature/page/content/contact-us-page/contactUs';
import { ContactUsForm } from '../../components/contact-us-form/ContactUsForm';

export interface FormWithImageSectionDataSectionProps extends BaseSectionProps<FormWithImageSectionProps> {
  serializer?: Partial<BlockContentSerializer>;
}

const FormWithImageSection: FC<FormWithImageSectionDataSectionProps> = ({ data }) => {
  const theme = useBaseTheme();
  const { sections, gridGap } = theme;
  const { sectionWrapper } = sections?.blockTextImageSection ?? {};
  const { image, title } = data;

  return (
    <SectionContainer {...sectionWrapper}>
      <Grid as="section" pos="relative" templateColumns="auto" templateRows="auto">
        <Container variant={'wide'}>
          <Grid
            pos="relative"
            zIndex={2}
            w="100%"
            h="100%"
            columnGap={gridGap}
            rowGap={'3.25rem'}
            gridTemplateColumns={['1fr', null, null, '1fr 40%', null, null, '1fr 43%']}
          >
            <Box order={[2]} h="100%" d="flex" flexDir="column">
              <Box textAlign="left" maxW={['100%', '100%', '100%', '100%', '440px', '420px', '635px', '615px']}>
                {title && (
                  <Box mb="30px">
                    <BlockContent blocks={title as Content[]} />
                  </Box>
                )}
                <ContactUsForm />
              </Box>
            </Box>

            <Box w={['100%']} h={['100%']} pos="relative" order={[1]}>
              <BreakOutContainer>
                {isSanityImageAsset(image) && (
                  <Box pos="relative">
                    <PictureAsset objectFit="contain" image={image} alt={image.alt ?? 'Image'} isFluid={true} />
                  </Box>
                )}
              </BreakOutContainer>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </SectionContainer>
  );
};

export default FormWithImageSection;
