import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import FormWithImageSection from '../../../../sections/form-with-image-section/FormWithImageSection';
import ShowroomSection from '../../../../sections/showroom-section/ShowroomSection';
import { BaseContentPageProps } from '../contentApi';
import { ContactUsDocument } from './contactUs';

const ContactUsPage: FC<BaseContentPageProps<ContactUsDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();

  const { title, formWithImageSection, showRoomSection } = content ?? {};
  return (
    <Box pt={headerHeight} pb={[12, null, 16, 20]}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/contact-us.png"
        mobileImage="/assets/png/pages/contact-us-mobile.png"
      />
      {title && <BlockTextSection data={{ title }} />}
      {showRoomSection && <ShowroomSection _type="contactUsPage" data={showRoomSection} />}
      {formWithImageSection && <FormWithImageSection data={formWithImageSection} />}
    </Box>
  );
};

export default ContactUsPage;
