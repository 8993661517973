export const navbarLogoDesktop = (
  <svg width="194" height="32" viewBox="0 0 194 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M1.64348 29.8838L0.854553 27.7979H0.839667C0.860755 28.1077 0.871299 28.3981 0.871299 28.6692V29.8838H0.467529V27.3862H1.09458L1.85002 29.373H1.86119L2.63895 27.3862H3.26787V29.8838H2.83991V28.6487C2.83991 28.5246 2.84301 28.3628 2.84921 28.1635C2.85665 27.9642 2.86286 27.8435 2.86782 27.8013H2.85293L2.03609 29.8838H1.64348Z"
        fill="#C7C7C7"
      />
      <path
        d="M13.7696 29.8838L13.4979 29.1902H12.4578L12.1917 29.8838H11.7228L12.7406 27.376H13.2244L14.2422 29.8838H13.7696ZM13.3807 28.8383L13.1258 28.1584C13.1072 28.1128 13.0811 28.0411 13.0476 27.9431C13.0154 27.8452 12.9931 27.7734 12.9807 27.7279C12.9472 27.868 12.8982 28.0212 12.8337 28.1874L12.5881 28.8383H13.3807Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.703 29.8838H24.1913L23.5103 28.8622L22.8237 29.8838H22.3474L23.2498 28.5889L22.405 27.3862H22.9L23.5289 28.3309L24.1578 27.3862H24.6379L23.7875 28.5957L24.703 29.8838Z"
        fill="#C7C7C7"
      />
      <path d="M33.1673 29.8838V27.3862H33.612V29.8838H33.1673Z" fill="#C7C7C7" />
      <path
        d="M43.6057 29.8838L42.8168 27.7979H42.8019C42.823 28.1077 42.8335 28.3981 42.8335 28.6692V29.8838H42.4298V27.3862H43.0568L43.8123 29.373H43.8234L44.6012 27.3862H45.2301V29.8838H44.8022V28.6487C44.8022 28.5246 44.8053 28.3628 44.8115 28.1635C44.8189 27.9642 44.8251 27.8435 44.8301 27.8013H44.8152L43.9983 29.8838H43.6057Z"
        fill="#C7C7C7"
      />
      <path
        d="M56.1765 27.3862V29.0023C56.1765 29.1868 56.1331 29.3485 56.0463 29.4875C55.9607 29.6253 55.836 29.7318 55.6723 29.807C55.5098 29.881 55.315 29.918 55.088 29.918C54.7506 29.918 54.4883 29.836 54.301 29.672C54.1137 29.508 54.02 29.2825 54.02 28.9955V27.3862H54.4666V28.9664C54.4666 29.1726 54.5187 29.3246 54.6229 29.4226C54.7271 29.5205 54.8871 29.5695 55.1029 29.5695C55.5222 29.5695 55.7318 29.3673 55.7318 28.963V27.3862H56.1765Z"
        fill="#C7C7C7"
      />
      <path
        d="M66.1461 29.8838L65.3572 27.7979H65.3423C65.3634 28.1077 65.3739 28.3981 65.3739 28.6692V29.8838H64.9702V27.3862H65.5972L66.3527 29.373H66.3638L67.1416 27.3862H67.7705V29.8838H67.3425V28.6487C67.3425 28.5246 67.3456 28.3628 67.3518 28.1635C67.3593 27.9642 67.3655 27.8435 67.3704 27.8013H67.3556L66.5387 29.8838H66.1461Z"
        fill="#C7C7C7"
      />
      <path
        d="M87.4733 28.1413C87.4733 28.4021 87.3803 28.602 87.1942 28.7409C87.0082 28.8799 86.7433 28.9494 86.3997 28.9494H86.1169V29.8838H85.6722V27.3862H86.4555C86.7954 27.3862 87.0497 27.45 87.2184 27.5776C87.3884 27.7051 87.4733 27.893 87.4733 28.1413ZM86.1169 28.606H86.3532C86.5814 28.606 86.7489 28.5695 86.8556 28.4966C86.9623 28.4238 87.0156 28.3099 87.0156 28.155C87.0156 28.0115 86.9678 27.9044 86.8723 27.8338C86.7768 27.7632 86.628 27.7279 86.4258 27.7279H86.1169V28.606Z"
        fill="#C7C7C7"
      />
      <path
        d="M97.6532 29.8838H96.1144V27.3862H97.6532V27.7313H96.5591V28.4112H97.5843V28.7529H96.5591V29.537H97.6532V29.8838Z"
        fill="#C7C7C7"
      />
      <path
        d="M106.785 28.5479H107.094C107.301 28.5479 107.452 28.5126 107.545 28.442C107.638 28.3714 107.684 28.2666 107.684 28.1276C107.684 27.9864 107.634 27.8851 107.533 27.8236C107.433 27.7621 107.282 27.7313 107.079 27.7313H106.785V28.5479ZM106.785 28.8862V29.8838H106.341V27.3862H107.109C107.46 27.3862 107.72 27.4466 107.889 27.5673C108.058 27.688 108.142 27.8703 108.142 28.114C108.142 28.4249 107.966 28.6464 107.613 28.7785L108.382 29.8838H107.876L107.225 28.8862H106.785Z"
        fill="#C7C7C7"
      />
      <path
        d="M117.317 29.8838H116.876V27.3862H118.411V27.7313H117.317V28.512H118.342V28.8588H117.317V29.8838Z"
        fill="#C7C7C7"
      />
      <path
        d="M129.417 28.6316C129.417 29.0382 129.306 29.3542 129.084 29.5797C128.863 29.8053 128.55 29.918 128.144 29.918C127.734 29.918 127.418 29.8064 127.197 29.5832C126.978 29.3588 126.868 29.0405 126.868 28.6282C126.868 28.2159 126.978 27.8993 127.199 27.6783C127.421 27.4574 127.737 27.3469 128.148 27.3469C128.552 27.3469 128.865 27.4591 129.086 27.6835C129.307 27.9078 129.417 28.2239 129.417 28.6316ZM127.34 28.6316C127.34 28.9391 127.408 29.1726 127.543 29.332C127.678 29.4903 127.879 29.5695 128.144 29.5695C128.408 29.5695 128.608 29.4909 128.742 29.3337C128.877 29.1766 128.944 28.9425 128.944 28.6316C128.944 28.3252 128.877 28.0929 128.743 27.9346C128.611 27.7763 128.412 27.6971 128.148 27.6971C127.881 27.6971 127.68 27.7763 127.543 27.9346C127.408 28.0929 127.34 28.3252 127.34 28.6316Z"
        fill="#C7C7C7"
      />
      <path
        d="M138.544 28.5479H138.853C139.06 28.5479 139.21 28.5126 139.303 28.442C139.396 28.3714 139.442 28.2666 139.442 28.1276C139.442 27.9864 139.392 27.8851 139.292 27.8236C139.191 27.7621 139.04 27.7313 138.838 27.7313H138.544V28.5479ZM138.544 28.8862V29.8838H138.099V27.3862H138.867C139.218 27.3862 139.478 27.4466 139.647 27.5673C139.816 27.688 139.9 27.8703 139.9 28.114C139.9 28.4249 139.724 28.6464 139.372 28.7785L140.14 29.8838H139.634L138.983 28.8862H138.544Z"
        fill="#C7C7C7"
      />
      <path
        d="M149.81 29.8838L149.021 27.7979H149.006C149.027 28.1077 149.038 28.3981 149.038 28.6692V29.8838H148.634V27.3862H149.261L150.017 29.373H150.028L150.806 27.3862H151.434V29.8838H151.007V28.6487C151.007 28.5246 151.01 28.3628 151.016 28.1635C151.023 27.9642 151.029 27.8435 151.034 27.8013H151.02L150.203 29.8838H149.81Z"
        fill="#C7C7C7"
      />
      <path
        d="M161.936 29.8838L161.665 29.1902H160.624L160.358 29.8838H159.889L160.907 27.376H161.391L162.409 29.8838H161.936ZM161.547 28.8383L161.292 28.1584C161.274 28.1128 161.248 28.0411 161.214 27.9431C161.182 27.8452 161.16 27.7734 161.147 27.7279C161.114 27.868 161.065 28.0212 161 28.1874L160.755 28.8383H161.547Z"
        fill="#C7C7C7"
      />
      <path
        d="M173.132 29.8838H172.587L171.249 27.8936H171.234L171.243 28.0046C171.261 28.2165 171.269 28.4101 171.269 28.5855V29.8838H170.866V27.3862H171.405L172.739 29.3662H172.751C172.748 29.34 172.743 29.2449 172.736 29.0809C172.728 28.9158 172.724 28.7871 172.724 28.6948V27.3862H173.132V29.8838Z"
        fill="#C7C7C7"
      />
      <path
        d="M183.105 27.7006C182.85 27.7006 182.649 27.7837 182.502 27.95C182.356 28.1163 182.283 28.3457 182.283 28.6384C182.283 28.9448 182.353 29.1766 182.493 29.3337C182.635 29.4909 182.839 29.5695 183.105 29.5695C183.221 29.5695 183.332 29.5592 183.44 29.5387C183.548 29.5171 183.66 29.4898 183.777 29.4567V29.807C183.564 29.881 183.322 29.918 183.051 29.918C182.653 29.918 182.347 29.8075 182.134 29.5866C181.921 29.3645 181.814 29.0473 181.814 28.635C181.814 28.3754 181.865 28.1481 181.968 27.9534C182.073 27.7586 182.223 27.6094 182.419 27.5058C182.615 27.4022 182.845 27.3503 183.109 27.3503C183.387 27.3503 183.644 27.4039 183.879 27.5109L183.719 27.8509C183.628 27.811 183.53 27.7763 183.427 27.7467C183.325 27.7159 183.218 27.7006 183.105 27.7006Z"
        fill="#C7C7C7"
      />
      <path
        d="M194 29.8838H192.461V27.3862H194V27.7313H192.906V28.4112H193.931V28.7529H192.906V29.537H194V29.8838Z"
        fill="#C7C7C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V3.03855H43.9421C44.4095 3.03855 45.3445 3.31903 45.3445 4.44095V21.5421C45.3445 23.199 46.6876 24.5421 48.3445 24.5421H54.0312C55.688 24.5421 57.0312 23.199 57.0312 21.5421L57.0312 4.44095C57.0312 3.31903 57.9661 3.03855 58.4336 3.03855H92.3251C92.7925 3.03855 93.7275 3.31903 93.7275 4.44095L93.7275 21.5421C93.7275 23.199 95.0706 24.5421 96.7275 24.5421H102.414C104.071 24.5421 105.414 23.199 105.414 21.5421L105.414 3.03855V3C105.414 1.34315 104.071 0 102.414 0H3ZM11.6867 9.11564H0V21.5421C0 23.199 1.34315 24.5421 3 24.5421H8.68672C10.3436 24.5421 11.6867 23.199 11.6867 21.5421V9.11564ZM0 5.84336H11.6867V6.31083H0V5.84336Z"
        fill="#C7C7C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.687 1.51927C108.687 0.680202 109.367 0 110.207 0H191C192.592 0 193.893 1.23893 193.994 2.80481H194V2.9341C194 2.95601 194 2.97798 194 3V3.03855H194V9.11564V9.15419C194 10.811 192.656 12.1542 191 12.1542H121.776C121.308 12.1542 120.374 12.4343 120.373 13.5546L120.373 13.5566L120.373 21.5421C120.373 23.199 119.03 24.5421 117.373 24.5421H111.687C110.03 24.5421 108.687 23.199 108.687 21.5421V12.1542V9.11564H120.373H182.313V8.88191H180.91C182.032 8.88191 182.313 7.94697 182.313 7.4795V4.44095C182.313 3.31903 181.378 3.03855 180.91 3.03855H110.207C109.367 3.03855 108.687 2.35834 108.687 1.51927ZM108.687 5.84336H172.73V6.31083H108.687V5.84336Z"
        fill="#D80624"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="194" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
