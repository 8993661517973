import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Heading, Icon, Link, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import Button from 'jexity/packages/components/button/Button';
import { Container } from 'jexity/packages/components/container/Container';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { FC } from 'react';
import { isSanityImageAsset, PictureAsset } from 'sanity-picture-asset';
import Floor from 'src/components/Floor';
import { CarDetailsDocument } from '../../feature/page/content/car-detail-page/carDetail';
import { RightArrow } from '../../theme/icons';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { BaseSectionProps } from '../common/sectionsApi';
import { CarInformationList } from '../../components/car-card/CarInformationList';

export interface CarDetailsSectionProps extends BaseSectionProps<CarDetailsDocument> {
  serializer?: Partial<BlockContentSerializer>;
  onOpen?: () => void;
}

const CarDetailsSection: FC<CarDetailsSectionProps> = ({ data, onOpen }) => {
  const theme = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { setFormAdditionalValues } = useFormContext();
  const { priceInclVatLabel, priceExclVatLabel } = translations?.carsPage ?? {};
  const { carDetailsSection } = translations?.carDetailsPage ?? {};
  const { buttonTextLabel, linkLabel } = carDetailsSection ?? {};
  const { headings, sections } = theme;
  const { sectionWrapper } = sections?.carDetailsSection ?? {};
  const {
    coverImage,
    name,
    price,
    priceExclVat,
    availablityPurchase,
    mobileDeLink,
    firstRegistration,
    yearConstruction,
    mileage,
    power,
    gearBox,
    condition,
  } = data;

  return (
    <SectionContainer {...sectionWrapper} pos="relative">
      <Floor
        zIndex={-1}
        pos="absolute"
        w={['400vw', null, null, '150vw']}
        maxW="3200px"
        h="auto"
        bottom="0"
        transform="translateY(50%)"
      />
      <Container variant="normal">
        <Grid pos="relative" gridGap="2em" gridTemplateColumns={['1fr', null, null, '1fr 1fr']}>
          <Box
            order={[2, null, null, 1]}
            h="100%"
            d="flex"
            flexDir="column"
            alignItems={['flex-start', null, null, 'flex-end']}
          >
            <Box textAlign="left" w="100%">
              <Box>
                {!!name && (
                  <Heading
                    as="h1"
                    {...headings?.h1}
                    mt={0}
                    mb="1.125rem"
                    letterSpacing={2}
                    fontWeight={400}
                    textTransform="uppercase"
                  >
                    {name}
                  </Heading>
                )}
              </Box>
              <Box mt={4} mb={['0.75rem', null, null, null, '1.25rem']}>
                <Text
                  fontFamily="heading"
                  fontSize="2.5rem"
                  lineHeight="3.6875rem"
                  letterSpacing={0}
                  fontWeight={400}
                  color="brand.primary.500"
                >
                  {price} ({priceInclVatLabel})
                </Text>
                {priceExclVat && (
                  <Text {...theme.headings?.h5} letterSpacing={0}>
                    {priceExclVat} ({priceExclVatLabel})
                  </Text>
                )}
              </Box>
              <CarInformationList
                availablityPurchase={availablityPurchase}
                firstRegistration={firstRegistration}
                yearConstruction={yearConstruction}
                mileage={mileage}
                power={power}
                gearBox={gearBox}
                condition={condition}
              />
              <Box mt={['2rem', null, null, null, '2.5rem']} backgroundColor="#D1D1D1" h="1px" />
              <Flex flexDir={['column', 'row']} flexWrap="wrap" alignItems={['flex-start', 'center']}>
                <Button
                  onClick={() => {
                    setFormAdditionalValues({
                      name,
                      price,
                      availablityPurchase,
                      firstRegistration,
                      yearConstruction,
                      mileage,
                      power,
                      gearBox,
                      condition,
                    });
                    // Add a delay here to make sure additional form values are set
                    window.setTimeout(() => {
                      onOpen?.();
                    }, 350);
                  }}
                  mt="1.375rem"
                  mb={0}
                  mr={3}
                >
                  {buttonTextLabel} <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
                </Button>
                {mobileDeLink?.href && (
                  <Link
                    href={mobileDeLink.href}
                    fontFamily="heading"
                    fontWeight={500}
                    fontSize="1rem"
                    lineHeight={['2rem', null, null, '1.25rem']}
                    letterSpacing={2}
                    color="white"
                    textTransform="uppercase"
                    mt="1.375rem"
                    pt="0.75rem"
                    pb="0.75rem"
                    isExternal={mobileDeLink.blank}
                    transition=".3s ease-in-out"
                    _hover={{
                      textDecor: 'none',
                      color: 'brand.primary.500',
                    }}
                  >
                    {linkLabel} <Icon as={ExternalLinkIcon} color="inherit" mt="-2px" />
                  </Link>
                )}
              </Flex>
            </Box>
          </Box>
          <Box w="100%" h="100%" pos="relative" order={[1, null, null, 2]}>
            {isSanityImageAsset(coverImage) && (
              <Box pos="sticky" top={theme.headerHeight?.map((h) => `calc(${h}px + 16px)`)}>
                <PictureAsset
                  objectFit="contain"
                  image={coverImage}
                  alt={coverImage.alt ?? 'Image'}
                  isFluid={true}
                  aspectRatio={1.4}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </SectionContainer>
  );
};

export default CarDetailsSection;
