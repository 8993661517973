import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { Container } from 'jexity/packages/components/container/Container';
import { useMedia } from 'jexity/packages/components/media-query/useMedia';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import NextLink from 'next/link';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Floor from 'src/components/Floor';
import SlideshowPagination from 'src/components/slideshow/slideshow-pagination/SlideshowPagination';
import { CarCard } from '../../components/car-card/CarCard';
import { SwipeableSingle } from '../../components/swipeable/swipeable-single/SwipeableSingle';
import { CarDetailsDocument } from '../../feature/page/content/car-detail-page/carDetail';
import { CarTeaserSection as CarTeaserSectionProps } from '../../feature/page/content/home-page/home';
import { RightArrow } from '../../theme/icons';
import { BaseSectionProps } from '../common/sectionsApi';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';

const SECTIONS_PER_SLIDE = 3;
const SECTIONS_PER_SLIDE_MIN_TABLET = 2;

const chunk = (
  array: Omit<CarDetailsDocument, 'ctaSection'>[][] | any = [],
  chunkSize = 1
): [CarDetailsDocument[]] | [] => {
  const referenceArray: any = [];

  const splice = (arr = []) => {
    referenceArray.push(arr.splice(0, chunkSize));

    if (arr.length) {
      return splice(arr);
    }
    return referenceArray;
  };

  return splice(array);
};

const CarTeaserSection: FC<BaseSectionProps<CarTeaserSectionProps>> = ({ data }) => {
  const { sections, paragraph, linkStyle } = useBaseTheme();
  const { sectionWrapper } = sections?.carTeaserSection ?? {};
  const { title, cars } = data;
  const { translations: t } = useTranslationsContext();
  const [activeItem, setActiveItem] = useState(0);
  const [divided, setDivided] = useState(false);
  const isTablet = useMedia(['(min-width: 1024px)'], [true], false);
  const isMinTablet = useMedia(['(min-width: 768px)'], [true], false);

  const clonedCars = useMemo(() => [...cars], [cars]);
  const clonedCarsTablet = useMemo(() => [...cars], [cars]);
  const repeatString = (string: string, times: number) => string.repeat(times);
  const updatedCars: [CarDetailsDocument[]] | [] = useMemo(
    () => (clonedCars.length ? chunk(clonedCars, SECTIONS_PER_SLIDE) : []),
    [clonedCars]
  );
  const updatedCarsMinTablet = useMemo(
    () => (clonedCarsTablet.length ? chunk(clonedCarsTablet, SECTIONS_PER_SLIDE_MIN_TABLET) : []),
    [clonedCarsTablet]
  );

  const onClickNext = useCallback(() => {
    if (divided) {
      if (isMinTablet && !isTablet) {
        if (activeItem < updatedCarsMinTablet.length - 1) {
          setActiveItem((prev) => prev + 1);
        }
      } else if (activeItem < updatedCars.length - 1) {
        setActiveItem((prev) => prev + 1);
      }
    } else {
      if (activeItem < cars.length - 1) {
        setActiveItem((prev) => prev + 1);
      }
    }
  }, [cars, divided, updatedCars, updatedCarsMinTablet, isTablet, isMinTablet, activeItem, setActiveItem]);

  const onClickBack = useCallback(() => {
    if (activeItem > 0) {
      setActiveItem((prev) => prev - 1);
    }
  }, [activeItem, setActiveItem]);

  useEffect(() => {
    return (isMinTablet && !isTablet) || isTablet ? setDivided(true) : setDivided(false);
  }, [isTablet, isMinTablet]);

  const hoverEffects = { color: 'brand.primary.500', textDecor: 'none', transform: 'scale(1.5)' };

  return (
    <SectionContainer {...sectionWrapper} mt={8}>
      <Container>
        <Box zIndex={2} pos="relative">
          <Flex flexDir="column">
            <Box mb={['0', null, null, null, '1.75rem']}>
              <BlockContent blocks={title as Content[]} />
            </Box>
          </Flex>
          <Flex
            visibility={{
              base: 'visible',
              sm: 'initial',
            }}
            alignItems="center"
          >
            <NextLink passHref href="/cars">
              <Link {...linkStyle}>
                <Text
                  {...paragraph?.body4}
                  textTransform="uppercase"
                  color="inherit"
                  fontFamily="heading"
                  letterSpacing={2}
                  fontWeight={400}
                >
                  {t?.homePage.carsTeaserSection.title}
                  <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
                </Text>
              </Link>
            </NextLink>
            <Flex ml="auto" d={['none', null, 'flex']}>
              <IconButton
                onClick={onClickBack}
                backgroundColor="transparent"
                aria-label="previous"
                icon={<ChevronLeftIcon />}
                color="white"
                mr={2}
                _hover={hoverEffects}
              />
              <IconButton
                onClick={onClickNext}
                backgroundColor="transparent"
                aria-label="next"
                icon={<ChevronRightIcon />}
                color="white"
                _hover={hoverEffects}
              />
            </Flex>
          </Flex>
        </Box>
      </Container>
      <Container
        mt={['3.125rem']}
        pb={{
          base: '2.5rem',
          xl: '5rem',
          '4xl': '7rem',
        }}
        pos="relative"
        zIndex={2}
      >
        <SwipeableSingle shouldDrag activeIndex={activeItem} setActiveIndex={setActiveItem} autoPlay>
          {!divided && cars.map((car, idx) => <CarCard showDetails key={`car-card-${idx}`} {...car} />)}
          {divided &&
            isMinTablet &&
            !isTablet &&
            (updatedCarsMinTablet as [CarDetailsDocument[]]).map((innerCars, idx) => (
              <Grid
                key={`car-grid-${idx}`}
                gridTemplateColumns={['1fr', repeatString('1fr ', SECTIONS_PER_SLIDE_MIN_TABLET)]}
                gridGap={'2rem'}
              >
                {innerCars.map((car, idx) => (
                  <CarCard showDetails key={`car-card-${idx}`} {...car} />
                ))}
              </Grid>
            ))}
          {divided &&
            isTablet &&
            (updatedCars as [CarDetailsDocument[]]).map((innerCars, idx) => (
              <Grid
                key={`car-grid-${idx}`}
                gridTemplateColumns={['1fr', repeatString('1fr ', SECTIONS_PER_SLIDE)]}
                gridGap={'2rem'}
              >
                {innerCars.map((car, idx) => (
                  <CarCard showDetails key={`car-card-${idx}`} {...car} />
                ))}
              </Grid>
            ))}
        </SwipeableSingle>

        <SlideshowPagination
          setActiveItem={(index) => setActiveItem(index)}
          activeItem={activeItem}
          data={cars}
          d={['flex', null, 'none']}
          mt={0}
        />
        <Floor
          zIndex={-1}
          pos="absolute"
          w={['400vw', null, null, '150vw']}
          maxW="3200px"
          h="auto"
          bottom="0"
          transform="translateY(20%)"
        />
      </Container>
    </SectionContainer>
  );
};

export default CarTeaserSection;
