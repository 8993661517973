export const orderProcessCurrency = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7847 8H50.8239C52.21 8 53.3337 9.19391 53.3337 10.6667C53.3337 12.1394 52.21 13.3333 50.8239 13.3333H40.7847C33.7769 13.3333 27.7269 17.6937 24.907 24H38.2748C39.661 24 40.7846 25.1939 40.7846 26.6667C40.7846 28.1394 39.661 29.3333 38.2748 29.3333H23.394C23.2767 30.2043 23.216 31.0946 23.216 32C23.216 33.8532 23.4703 35.6434 23.9437 37.3333H38.2748C39.661 37.3333 40.7846 38.5272 40.7846 40C40.7846 41.4728 39.661 42.6667 38.2748 42.6667H26.3655C29.5404 47.5025 34.8151 50.6667 40.7847 50.6667H50.8239C52.21 50.6667 53.3337 51.8606 53.3337 53.3333C53.3337 54.8061 52.21 56 50.8239 56H40.7847C31.9163 56 24.2411 50.5689 20.5447 42.6667H13.1768C11.7907 42.6667 10.667 41.4728 10.667 40C10.667 38.5272 11.7907 37.3333 13.1768 37.3333H18.7562C18.3899 35.6181 18.1964 33.833 18.1964 32C18.1964 31.0986 18.2432 30.2089 18.3343 29.3333H13.1768C11.7907 29.3333 10.667 28.1394 10.667 26.6667C10.667 25.1939 11.7907 24 13.1768 24H19.4819C22.583 14.6788 30.9503 8 40.7847 8Z"
      fill="currentColor"
    />
  </svg>
);
