import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import React, { FC } from 'react';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import BrandsSection from '../../../../sections/brands-section/BrandsSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';
import OrderProcessSection from '../../../../sections/order-process-section/OrderProcessSection';
import { BaseContentPageProps } from '../contentApi';
import { OrderProcessDocument } from './orderProcess';

const OrderProcessPage: FC<BaseContentPageProps<OrderProcessDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();

  const { title, orderProcess, ctaSection } = content ?? {};
  return (
    <Box pt={headerHeight}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/order-process.png"
        mobileImage="/assets/png/pages/order-process-mobile.png"
      />
      <Box pos="relative">
        {title && <BlockTextSection data={{ title }} />}
        {orderProcess && <OrderProcessSection data={orderProcess} />}
        <BrandsSection pageType="orderProcessPage" isLink={true} />
        {ctaSection && <CtaSection data={ctaSection} />}
      </Box>
    </Box>
  );
};

export default OrderProcessPage;
