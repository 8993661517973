export const evelanLogo = (
  <svg width="70" height="10" viewBox="0 0 70 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.8044 0L17.8534 7.81724L13.8675 0H11.5258L16.6576 9.90981H19.089L24.1859 0H21.8044ZM25.292 0H35.1918V2.10254H25.297L25.292 0ZM25.292 3.91111H35.1918V6.00368H25.297L25.292 3.91111ZM25.292 7.80728H35.1918V9.90981H25.297L25.292 7.80728ZM38.9285 8.08629V0H36.8161V9.90981H44.6034L44.8575 9.41158L45.55 8.07134L38.9285 8.08629ZM58.4791 9.90981H56.0926L52.1068 2.09257L48.1558 9.90981H45.7742L46.691 8.13611L50.8911 0H53.3224L58.4791 9.90981ZM59.5852 9.76533V9.90981H59.6649L59.5852 9.76533ZM0.5 0H10.3949V2.09257H0.5V0ZM0.5 3.91111H10.3949V5.9987H0.5V3.91111ZM0.5 7.82223H10.3949V9.90981H0.5V7.82223ZM67.3875 0V7.00513L61.7326 0H59.5852V9.90981H61.6927V2.93458L67.3526 9.90981H69.5V0H67.3875Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M21.8044 0L17.8534 7.81724L13.8675 0H11.5258L16.6576 9.90981H19.089L24.1859 0H21.8044ZM25.292 0H35.1918V2.10254H25.297L25.292 0ZM25.292 3.91111H35.1918V6.00368H25.297L25.292 3.91111ZM25.292 7.80728H35.1918V9.90981H25.297L25.292 7.80728ZM38.9285 8.08629V0H36.8161V9.90981H44.6034L44.8575 9.41158L45.55 8.07134L38.9285 8.08629ZM58.4791 9.90981H56.0926L52.1068 2.09257L48.1558 9.90981H45.7742L46.691 8.13611L50.8911 0H53.3224L58.4791 9.90981ZM59.5852 9.76533V9.90981H59.6649L59.5852 9.76533ZM0.5 0H10.3949V2.09257H0.5V0ZM0.5 3.91111H10.3949V5.9987H0.5V3.91111ZM0.5 7.82223H10.3949V9.90981H0.5V7.82223ZM67.3875 0V7.00513L61.7326 0H59.5852V9.90981H61.6927V2.93458L67.3526 9.90981H69.5V0H67.3875Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="0.5" y1="4.9574" x2="69.5" y2="4.9574" gradientUnits="userSpaceOnUse">
        <stop stopColor="#40A4B4" />
        <stop offset="0.08" stopColor="#329AAB" />
        <stop offset="0.32" stopColor="#0E8194" />
        <stop offset="0.45" stopColor="#00778B" />
        <stop offset="0.6" stopColor="#007787" />
        <stop offset="1" stopColor="#007377" />
      </linearGradient>
    </defs>
  </svg>
);
