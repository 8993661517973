export const garage = (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.326 10.0547L30.326 0.0546563C30.1147 -0.0182187 29.8853 -0.0182187 29.674 0.0546563L0.674 10.0547C0.27075 10.1937 0.000125 10.5732 0 10.9997V58.9997C0 59.5519 0.44775 59.9997 1 59.9997H6C6.55225 59.9997 7 59.5519 7 58.9997V27.9997H53V58.9997C53 59.5519 53.4478 59.9997 54 59.9997H59C59.5522 59.9997 60 59.5519 60 58.9997V10.9997C59.9999 10.5732 59.7293 10.1937 59.326 10.0547ZM5 26.9997V57.9997H2V19.9997H5V26.9997ZM53 25.9997H7V23.9997H53V25.9997ZM53 21.9997H7V19.9997H53V21.9997ZM58 57.9997H55V19.9997H58V57.9997ZM58 17.9997H2V11.7127L30 2.05766L58 11.7127V17.9997Z"
      fill="white"
    />
    <path
      d="M49.447 43.1L45.747 41.25L42.447 34.65C41.9394 33.6344 40.8994 32.9948 39.764 33H20.236C19.0988 32.9963 18.0584 33.6393 17.553 34.658L14.253 41.258L10.553 43.108C10.2149 43.277 10.001 43.622 10 44V58C10 59.1046 10.8954 60 12 60H16C17.1046 60 18 59.1046 18 58V55H42V58C42 59.1046 42.8954 60 44 60H48C49.1046 60 50 59.1046 50 58V44C50.0021 43.6192 49.7876 43.2702 49.447 43.1ZM19.342 35.553C19.5101 35.2133 19.8569 34.9988 20.236 35H39.764C40.1431 34.9988 40.4899 35.2133 40.658 35.553L43.382 41H16.618L19.342 35.553ZM16 58H12V55H16V58ZM48 58H44V55H48V58ZM48 53H17H12V44.618L15.236 43H44.764L48 44.618V53Z"
      fill="white"
    />
    <path
      d="M19 45H16C14.8954 45 14 45.8954 14 47V49C14 50.1046 14.8954 51 16 51H19C20.1046 51 21 50.1046 21 49V47C21 45.8954 20.1046 45 19 45ZM19 49H16V47H19V49Z"
      fill="white"
    />
    <path
      d="M44 45H41C39.8954 45 39 45.8954 39 47V49C39 50.1046 39.8954 51 41 51H44C45.1046 51 46 50.1046 46 49V47C46 45.8954 45.1046 45 44 45ZM44 49H41V47H44V49Z"
      fill="white"
    />
    <path
      d="M35 45H25C23.8954 45 23 45.8954 23 47V49C23 50.1046 23.8954 51 25 51H35C36.1046 51 37 50.1046 37 49V47C37 45.8954 36.1046 45 35 45ZM35 49H25V47H35V49Z"
      fill="white"
    />
  </svg>
);
