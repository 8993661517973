import { FC } from 'react';

export const CustomStyles: FC = () => (
  <style jsx global>
    {`
      .is-blurred > div:not(#header-nav),
      .is-blurred > div:first-child > div:first-child > div:first-child {
        filter: blur(20px);
      }

      .ribbon {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: absolute;
        z-index: 2;
      }
      .ribbon::before,
      .ribbon::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #f1f1f1;
      }
      .ribbon span {
        position: absolute;
        display: block;
        width: 225px;
        padding: 15px 0;
        background-color: white;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        color: #e21331;
        font: 400 18px Roboto, sans-serif;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
      }

      /* top right*/
      .ribbon-top-right {
        top: -10px;
        right: -10px;
      }
      .ribbon-top-right::before,
      .ribbon-top-right::after {
        border-top-color: transparent;
        border-right-color: transparent;
      }
      .ribbon-top-right::before {
        top: 0;
        left: 0;
      }
      .ribbon-top-right::after {
        bottom: 0;
        right: 0;
      }
      .ribbon-top-right span {
        left: -25px;
        top: 30px;
        transform: rotate(45deg);
      }
    `}
  </style>
);
