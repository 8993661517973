import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { FC, useContext, useState } from 'react';
import { RequestCarContext } from 'src/feature/page/content/request-car/requestCarPage';
import { BrandIcon } from '../../../components/brands-list/BrandsList';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';
import { BRANDS_LIST } from './brandsImages';

export const ChooseMakeContent: FC<{ onSelectLogo: () => void }> = ({ onSelectLogo }) => {
  const { headings, gridGap } = useBaseTheme();
  const { setFormAdditionalValues } = useFormContext();
  const [activeMake, setActiveMake] = useState('');
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { chooseMakeTitle } = requestCarPage?.requestCarFormSection ?? {};

  const { setBrand } = useContext(RequestCarContext);

  return (
    <Box minH="420px">
      <Box maxWidth={545} textAlign={'center'} margin={'0 auto'} mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {chooseMakeTitle}
        </Heading>
      </Box>
      <SimpleGrid gridGap={gridGap} gridRowGap={['2.75rem']} columns={[2, 3, null, 4]}>
        {BRANDS_LIST.map(({ name, value }, idx) => (
          <Box role="group" key={`brand-list-item-${idx}`} cursor="pointer">
            <BrandIcon
              name={name}
              color={activeMake === value ? 'brand.primary.500' : 'white'}
              onClick={() => {
                if (setBrand) {
                  setBrand(value);
                }
                setActiveMake(value);
                setFormAdditionalValues((prev) => ({ ...prev, make: value }));
                onSelectLogo();
              }}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};
