import { Box, BoxProps, Grid, Heading, Icon, Link, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { FluidPictureAsset } from 'sanity-picture-asset';
import { CarData } from '../../feature/page/content/cars-page/cars';
import { RightArrow } from '../../theme/icons';
import { DEFAULT_LANGUAGE } from '../../theme/languages';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { SetRequired } from 'type-fest';
import { DEFAULT_ASPECT_RATIO } from 'src/constants';
import { CarInformationList } from './CarInformationList';
import NextLink from 'next/link';

type CarCardProps = SetRequired<Partial<CarData>, 'coverImage' | 'name'> &
  BoxProps & {
    showDetails?: boolean;
    showViewDetailsButton?: boolean;
  };

export const CarCard: FC<CarCardProps> = ({
  onClick,
  coverImage,
  name,
  availablityPurchase,
  firstRegistration,
  yearConstruction,
  mileage,
  power,
  gearBox,
  condition,
  price,
  priceExclVat,
  slug,
  showDetails = true,
  showViewDetailsButton = true,
  ...others
}) => {
  const { locale = DEFAULT_LANGUAGE, push } = useRouter();
  const { translations } = useTranslationsContext();
  const { viewMoreText, priceInclVatLabel, priceExclVatLabel } = translations?.carsPage ?? {};
  const { headings, linkStyle, paragraph } = useBaseTheme();
  const carDetailUrl = `/cars/${slug?.current ?? ''}`;

  const handleViewMoreDetails = useCallback(() => {
    if (slug) {
      void push('/cars/[slug]', carDetailUrl, { locale });
    }
  }, [push, locale, slug, carDetailUrl]);

  return (
    <Box
      pos="relative"
      mb={['2.5rem', null, null, '6.125rem']}
      transition=".3s ease-in-out"
      p="1rem"
      bg="brand.secondary.900"
      cursor="pointer"
      onClick={onClick ?? handleViewMoreDetails}
      {...(({ gallery, mobileDeLink, ...rest }) => rest)({ ...others })}
    >
      <Grid rowGap="1rem" h="100%" role="group" gridTemplateRows="max-content 1fr">
        <Box pos="relative" h="100%" w="100%">
          <Box
            transition=".3s ease-in-out"
            pos="absolute"
            top={0}
            left={0}
            w="100%"
            h="100%"
            backgroundColor="transparent"
            zIndex={-1}
            _groupHover={{ borderColor: 'brand.primary.500', borderWidth: 4, zIndex: 3 }}
          />
          <FluidPictureAsset
            image={coverImage}
            aspectRatio={DEFAULT_ASPECT_RATIO}
            alt={coverImage.alt ?? 'Car Cover Image'}
          />
        </Box>
        <Box color="white" transition=".3s ease-in-out" _groupHover={{ color: 'brand.primary.500' }} mt={2}>
          <Heading
            as="h5"
            {...headings?.h5}
            my={[0, null, null, null, 1]}
            fontSize={{ base: '1.5rem', xl: '1.75rem' }}
            lineHeight={{ base: '2rem', xl: '2.1875rem' }}
            color="inherit"
            fontWeight={[400]}
            mb={0}
            pb={0}
          >
            {name}
          </Heading>
        </Box>
        {showDetails && availablityPurchase && firstRegistration && yearConstruction && (
          <CarInformationList
            availablityPurchase={availablityPurchase}
            firstRegistration={firstRegistration}
            yearConstruction={yearConstruction}
            mileage={mileage}
            power={power}
            gearBox={gearBox}
            condition={condition}
          />
        )}
        <Box
          _empty={{ d: 'none' }}
          color="white"
          transition=".3s ease-in-out"
          _groupHover={{ color: 'brand.primary.500' }}
        >
          {price && (
            <Heading
              mb="0.1875rem"
              as="h5"
              {...headings?.h5}
              fontSize={{ base: '1.5rem', xl: '1.75rem' }}
              color="inherit"
            >
              {price} ({priceInclVatLabel})
            </Heading>
          )}
          {priceExclVat && (
            <Heading as="h6" mb={[2, null, null, '0.5rem']} {...headings?.h6} fontSize="1.2rem" color="gray.500">
              {priceExclVat} ({priceExclVatLabel})
            </Heading>
          )}
          {showViewDetailsButton && (
            <NextLink href={carDetailUrl} locale={locale} passHref>
              <Link {...linkStyle} color="inherit">
                <Text
                  as="span"
                  d="block"
                  mt={2}
                  {...paragraph?.body4}
                  fontFamily="heading"
                  fontWeight={400}
                  fontSize="1rem"
                  lineHeight={['1rem', null, null, null, '1.25rem']}
                  letterSpacing={2}
                  color="inherit"
                  textTransform="uppercase"
                >
                  {viewMoreText}
                  <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
                </Text>
              </Link>
            </NextLink>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
