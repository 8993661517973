import React, { FC } from 'react';
import { Box, Grid, ThemeProvider } from '@chakra-ui/react';
import { FillPictureAsset, isSanityImageAsset } from 'sanity-picture-asset';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { Container } from 'jexity/packages/components/container/Container';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Content } from 'jexity/packages/components/block-content/blockContentApi';
import { overrideThemeTextWith } from 'jexity/packages/baseTheme/themeUtil';
import { BaseSectionProps } from '../common/sectionsApi';
import { CommissionOverviewSection as CommissionOverviewSectionProps } from '../../feature/page/content/commission-sales-page/commissionSales';

const CommissionOverviewSection: FC<BaseSectionProps<CommissionOverviewSectionProps>> = ({ data }) => {
  const theme = useBaseTheme();
  const { headings, sections } = theme;
  const { sectionWrapper } = sections?.commissionOverviewSection ?? {};
  const { image, imagePlacement, imagePosition, content, title } = data;

  const isImageLeft = imagePlacement === 'left';
  const isImageOverflow = imagePosition === 'overflow';

  const titleTheme = overrideThemeTextWith(theme, {
    ...headings?.h4,
    fontSize: '1.25rem',
    color: 'rgba(255,255,255,0.7)',
    mt: 0,
    textTransform: 'uppercase',
    fontWeight: 400,
  });

  const contentTheme = overrideThemeTextWith(theme, {
    marginTop: 0,
  });

  return (
    <SectionContainer mb={['1.5rem', null, null, 0]} {...sectionWrapper}>
      <Grid as="section" pos="relative" templateColumns="auto" templateRows="auto">
        <Container variant={isImageOverflow ? 'wide' : 'normal'}>
          <Grid
            pos="relative"
            zIndex={2}
            w="100%"
            h="100%"
            columnGap={'2rem'}
            rowGap={'2rem'}
            gridTemplateColumns={[
              '1fr',
              null,
              null,
              isImageOverflow ? (isImageLeft ? '1fr 45%' : '45% 1fr') : '1fr 1fr',
              null,
              null,
              isImageOverflow ? (isImageLeft ? '1fr 47%' : '47% 1fr') : null,
            ]}
            alignItems="top"
          >
            <Box
              h="100%"
              d="flex"
              flexDir="column"
              alignItems={['flex-start', null, null, isImageLeft ? 'flex-start' : 'flex-end']}
            >
              <Box textAlign="left" maxW={['100%', '100%', '100%', '100%', '500px', '490px', '695px', '685px']}>
                <ThemeProvider theme={titleTheme}>
                  <BlockContent blocks={title as Content[]} />
                </ThemeProvider>
                <ThemeProvider theme={contentTheme}>
                  <BlockContent blocks={content as Content[]} />
                </ThemeProvider>
              </Box>
            </Box>

            <Box w={['100%']} h={['314px', '458px']} pos="relative">
              {isSanityImageAsset(image) && (
                <Box pos="absolute" top={0} left={0} w="100%" h={['314px', '458px']}>
                  <FillPictureAsset image={image} alt={image.alt ?? 'Image'} objectFit="cover" />
                </Box>
              )}
            </Box>
          </Grid>
        </Container>
      </Grid>
    </SectionContainer>
  );
};

export default CommissionOverviewSection;
