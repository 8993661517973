import { FormStyles } from 'jexity/packages/baseTheme/baseTheme';

export const formStyles: FormStyles = {
  response: {
    wrapper: {
      bg: 'brand.bodyBackground',
      transform: 'initial',
    },
    confirmation: {
      mb: 1,
    },
    serverError: {
      mb: 1,
      color: 'white',
      textTransform: 'uppercase',
    },
    showFormBtn: {
      variant: 'primary',
      mt: '2rem',
      p: '16px 16px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 700,
      fontFamily: 'heading',
      textTransform: 'none',
    },
  },
};
