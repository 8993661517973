import React, { FC } from 'react';
import { Box, ComponentWithAs, Heading, Icon, IconProps, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';

interface InfoBoxProps {
  title?: string;
  content?: string;
  icon: ComponentWithAs<'svg', IconProps>;
}

const InfoBox: FC<InfoBoxProps> = ({ title, content, icon }) => {
  const theme = useBaseTheme();
  return (
    <Box minH="8rem" bg="brand.primary.500" p="1.5rem" pb={8}>
      <Box
        d={{
          base: 'initial',
          sm: 'flex',
        }}
      >
        <Box mr="1.5rem" w="3.75rem" mb="1rem">
          <Icon as={icon} boxSize="3.75rem" color="brand.primary.500" />
        </Box>
        <Box>
          {title && (
            <Heading as="h5" {...theme.headings?.h5} mb=".5rem">
              {title}
            </Heading>
          )}
          {content && (
            <Text {...theme.paragraph?.body4} color="white">
              {content}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InfoBox;
