import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import { FormContext } from 'jexity/packages/components/form/useFormContext';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import React, { FC, useState } from 'react';
import { SliderContainer } from './slider-container/SliderContainer';
import NextImage from 'next/image';

export interface ContactAdditionalDetails {
  make: string;
  model: string;
  minimumBudget: number;
  maximumBudget: number;
  mileage: number;
  registrationYear: number;
  cubicCapacity: string;
  transmission: string;
}

const EMPTY_ADDITIONAL_CONTACT_DETAILS = {
  make: '',
  model: '',
  minimumBudget: 0,
  maximumBudget: 0,
  mileage: 0,
  registrationYear: 0,
  cubicCapacity: '',
  transmission: '',
};

const RequestCarFormSection: FC = () => {
  const { sections } = useBaseTheme();
  const { sectionWrapper } = sections?.requestCarFormSection ?? {};
  const [formAdditionalValues, setFormAdditionalValues] = useState(EMPTY_ADDITIONAL_CONTACT_DETAILS);

  return (
    <SectionContainer {...sectionWrapper}>
      <Container
        zIndex={0}
        backgroundColor="brand.secondary.900"
        py={['2.5rem', null, null, '2.6875rem']}
        minH="600px"
        pos="relative"
      >
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          _after={{
            pos: 'absolute',
            content: '""',
            bg: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
            w: '100%',
            h: '100%',
            top: 0,
            left: 0,
            boxShadow: 'inset 0 0 300px 220px rgb(0 0 0 / 90%)',
            zIndex: 0,
          }}
        >
          <NextImage
            alt="request car form bg"
            src="/assets/png/request-car-form-bg.jpg"
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <FormContext.Provider value={{ setFormAdditionalValues, formAdditionalValues }}>
          <SliderContainer />
        </FormContext.Provider>
      </Container>
    </SectionContainer>
  );
};

export default RequestCarFormSection;
