import { Box, BoxProps, Flex, Icon, Link, LinkProps, SimpleGrid, Text, TextProps } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../../../theme/languages';
import NextLink from 'next/link';
import { useTranslationsContext } from '../../../../theme/translations/TranslationContext';
import { useSiteSettingsContext } from 'jexity/packages/feature/common';
import { getSecondaryMenuList } from '../../../../theme/menu/menuListItem';

export const MenuModalDetails: FC<BoxProps & { setOpen: Dispatch<SetStateAction<boolean>> }> = ({
  setOpen,
  ...others
}) => {
  const { headings } = useBaseTheme();
  const { siteSettings } = useSiteSettingsContext();
  const { locale = DEFAULT_LANGUAGE, asPath } = useRouter();
  const { translations } = useTranslationsContext();
  const { socialLabel, detailsLabel, languageLabel } = translations?.header ?? {};
  const secondaryMenuList = useMemo(() => {
    return getSecondaryMenuList(locale);
  }, [locale]);

  const { socialMedia = [] } = siteSettings;

  const defaultTextStyle: TextProps = {
    ...headings?.h6,
    fontSize: '1.1rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '2px',
  };

  const defaultLinkStyle: LinkProps = {
    transition: 'color 0.3s ease-in',
    _hover: {
      textDecoration: 'none',
      color: 'brand.primary.500',
    },
    _focus: { outline: 'none' },
  };

  return (
    <Box minW={['339px']} bgColor="brand.primary.600" zIndex={3} {...others}>
      <SimpleGrid columns={[2, null, null, 1]} mt={['0', '6.5rem']}>
        <Box mb={['1rem']} order={[2, null, null, 1]}>
          <Text {...defaultTextStyle} fontWeight={500} mb="1rem" color="gray.500">
            {socialLabel}
          </Text>
          {socialMedia
            .filter((name) => !!name)
            .map((media, idx) => (
              <Link
                key={`social-link-${idx}`}
                href={media?.url}
                d="flex"
                color="brand.textColor"
                transition="color .4s ease-out"
                _hover={{
                  textDecor: 'none',
                  color: 'brand.primary.500',
                }}
                _focus={{ boxShadow: 'none' }}
                aria-label={`${media?.name}`}
                isExternal
                mb="1rem"
              >
                <Text {...defaultTextStyle} color="inherit" fontWeight={400}>
                  {media?.name}
                </Text>
              </Link>
            ))}
        </Box>
        <Box mb={['2rem']} order={[1, null, null, 2]}>
          <Text {...defaultTextStyle} fontWeight={500} mb="1rem" color="gray.500">
            {detailsLabel}
          </Text>
          {secondaryMenuList.map(({ title, link }) => (
            <NextLink key={`sec-meu-item-${link.href}`} {...link} locale={locale}>
              <Link
                {...defaultLinkStyle}
                color={asPath === link.href.toString() ? 'brand.primary.500' : 'white'}
                onClick={() => setOpen(false)}
              >
                <Text {...defaultTextStyle} color="inherit" mb="1rem">
                  {title}
                </Text>
              </Link>
            </NextLink>
          ))}
        </Box>
      </SimpleGrid>
      <Flex flexDir="column">
        <Text {...defaultTextStyle} fontWeight={400} mb={['1.125rem']} color="gray.500">
          {languageLabel}
        </Text>
        <SimpleGrid columns={[2, null, null, 1]} gridRowGap="1rem">
          {LANGUAGES.map((language, idx) => (
            <Flex pos="relative" key={`language-${idx}`} alignItems="center">
              <NextLink href={asPath} locale={language.key} passHref>
                <Link
                  transition=".4s ease-out"
                  pt="2px"
                  _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
                  {...(defaultTextStyle as LinkProps)}
                  textDecor={language.key === locale ? 'underline' : 'none'}
                  onClick={() => setOpen(false)}
                >
                  <Flex flexDir="row" alignItems="center">
                    <Icon as={language.icon} mr="6px" w="28px" h="20px" />
                    <Text color="inherit">{language.label}</Text>
                  </Flex>
                </Link>
              </NextLink>
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
