import { Box, Flex, Grid, Heading, HStack, Icon, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Container } from 'jexity/packages/components/container/Container';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';
import { BulletListIcon, Envelope, Phone } from 'src/theme/icons';
import Floor from 'src/components/Floor';
import { SwipeableSingle } from 'jexity/packages/components/swipeable/swipeable-single/SwipeableSingle';
import { Bullet } from 'jexity/packages/components/bullet/Bullet';
import NextLink from 'next/link';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { DEFAULT_LANGUAGE } from '../../../../theme/languages';
import { useRouter } from 'next/router';

const PriceCard: FC<{
  title: string;
  lists: string[];
  index: number;
  subPackage?: {
    title: string;
    lists: string[];
  };
}> = ({ title, index, lists, subPackage }) => {
  const { headings, paragraph } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { pricingSection } = translations?.vehicleStoragePage ?? {};
  const packagesList = pricingSection?.defaultPackagesList
    ? [...pricingSection.defaultPackagesList, ...lists]
    : [...lists];

  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const bestSeller = title.toLowerCase() === 'premium';

  return (
    <Box pos="relative" d="grid" gridTemplateRows="min-content 1fr">
      {bestSeller && (
        <div className="ribbon ribbon-top-right">
          <span>BESTSELLER</span>
        </div>
      )}

      <Box bg="brand.primary.500" p="1.5rem">
        <Text {...paragraph?.body4} color="#F6B8C1">
          PACKAGE {index + 1}
        </Text>
        <Heading {...headings?.h3}>{title}</Heading>
      </Box>

      <Flex flexDir="column" p="1.5rem" bg={bestSeller ? 'brand.primary.500' : 'brand.paragraphColor'} pos="relative">
        <Box mb="1.75rem">
          {packagesList.map((data, index) => (
            <Box key={index} mb="0.625rem">
              <Flex>
                <Icon as={BulletListIcon} color={bestSeller ? 'white' : 'brand.primary.500'} mr={2} mt="0.15625rem" />
                <Text as="span" {...paragraph?.body4} color={bestSeller ? '#d1d1d1' : 'brand.secondary.900'}>
                  {data}
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>

        {subPackage && (
          <Box mb="1.75rem">
            <Text
              {...paragraph?.body4}
              fontWeight="700"
              color={bestSeller ? '#d1d1d1' : 'brand.secondary.900'}
              mb="0.625rem"
            >
              {subPackage.title}
            </Text>

            {subPackage.lists.map((data, index) => (
              <Box key={index} mb="0.625rem">
                <Flex>
                  <Icon as={BulletListIcon} color={bestSeller ? 'white' : 'brand.primary.500'} mr={2} mt="0.15625rem" />
                  <Text as="span" {...paragraph?.body4} color={bestSeller ? '#d1d1d1' : 'brand.secondary.900'}>
                    {data}
                  </Text>
                </Flex>
              </Box>
            ))}
          </Box>
        )}

        <VStack mt="auto" bottom="0" pb="1.5rem" gridGap="0.375rem" alignItems="flex-start">
          <Text
            d="block"
            as="p"
            {...paragraph?.body4}
            color={bestSeller ? 'white' : 'brand.secondary.900'}
            textTransform="uppercase"
            letterSpacing="0.125rem"
          >
            {pricingSection?.getInTouch}
          </Text>
          <SimpleGrid gridGap={2} justifyContent="space-between" gridColumnGap="1.75rem" gridColumn="">
            <Link
              href="tel:+491725633714"
              textDecoration="none"
              transition="transform 0.2s ease-out"
              transformOrigin="left"
              d="inline-block"
              _hover={{
                textDecoration: 'none',
                transform: 'scale(1.05)',
              }}
              _focus={{ outline: 'none' }}
            >
              <Icon as={Phone} boxSize="1.25rem" color={bestSeller ? 'white' : 'brand.primary.500'} />
              <Text
                ml="0.3125rem"
                as="span"
                {...paragraph?.body4}
                color={bestSeller ? 'white' : 'brand.primary.500'}
                letterSpacing="0.125rem"
                textDecor="none"
              >
                +49 172 56 33 714
              </Text>
            </Link>

            <NextLink href="/contact-us" locale={locale} passHref>
              <Link
                textDecoration="none"
                transition="transform 0.2s ease-out"
                transformOrigin="left"
                d="inline-block"
                _hover={{
                  textDecoration: 'none',
                  transform: 'scale(1.05)',
                }}
                _focus={{ outline: 'none' }}
              >
                <Icon as={Envelope} boxSize="1.25rem" mb="2px" color={bestSeller ? 'white' : 'brand.primary.500'} />
                <Text
                  cursor="pointer"
                  ml="0.3125rem"
                  as="span"
                  {...paragraph?.body4}
                  color={bestSeller ? 'white' : 'brand.primary.500'}
                  letterSpacing="0.125rem"
                  textTransform="uppercase"
                >
                  E-Mail
                </Text>
              </Link>
            </NextLink>
          </SimpleGrid>
        </VStack>
      </Flex>
    </Box>
  );
};

const PricingPackages: FC = () => {
  const { translations } = useTranslationsContext();
  const { headings } = useBaseTheme();
  const { pricingSection } = translations?.vehicleStoragePage ?? {};
  const [activeItem, setActiveItem] = useState(0);

  return (
    <Box
      pos="relative"
      zIndex={1}
      pb={{
        base: 0,
        md: 16,
      }}
      pt={{
        base: '3.25rem',
        md: 40,
      }}
    >
      <Floor
        pos="absolute"
        w={['400vw', null, null, '150vw']}
        maxW="3200px"
        h="auto"
        bottom="0"
        transform="translateY(50%)"
      />

      <Container pos="relative" zIndex="2">
        <Heading as="h2" {...headings?.h2} mb={{ base: '1.5rem', sm: '2rem' }}>
          {pricingSection?.sectionTitle}
        </Heading>
        <Box d={{ base: 'none', lg: 'initial' }}>
          <SimpleGrid gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="2rem">
            {pricingSection?.pricingPackagesList.map(({ title, lists, subPackage }, index: number) => (
              <PriceCard
                title={title}
                key={index}
                index={index}
                lists={lists}
                {...(subPackage && {
                  subPackage: subPackage,
                })}
              />
            ))}
          </SimpleGrid>
        </Box>

        <Box d={{ base: 'initial', lg: 'none' }}>
          <Grid
            w="100%"
            gridGap={4}
            gridTemplateColumns={{ base: 'repeat(2, min-content)', sm: 'repeat(4, min-content)' }}
            mb="1.25rem"
            justifyContent="space-between"
          >
            {pricingSection?.pricingPackagesList.map(({ title }, index) => {
              return (
                <Text
                  as="span"
                  whiteSpace="nowrap"
                  onClick={() => setActiveItem(index)}
                  key={title}
                  {...headings?.h5}
                  cursor="pointer"
                  color={activeItem === index ? 'brand.primary.500' : 'white'}
                  transition=".2s ease-in"
                  _hover={{
                    ...(!(index === activeItem) && {
                      transform: 'scale(1.05)',
                      color: 'brand.primary.500',
                    }),
                  }}
                >
                  {title}
                </Text>
              );
            })}
          </Grid>
          <SwipeableSingle
            activeIndex={activeItem}
            setActiveIndex={setActiveItem}
            skipHeightAnimation={false}
            autoPlay={false}
          >
            {pricingSection?.pricingPackagesList.map(({ title, lists, subPackage }, index: number) => (
              <PriceCard
                title={title}
                key={index}
                index={index}
                lists={lists}
                {...(subPackage && {
                  subPackage: subPackage,
                })}
              />
            ))}
          </SwipeableSingle>

          {pricingSection?.pricingPackagesList && (
            <Bullet
              setActiveItem={(index) => setActiveItem(index)}
              activeItem={activeItem}
              data={pricingSection.pricingPackagesList}
              mt="2rem"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default PricingPackages;
