import { Icon, IconProps } from '@chakra-ui/icon';
import { FC } from 'react';

const ChevronIcon: FC<IconProps> = ({ children, css, ...props }) => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 17.9999C8.99938 17.7662 9.08075 17.5398 9.22984 17.3599L13.7098 11.9999L9.38984 6.62985C9.04168 6.20113 9.10699 5.57134 9.53571 5.22318C9.53708 5.22207 9.53846 5.22096 9.53984 5.21985L9.53984 5.21985C9.96856 4.87169 10.5983 4.93701 10.9465 5.36573C10.9476 5.3671 10.9487 5.36848 10.9498 5.36985L15.7798 11.3699L15.7798 11.3699C16.0832 11.7389 16.0832 12.2709 15.7798 12.6399L10.7798 18.6399C10.4273 19.065 9.79693 19.1239 9.37176 18.7715C9.37111 18.7709 9.37047 18.7704 9.36983 18.7699C9.13756 18.5814 9.00181 18.2989 8.99983 17.9999L8.99984 17.9999Z"
        fill="#FCFCFC"
      />
    </Icon>
  );
};

export default ChevronIcon;
