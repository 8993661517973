export const instagram = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24637 1.41821C4.16112 1.46718 3.22047 1.73252 2.46092 2.48886C1.69872 3.24918 1.4367 4.19344 1.38761 5.26741C1.3571 5.93773 1.17865 11.0018 1.69607 12.3299C2.045 13.2259 2.73224 13.9148 3.6364 14.2648C4.05829 14.4289 4.53989 14.5401 5.24637 14.5725C11.1536 14.8398 13.3433 14.6942 14.2667 12.3299C14.4306 11.9091 14.5433 11.428 14.5745 10.7233C14.8445 4.80094 14.5307 3.51723 13.5012 2.4889C12.6846 1.67432 11.7241 1.11981 5.24632 1.41824M5.30071 13.3814C4.65394 13.3523 4.30302 13.2444 4.06885 13.1538C3.47979 12.9248 3.03733 12.4841 2.8098 11.8985C2.41576 10.8894 2.54645 6.09655 2.5816 5.32102C2.6161 4.56137 2.77 3.86723 3.30599 3.33124C3.96935 2.66952 4.82641 2.34528 10.662 2.60865C11.4235 2.64305 12.1194 2.79657 12.6567 3.33124C13.3201 3.99295 13.6491 4.85649 13.3811 10.6697C13.3519 11.3149 13.2438 11.6649 13.1529 11.8985C12.5526 13.437 11.1714 13.6507 5.3007 13.3814M10.7263 4.46281C10.7263 4.90087 11.0825 5.25687 11.5223 5.25687C11.9622 5.25687 12.319 4.90087 12.319 4.46281C12.319 4.02476 11.9622 3.66875 11.5223 3.66875C11.0825 3.66875 10.7263 4.02476 10.7263 4.46281ZM4.57499 7.99504C4.57499 9.87167 6.10005 11.393 7.98134 11.393C9.86262 11.393 11.3877 9.87167 11.3877 7.99504C11.3877 6.11842 9.86262 4.5978 7.98134 4.5978C6.10005 4.5978 4.57499 6.11842 4.57499 7.99504ZM5.77037 7.99505C5.77037 6.7775 6.76011 5.78955 7.98135 5.78955C9.20259 5.78955 10.1923 6.7775 10.1923 7.99505C10.1923 9.21327 9.20259 10.2012 7.98135 10.2012C6.76011 10.2012 5.77037 9.21327 5.77037 7.99505Z"
      fill="white"
    />
  </svg>
);
