import {
  Box,
  BoxProps,
  ComponentWithAs,
  Flex,
  Icon,
  IconProps,
  Link,
  LinkProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Variants } from 'framer-motion';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { MotionBox } from 'jexity/packages/components/motion/Motion';
import { Dispatch, FC, SetStateAction } from 'react';
import { LANGUAGES } from '../../../../theme/languages';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

const coverVariants: Variants = {
  open: {
    height: '116px',
    transition: { delay: 0.1 },
  },
  close: { height: '0', transition: { delay: 0.2 } },
};

const gridVariants: Variants = {
  open: {
    height: '100%',
    transition: { delay: 0.3, duration: 0.2 },
  },
  close: { height: '0%', transition: { duration: 2 } },
};

interface LanguageSelectorProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedIcon: Dispatch<SetStateAction<ComponentWithAs<'svg', IconProps>>>;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ open, setOpen, setSelectedIcon }) => {
  const { headings } = useBaseTheme();
  const { asPath } = useRouter();
  // redirect to home page if slug is invalid

  const defaultTextStyle: BoxProps = {
    ...headings?.h6,
    fontSize: 'md',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'heading',
    letterSpacing: '2px',
  };

  return (
    <MotionBox
      d={['none', null, null, 'block']}
      pos="absolute"
      top="75px"
      right={[0, '2.25rem', null, null, null, null, '3.25rem']}
      maxW="173px"
      w="100%"
      variants={coverVariants}
      initial="close"
      animate={open ? 'open' : 'close'}
    >
      <Box pos="absolute" top={0} left={0} h="100%" w="100%">
        <MotionBox
          position="relative"
          variants={gridVariants}
          initial="close"
          animate={open ? 'open' : 'close'}
          overflow="hidden"
        >
          <SimpleGrid
            p="1.375rem 2.1875rem"
            columns={1}
            gridRowGap="1.5rem"
            backgroundColor="brand.secondary.900"
            justifyItems="right"
          >
            {LANGUAGES.map((language, idx) => (
              <Flex pos="relative" key={`language-${idx}`} alignItems="center">
                <NextLink href={asPath} locale={language.key} passHref>
                  <Link
                    transition=".4s ease-out"
                    pt="2px"
                    _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
                    {...(defaultTextStyle as LinkProps)}
                    textDecor="none"
                    onClick={() => {
                      setOpen(false);
                      setSelectedIcon(language.icon);
                    }}
                  >
                    <Flex flexDir="row" alignItems="center">
                      <Text color="inherit">{language.label}</Text>
                      <Icon as={language.icon} ml="0.5rem" boxSize="24px" />
                    </Flex>
                  </Link>
                </NextLink>
              </Flex>
            ))}
          </SimpleGrid>
        </MotionBox>
      </Box>
    </MotionBox>
  );
};
