export const listIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 1.33325C4.31777 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31777 14.6666 7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325V1.33325ZM9.99967 8.66659H8.66634V9.99992C8.66634 10.3681 8.36787 10.6666 7.99967 10.6666C7.63148 10.6666 7.33301 10.3681 7.33301 9.99992V8.66659H5.99967C5.63148 8.66659 5.33301 8.36811 5.33301 7.99992C5.33301 7.63173 5.63148 7.33325 5.99967 7.33325H7.33301V5.99992C7.33301 5.63173 7.63148 5.33325 7.99967 5.33325C8.36787 5.33325 8.66634 5.63173 8.66634 5.99992V7.33325H9.99967C10.3679 7.33325 10.6663 7.63173 10.6663 7.99992C10.6663 8.36811 10.3679 8.66659 9.99967 8.66659Z"
      fill="#E21331"
    />
  </svg>
);
