import { FC } from 'react';
import { Box, Grid, Heading, ModalCloseButton, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { PictureAsset } from 'sanity-picture-asset';
import { CarData } from '../../feature/page/content/cars-page/cars';
import { RequestCarContactUsForm } from '../request-car-contact-us-form/RequestCarContactUsForm';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { DEFAULT_ASPECT_RATIO } from '../../constants';

const CarDetailForm: FC<Pick<CarData, 'coverImage' | 'category' | 'name' | 'price' | 'priceExclVat'>> = ({
  coverImage,
  name,
  price,
  priceExclVat,
}) => {
  const { translations } = useTranslationsContext();
  const { priceInclVatLabel, priceExclVatLabel } = translations?.carsPage ?? {};
  const { headings } = useBaseTheme();

  return (
    <>
      <Box d={['none', null, null, 'block']}>
        <Box pos="absolute" right={0} top={0} zIndex={120}>
          <ModalCloseButton size="md" color="white" />
        </Box>
        <Box pos="relative">
          <Grid gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="2rem">
            <Box>
              <Box pos="relative" overflow="hidden" mb="1.5rem" minH={['250px', null, null, '379px']}>
                <Box pos="absolute" maxH="380px" top={0} right={0} w="100%" h="100%">
                  <PictureAsset
                    objectFit="contain"
                    image={coverImage}
                    alt={coverImage.alt ?? name}
                    isFluid={true}
                    aspectRatio={1.4}
                  />
                </Box>
              </Box>
              <Box>
                {!!name && (
                  <Heading
                    as="h1"
                    {...headings?.h1}
                    mt={2}
                    mb="1.125rem"
                    letterSpacing={2}
                    fontWeight={400}
                    textTransform="uppercase"
                  >
                    {name}
                  </Heading>
                )}
              </Box>
              <Box mt={4} mb={['0.5rem', null, null, null, '1.25rem']}>
                <Text
                  fontFamily="heading"
                  fontSize="2.5rem"
                  lineHeight="3.6875rem"
                  letterSpacing={2}
                  fontWeight={400}
                  color="brand.primary.500"
                >
                  {price} ({priceInclVatLabel})
                </Text>
                {priceExclVat && (
                  <Text {...headings?.h5}>
                    {priceExclVat} ({priceExclVatLabel})
                  </Text>
                )}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" px={['1.1562rem', 0]}>
              <RequestCarContactUsForm />
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box d={['block', null, null, 'none']}>
        <Box pos="relative" minH={['100%', '280px']}>
          <Box pos="absolute" top={0} left={0} w="100%" h="100%" zIndex={100} backgroundColor="black" opacity={0.6} />
          <Box maxH="355px" overflow="hidden">
            <PictureAsset
              objectFit="contain"
              image={coverImage}
              alt={coverImage.alt ?? name}
              isFluid={true}
              aspectRatio={DEFAULT_ASPECT_RATIO}
            />
          </Box>
          <Box pos="absolute" top="4px" right="4px" zIndex={120}>
            <ModalCloseButton size="md" color="white" />
          </Box>
          <Box pos="absolute" bottom={4} left={['1.1562rem', null, null, '2rem']} zIndex={120}>
            <Box>
              {!!name && (
                <Heading
                  as="h1"
                  {...headings?.h1}
                  fontSize="1.5rem"
                  my={1}
                  letterSpacing={1}
                  fontWeight={400}
                  textTransform="uppercase"
                >
                  {name}
                </Heading>
              )}
            </Box>
            <Box>
              <Text fontFamily="heading" fontSize="2.5rem" lineHeight="3.6875rem" fontWeight={400} color="white">
                {price} ({priceInclVatLabel})
              </Text>
              {priceExclVat && (
                <Text {...headings?.h5}>
                  {priceExclVat} ({priceExclVatLabel})
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          mt={[4, null, null, '-0.5rem']}
          flexWrap="wrap"
          px="1.1562rem"
          pb="2rem"
          mx={[0, null, null, '-0.5rem']}
        >
          <RequestCarContactUsForm />
        </Box>
      </Box>
    </>
  );
};

export default CarDetailForm;
