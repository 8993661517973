import { Box, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';

export interface Makes {
  value: string;
  label?: string;
}

const CustomizedScroll = () => {
  return (
    <style jsx global>
      {`
        .filters-container::-webkit-scrollbar {
          width: 0.5rem;
          height: 0.5rem;
        }
        .filters-container::-webkit-scrollbar-track {
          height: 100%;
          background: #656565;
          border-radius: 3px;
        }
        .filters-container::-webkit-scrollbar-thumb {
          background: #e21331;
          border-radius: 3px;
        }
        .filter-radio[data-checked] {
          background: white;
          border-color: #e21331;
          color: #e21331;
        }
      `}
    </style>
  );
};

const CarMakesList: FC<{
  currentFilter: Makes['value'];
  makes: Makes[];
  onChange: (value: Makes['value']) => void;
}> = ({ currentFilter, makes, onChange }) => {
  return (
    <>
      <CustomizedScroll />
      <Box
        minW="12.5rem"
        p="1.5rem"
        bg="brand.secondary.900"
        maxH="15.625rem"
        overflow="auto"
        className="filters-container"
        mt={{
          base: '1rem',
          md: '0.875rem',
        }}
      >
        <RadioGroup value={currentFilter}>
          <Stack>
            {makes.map((make, i) => (
              <Radio
                className="filter-radio"
                color="white"
                bg="white"
                value={make.value}
                key={i}
                onChange={() => onChange(make.value)}
              >
                <Text textTransform="none">{make.label}</Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Box>
    </>
  );
};

export default CarMakesList;
