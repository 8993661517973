import React, { FC, useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { SanityImage } from 'sanity-picture-asset';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useMedia } from 'jexity/packages/components/media-query/useMedia';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import { Container } from 'jexity/packages/components/container/Container';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import Slideshow from '../../components/slideshow/Slideshow';

interface ImageGallerySectionProps {
  images: SanityImage[];
}

const ImageGallerySection: FC<ImageGallerySectionProps> = ({ images }) => {
  const { headings } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const [renderedPreviewLimit, setRenderedPreviewLimit] = useState(false);
  const { imageGallerySection } = translations?.carDetailsPage ?? {};
  const { sectionTitle } = imageGallerySection ?? {};
  const isDesktop = useMedia(['(max-width: 1200px)'], [false], true);

  useEffect(() => {
    return isDesktop ? setRenderedPreviewLimit(true) : setRenderedPreviewLimit(false);
  }, [isDesktop]);

  return (
    <SectionContainer>
      <Container>
        <Heading as="h3" {...headings?.h3}>
          {sectionTitle}
        </Heading>
        <Box mt="1.25rem" maxW={[1920]} m="auto">
          {renderedPreviewLimit ? (
            <Slideshow
              showCaptionOnFullScreen
              layout="grid"
              slides={images}
              previewLimit={6}
              loadFullScreenSlideshowOnClick={true}
            />
          ) : (
            <Slideshow
              showCaptionOnFullScreen
              layout="slideshow"
              slides={images}
              previewLimit={6}
              loadFullScreenSlideshowOnClick={true}
            />
          )}
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default ImageGallerySection;
