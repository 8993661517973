import Cookies from 'js-cookie';
import { AcceptedCookie, CookieContent } from './cookiesApi';

/**
 * Get Cookie
 * @param category
 */
export const getCookie = (category: string): string | null => Cookies.get(category);

/**
 * Check if cookie is active or existing
 * @param category
 */
export const isCookieActive = (category: string): boolean => !!getCookie(category);

/**
 * Set cookie
 * @param name - name of the cookie to be set
 * @param value - value to be set on the cookie
 * @param callback - callback that needs to be executed after the cookie set
 */
export const setCookie = (
  name: string,
  value: string | number | boolean,
  callback?: (() => void) | undefined
): void => {
  Cookies.set(name, value, { expires: 365, secure: false });
  localStorage.setItem(name, value.toString());

  if (callback) {
    callback();
  }
};

/**
 * Remove cookie
 * @param name - name of cookie to be removed
 */
export const removeCookie = (name: string): void => {
  Cookies.remove(name);
  localStorage.setItem(name, 'false');
};

/**
 * Save settings of current updated cookie consents and corresponding JS script
 * @param {AcceptedCookie} cookies - updated cookies with user consent
 */
export const saveSettings = (cookies: AcceptedCookie): void => {
  const cookieKeys = Object.keys(cookies);

  cookieKeys.map((category) => {
    const cookie: CookieContent = cookies[category];
    const { cookieName, checked } = cookie;
    if (checked) {
      setCookie(cookieName, checked);
    } else {
      removeCookie(cookieName);
    }
  });
};

/**
 * Check if there is existing cookies already
 * @param cookies - cookies settings to be used as reference
 */
export const isInitial = (cookies: AcceptedCookie): boolean => {
  let isAllNull = true;
  Object.values(cookies).forEach((category) => {
    if (Cookies.get(category.cookieName) !== undefined && isAllNull === true) {
      isAllNull = false;
    }
  });
  return isAllNull;
};

/**
 * Called on first load of the app to initialize cookies on user browser
 * @param cookies - cookies settings to be used as reference
 */
export const initializeCookies = (cookies: AcceptedCookie): AcceptedCookie | null => {
  let updatedCookies = {};
  const cookieKeys = Object.keys(cookies);

  cookieKeys.forEach((key) => {
    const category = cookies[key];
    const { cookieName, checked, required } = category;
    const localStorageCookie = localStorage.getItem(cookieName);

    if ((localStorageCookie && localStorageCookie === 'true') || required) {
      updatedCookies = { ...updatedCookies, [key]: { ...category } };
    } else {
      updatedCookies = {
        ...updatedCookies,
        [key]: { ...category, checked: false },
      };
    }

    if (isCookieActive(cookieName)) {
      if (!checked) {
        removeCookie(cookieName);
      }
    }
  });

  return updatedCookies;
};

export const defaultCookieCategories = {
  necessary: {
    cookieName: 'evelan-gdpr-necessary',
    checked: true,
    disabled: true,
    required: true,
  },
  analytics: {
    cookieName: 'evelan-gdpr-analytics',
    checked: true,
    disabled: false,
    required: false,
  },
  marketing: {
    cookieName: 'evelan-gdpr-marketing',
    checked: true,
    disabled: false,
    required: false,
  },
};
