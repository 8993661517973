import { MakeContent } from '../../../theme/request-car-options/models';

export interface BrandImage {
  name: string;
  value: MakeContent;
  href: string;
  h: string | (string | null)[];
  w: string | (string | null)[];
}

export const BRANDS_LIST: BrandImage[] = [
  {
    name: 'ferrari',
    value: 'Ferrari',
    href: 'https://www.google.com',
    w: ['87px', null, null, '139px'],
    h: ['19px', null, null, '30px'],
  },
  {
    name: 'lamborghini',
    value: 'Lamborghini',
    href: 'https://www.google.com',
    w: ['111px', null, null, '176px'],
    h: ['26px', null, null, '42px'],
  },
  {
    name: 'pagani',
    value: 'Pagani',
    href: 'https://www.google.com',
    w: ['95px', null, null, '152px'],
    h: ['50px', null, null, '80px'],
  },
  {
    name: 'bugatti',
    value: 'Bugatti',
    href: 'https://www.google.com',
    w: ['95px', null, null, '152px'],
    h: ['47px', null, null, '80px'],
  },
  {
    name: 'mercedes',
    value: 'Mercedes-Benz',
    href: 'https://www.google.com',
    w: ['127px', null, null, '202px'],
    h: ['26px', null, null, '42px'],
  },
  {
    name: 'porsche',
    value: 'Porsche',
    href: 'https://www.google.com',
    w: ['118px', null, null, '189px'],
    h: ['8px', null, null, '13px'],
  },
  {
    name: 'bmw',
    value: 'BMW',
    href: 'https://www.google.com',
    w: ['50px', null, null, '80px'],
    h: ['50px', null, null, '80px'],
  },
  {
    name: 'bentley',
    value: 'Bentley',
    href: 'https://www.google.com',
    w: ['99px', null, null, '159px'],
    h: ['50px', null, null, '80px'],
  },
  {
    name: 'landrover',
    value: 'Land Rover',
    href: 'https://www.google.com',
    w: ['83px', null, null, '133px'],
    h: ['44px', null, null, '70px'],
  },
  {
    name: 'audi',
    value: 'Audi',
    href: 'https://www.google.com',
    w: ['71px', null, null, '114px'],
    h: ['44px', null, null, '71px'],
  },
  {
    name: 'aston',
    value: 'Aston Martin',
    href: 'https://www.google.com',
    w: ['118px', null, null, '189px'],
    h: ['38px', null, null, '60px'],
  },
  {
    name: 'rimac',
    value: 'Rimac',
    href: 'https://www.google.com',
    w: ['79px', null, null, '126px'],
    h: ['50px', null, null, '80px'],
  },
];
