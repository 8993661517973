import { Icon, IconProps } from '@chakra-ui/icon';
import { FC } from 'react';

const SearchIcon: FC<IconProps> = ({ children, css, ...props }) => {
  return (
    <Icon width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2267 51.44L46.16 42.4C49.0853 38.6732 50.6726 34.0711 50.6667 29.3333C50.6667 17.5513 41.1154 8 29.3333 8C17.5513 8 8 17.5513 8 29.3333C8 41.1154 17.5513 50.6667 29.3333 50.6667C34.0711 50.6726 38.6732 49.0853 42.4 46.16L51.44 55.2267C52.4771 56.2723 54.1656 56.2792 55.2112 55.2421C55.2164 55.237 55.2215 55.2318 55.2267 55.2267V55.2267C56.2723 54.1895 56.2792 52.5011 55.2421 51.4555C55.237 51.4503 55.2318 51.4451 55.2267 51.44L55.2267 51.44ZM13.3333 29.3333V29.3333C13.3333 20.4968 20.4968 13.3333 29.3333 13.3333C38.1699 13.3333 45.3333 20.4968 45.3333 29.3333C45.3333 38.1699 38.1699 45.3333 29.3333 45.3333C20.4968 45.3333 13.3333 38.1699 13.3333 29.3333L13.3333 29.3333Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SearchIcon;
