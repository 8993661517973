export const lifeIcon = (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.8438 24.375C53.9736 24.375 53.139 24.7207 52.5236 25.3361C51.9083 25.9514 51.5626 26.786 51.5626 27.6562V36.6562C51.2569 36.3006 50.878 36.0153 50.4517 35.8198C50.0255 35.6244 49.562 35.5234 49.093 35.5238C48.6241 35.5243 48.1608 35.6261 47.7349 35.8224C47.3091 36.0187 46.9307 36.3047 46.6257 36.6609L42.5335 41.4375C40.7287 42.3718 39.2165 43.7857 38.1631 45.5237C37.1097 47.2617 36.5559 49.2565 36.5626 51.2887V57.1875H38.4376V51.2887C38.4327 49.5728 38.9085 47.8898 39.811 46.4304C40.7135 44.9709 42.0067 43.7934 43.5441 43.0312C43.656 42.975 43.7554 42.8969 43.8366 42.8016L48.0497 37.8872C48.2781 37.6218 48.5986 37.453 48.9466 37.4147C49.2946 37.3765 49.6441 37.4716 49.9247 37.6809C50.0781 37.796 50.2057 37.9417 50.2996 38.1088C50.3935 38.2759 50.4516 38.4607 50.4701 38.6515C50.4886 38.8422 50.4672 39.0348 50.4072 39.2168C50.3472 39.3988 50.2499 39.5664 50.1216 39.7087L45.2316 45.3272C45.1454 45.419 45.0785 45.5273 45.035 45.6456C44.9916 45.7639 44.9724 45.8897 44.9786 46.0155C44.9849 46.1414 45.0164 46.2647 45.0714 46.3781C45.1264 46.4914 45.2037 46.5926 45.2986 46.6754C45.3936 46.7583 45.5042 46.8211 45.624 46.8602C45.7438 46.8993 45.8702 46.9139 45.9958 46.903C46.1213 46.8922 46.2434 46.8561 46.3547 46.797C46.466 46.7379 46.5642 46.657 46.6435 46.5591L53.206 39.0591C53.3563 38.887 53.4387 38.666 53.4375 38.4375V27.6562C53.4375 27.2833 53.5857 26.9256 53.8494 26.6619C54.1132 26.3982 54.4708 26.25 54.8438 26.25C55.2168 26.25 55.5744 26.3982 55.8382 26.6619C56.1019 26.9256 56.25 27.2833 56.25 27.6562V40.0369C56.2504 41.2666 55.9048 42.4716 55.2525 43.5141L47.0176 56.6906L48.6113 57.6844L56.8463 44.5088C57.6833 43.1675 58.1264 41.6179 58.125 40.0369V27.6562C58.125 26.786 57.7793 25.9514 57.164 25.3361C56.5486 24.7207 55.714 24.375 54.8438 24.375Z"
      fill="white"
    />
    <path
      d="M11.3925 57.6844L12.9862 56.6906L4.7475 43.5141C4.09529 42.4716 3.74962 41.2666 3.75 40.0369V27.6562C3.75 27.2833 3.89816 26.9256 4.16188 26.6619C4.4256 26.3982 4.78329 26.25 5.15625 26.25C5.52921 26.25 5.8869 26.3982 6.15062 26.6619C6.41434 26.9256 6.5625 27.2833 6.5625 27.6562V38.4375C6.56276 38.6645 6.64538 38.8837 6.795 39.0544L13.3575 46.5544C13.4368 46.6523 13.535 46.7332 13.6463 46.7923C13.7576 46.8514 13.8797 46.8875 14.0052 46.8983C14.1307 46.9092 14.2572 46.8946 14.377 46.8555C14.4967 46.8164 14.6074 46.7536 14.7024 46.6707C14.7973 46.5879 14.8746 46.4868 14.9296 46.3734C14.9845 46.26 15.0161 46.1367 15.0224 46.0109C15.0286 45.885 15.0094 45.7592 14.966 45.6409C14.9225 45.5226 14.8556 45.4143 14.7694 45.3225L9.87281 39.6975C9.74608 39.5548 9.65031 39.3874 9.59156 39.2059C9.5328 39.0243 9.51234 38.8326 9.53146 38.6427C9.55058 38.4529 9.60886 38.269 9.70263 38.1028C9.7964 37.9367 9.92362 37.7917 10.0763 37.6772C10.3568 37.4676 10.7064 37.3723 11.0544 37.4106C11.4025 37.4489 11.723 37.6179 11.9512 37.8834L16.1644 42.7978C16.2456 42.8931 16.345 42.9712 16.4569 43.0275C17.9947 43.7901 19.2881 44.9682 20.1904 46.4284C21.0928 47.8885 21.5682 49.5723 21.5625 51.2887V57.1875H23.4375V51.2887C23.4442 49.2565 22.8904 47.2617 21.837 45.5237C20.7836 43.7857 19.2713 42.3718 17.4666 41.4375L13.3744 36.6638C13.0696 36.3074 12.6914 36.0211 12.2656 35.8246C11.8398 35.6281 11.3766 35.526 10.9077 35.5252C10.4387 35.5245 9.97519 35.6252 9.54882 35.8205C9.12246 36.0157 8.74335 36.3008 8.4375 36.6562V27.6562C8.4375 26.786 8.0918 25.9514 7.47644 25.3361C6.86109 24.7207 6.02649 24.375 5.15625 24.375C4.28601 24.375 3.45141 24.7207 2.83606 25.3361C2.2207 25.9514 1.875 26.786 1.875 27.6562V40.0369C1.87474 41.6183 2.31916 43.1679 3.1575 44.5088L11.3925 57.6844Z"
      fill="white"
    />
    <path
      d="M48.75 19.549V8.43774C48.75 8.1891 48.6512 7.95064 48.4754 7.77482C48.2996 7.59901 48.0611 7.50024 47.8125 7.50024C42.4623 7.49939 37.2129 6.0451 32.625 3.29274L30.4819 2.0093C30.3363 1.92206 30.1697 1.87598 30 1.87598C29.8303 1.87598 29.6637 1.92206 29.5181 2.0093L27.375 3.29274C22.7871 6.0451 17.5377 7.49939 12.1875 7.50024C11.9389 7.50024 11.7004 7.59901 11.5246 7.77482C11.3488 7.95064 11.25 8.1891 11.25 8.43774V19.549C11.2579 23.8077 12.2626 28.0054 14.1835 31.8063C16.1044 35.6071 18.8884 38.9056 22.3125 41.4377L29.4469 46.6952C29.608 46.8139 29.8028 46.8779 30.0028 46.8779C30.2029 46.8779 30.3977 46.8139 30.5588 46.6952L37.6875 41.4377C41.1116 38.9056 43.8956 35.6071 45.8165 31.8063C47.7374 28.0054 48.7421 23.8077 48.75 19.549ZM30 44.7734L23.4225 39.9265C20.2352 37.5688 17.6439 34.498 15.8558 30.9596C14.0677 27.4212 13.1325 23.5135 13.125 19.549V9.36117C18.4953 9.20065 23.7345 7.6648 28.3416 4.90055L30 3.90586L31.6575 4.90055C36.2649 7.66464 41.5044 9.20048 46.875 9.36117V19.549C46.8675 23.5135 45.9323 27.4212 44.1442 30.9596C42.3561 34.498 39.7648 37.5688 36.5775 39.9265L30 44.7734Z"
      fill="white"
    />
    <path
      d="M44.9999 11.9812C44.9999 11.7504 44.9148 11.5278 44.7609 11.3558C44.6069 11.1839 44.3949 11.0748 44.1655 11.0494C39.4025 10.5168 34.8091 8.96856 30.6955 6.50906L30.4846 6.3825C30.3386 6.29453 30.1713 6.24805 30.0009 6.24805C29.8304 6.24805 29.6631 6.29453 29.5171 6.3825L29.3071 6.50812C25.4628 8.8202 21.1889 10.3272 16.7446 10.9378C16.4418 10.9791 16.1371 11.0166 15.8324 11.0503C15.603 11.0757 15.3911 11.1848 15.2371 11.3568C15.0832 11.5287 14.998 11.7514 14.998 11.9822V19.5497C15.0059 23.2204 15.8723 26.8384 17.5282 30.1145C19.184 33.3905 21.5833 36.2337 24.5343 38.4169L29.444 42.0337C29.6051 42.1524 29.7999 42.2164 29.9999 42.2164C30.2 42.2164 30.3948 42.1524 30.5559 42.0337L35.4655 38.4159C38.4166 36.233 40.8159 33.3898 42.4714 30.1137C44.127 26.8375 44.9929 23.2194 44.9999 19.5487V11.9812ZM39.3749 31.6687C38.0101 33.6853 36.3121 35.4549 34.3537 36.9019L29.9999 40.1147L25.6471 36.9075C23.6884 35.4601 21.9901 33.6902 20.6249 31.6734V30.9375C20.6264 29.6947 21.1207 28.5033 21.9995 27.6246C22.8783 26.7458 24.0697 26.2515 25.3124 26.25H34.6874C35.9302 26.2515 37.1216 26.7458 38.0003 27.6246C38.8791 28.5033 39.3734 29.6947 39.3749 30.9375V31.6687ZM43.1249 19.5441C43.1198 22.8071 42.3742 26.0264 40.9443 28.9594C40.5222 27.6305 39.6886 26.4703 38.5639 25.6462C37.4392 24.8221 36.0817 24.377 34.6874 24.375H25.3124C23.9182 24.3769 22.5608 24.822 21.4362 25.6461C20.3116 26.4702 19.4783 27.6305 19.0565 28.9594C17.6264 26.028 16.8805 22.8103 16.8749 19.5487V12.8109L17.0034 12.7931C21.5919 12.1637 26.0097 10.6286 29.9999 8.27718C34.0333 10.6388 38.4939 12.1796 43.1249 12.8109V19.5441Z"
      fill="white"
    />
    <path d="M30.9375 35.625H29.0625V37.5H30.9375V35.625Z" fill="white" />
    <path d="M20.625 14.0625H18.75V15.9375H20.625V14.0625Z" fill="white" />
    <path d="M41.25 14.0625H39.375V15.9375H41.25V14.0625Z" fill="white" />
    <path d="M20.625 21.5625H18.75V23.4375H20.625V21.5625Z" fill="white" />
    <path d="M41.25 21.5625H39.375V23.4375H41.25V21.5625Z" fill="white" />
    <path d="M24.375 29.0625H22.5V30.9375H24.375V29.0625Z" fill="white" />
    <path d="M37.5 29.0625H35.625V30.9375H37.5V29.0625Z" fill="white" />
    <path
      d="M24.375 16.875C24.375 17.9875 24.7049 19.0751 25.323 20.0001C25.9411 20.9251 26.8196 21.6461 27.8474 22.0718C28.8752 22.4976 30.0062 22.609 31.0974 22.3919C32.1885 22.1749 33.1908 21.6391 33.9775 20.8525C34.7641 20.0658 35.2999 19.0635 35.5169 17.9724C35.734 16.8812 35.6226 15.7502 35.1968 14.7224C34.7711 13.6946 34.0501 12.8161 33.1251 12.198C32.2 11.5799 31.1125 11.25 30 11.25C28.5086 11.2515 27.0787 11.8446 26.0242 12.8992C24.9696 13.9537 24.3765 15.3836 24.375 16.875ZM30 13.125C30.7417 13.125 31.4667 13.3449 32.0834 13.757C32.7001 14.169 33.1807 14.7547 33.4645 15.4399C33.7484 16.1252 33.8226 16.8792 33.6779 17.6066C33.5332 18.334 33.1761 19.0022 32.6516 19.5266C32.1272 20.0511 31.459 20.4082 30.7316 20.5529C30.0042 20.6976 29.2502 20.6234 28.5649 20.3395C27.8797 20.0557 27.294 19.5751 26.882 18.9584C26.4699 18.3417 26.25 17.6167 26.25 16.875C26.25 15.8804 26.6451 14.9266 27.3483 14.2233C28.0516 13.5201 29.0054 13.125 30 13.125Z"
      fill="white"
    />
  </svg>
);
