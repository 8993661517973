import { Box, Flex, Icon, Progress } from '@chakra-ui/react';
import Button from 'jexity/packages/components/button/Button';
import { FC, useCallback, useState } from 'react';
import { SwipeableSingle } from '../../../components/swipeable/swipeable-single/SwipeableSingle';
import { LeftArrow, RightArrow } from '../../../theme/icons';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';
import { ChooseMakeContent } from '../choose-make/ChooseMakeContent';
import { ChooseModelContent } from '../choose-model-content/ChooseModelContent';
import { ContactForm } from '../contact-form/ContactForm';
import { PickBudgetContent } from '../pick-budget-content/PickBudgetContent';
import { PickCubicContent } from '../pick-cubic-content/PickCubicContent';
import { PickKilometerContent } from '../pick-kilometer-content/PickKilometerContent';

const SWIPEABLE_CONTENT_COUNT = 6;
const PROGRESS_INITIAL_VALUE = 100 / SWIPEABLE_CONTENT_COUNT;

export const SliderContainer: FC = () => {
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { sliderBackButton, sliderNextButton } = requestCarPage?.requestCarFormSection ?? {};
  const [activeItem, setActiveItem] = useState(0);

  const onClickNext = useCallback(() => {
    setActiveItem((prev) => prev + 1);
  }, [setActiveItem]);

  const onClickBack = useCallback(() => {
    setActiveItem((prev) => prev - 1);
  }, [setActiveItem]);

  return (
    <>
      <SwipeableSingle
        userSelect="none"
        activeIndex={activeItem}
        setActiveIndex={setActiveItem}
        mb={activeItem === SWIPEABLE_CONTENT_COUNT - 1 ? '2rem' : 0}
      >
        <ChooseMakeContent onSelectLogo={onClickNext} />
        <ChooseModelContent />
        <PickBudgetContent />
        <PickKilometerContent />
        <PickCubicContent />
        <ContactForm />
      </SwipeableSingle>
      <Flex
        mt="2.6875rem"
        justifyContent="center"
        display={activeItem === 5 || activeItem === 0 ? 'none' : 'flex'}
        mb="2rem"
      >
        <Button mr="1.8125rem" d={activeItem === 0 ? 'none' : 'block'} backgroundColor="gray.900" onClick={onClickBack}>
          <Icon as={LeftArrow} mr={2} width={['16px']} height={['14px']} />
          {sliderBackButton}
        </Button>
        <Button onClick={onClickNext}>
          {sliderNextButton}
          <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
        </Button>
      </Flex>
      <Box pos="absolute" bottom={0} left={0} right={0}>
        <Progress value={PROGRESS_INITIAL_VALUE * (activeItem + 1)} size="sm" colorScheme="red" />
      </Box>
    </>
  );
};
