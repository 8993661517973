import { Box, Heading, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import { RequestCarContactUsForm } from '../../../components/request-car-contact-us-form/RequestCarContactUsForm';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';

export const ContactForm: FC = () => {
  const { headings, paragraph } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { contactFormTitle, contactFormSubtitle } = requestCarPage?.requestCarFormSection ?? {};

  return (
    <Box minH="420px" maxWidth={545} margin="0 auto">
      <Box maxWidth={545} textAlign={'center'} margin={'0 auto'} mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {contactFormTitle}
        </Heading>
        <Text {...paragraph?.body4} mt={4}>
          {contactFormSubtitle}
        </Text>
      </Box>
      <RequestCarContactUsForm />
    </Box>
  );
};
