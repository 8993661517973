import React, { FC } from 'react';
import { Box, Heading, Icon, Text } from '@chakra-ui/react';

import { AnimatePresence } from 'framer-motion';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { MotionBox } from 'jexity/packages/components/motion/Motion';
import { reponseWrapperVariants } from 'jexity/packages/components/form/formApi';
import Button from 'jexity/packages/components/button/Button';
import { RightArrow } from '../../../theme/icons';
import { CloseIcon } from '@chakra-ui/icons';

export interface FormServerErrorProps {
  serverError?: boolean;
  reset?: any;
  translations: {
    title: string;
    subtitle: string;
    buttonText: string;
  };
}

const FormServerError: FC<FormServerErrorProps> = ({ serverError = false, reset, translations }) => {
  const { paragraph, formStyles } = useBaseTheme();
  const showFormBtnStyles = formStyles?.response?.showFormBtn;
  const responseStyles = formStyles?.response as any;
  const { title, subtitle, buttonText } = translations;

  return (
    <AnimatePresence>
      {serverError && (
        <MotionBox
          key="form-server-error"
          zIndex={2}
          pos="absolute"
          top="100%"
          left={0}
          d="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          mr="auto"
          ml="auto"
          w="100%"
          h="100%"
          p="0 1.25em"
          bg="white"
          textAlign="center"
          variants={reponseWrapperVariants}
          initial="hidden"
          animate="show"
          exit="hidden"
          {...responseStyles?.wrapper}
        >
          <Box
            d="flex"
            justifyContent="center"
            alignItems="center"
            mr="auto"
            ml="auto"
            w="100px"
            h="100px"
            minH="100px"
            borderRadius="50%"
            backgroundColor="red.400"
          >
            <Icon as={CloseIcon} boxSize="50px" color="white" />
          </Box>
          <Heading mt="2rem" as="h3" {...responseStyles?.serverError}>
            {title}
          </Heading>
          <Text mt="2rem" {...paragraph?.body4}>
            {subtitle}
          </Text>
          <Button
            onClick={() => {
              reset();
            }}
            {...showFormBtnStyles}
          >
            {buttonText}
            <Icon ml={2} as={RightArrow} w="16px" h="14px" />
          </Button>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default FormServerError;
