import { Container } from 'jexity/packages/components/container/Container';
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';
import { FC } from 'react';
import NextImage from 'next/image';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';

const FreeSpots: FC = () => {
  const theme = useBaseTheme();
  const { translations } = useTranslationsContext();
  const freeSpots = translations?.vehicleStoragePage.freeSpotsSection;

  return (
    <Box pos="relative" zIndex={1} pb={[12, null, 16, 20]} pt={40}>
      <Container pos="relative" zIndex="2">
        <Heading as="h3" {...theme.headings?.h3} mb=".5rem">
          {freeSpots?.title}
        </Heading>
        <Text {...theme.paragraph?.body4} mb=".5rem">
          {freeSpots?.content}
        </Text>
        <Heading as="h5" {...theme.headings?.h5}>
          &quot;{freeSpots?.testimonial}!&quot;
        </Heading>

        <Box h="4rem" />

        <SimpleGrid gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="32px" alignItems="center">
          <Box>
            <Box w="100%" bg="#0F0F0F">
              <NextImage src="/assets/svg/lager1.svg" alt="Lager 1" layout="responsive" width={543} height={516} />
            </Box>
            <Heading {...theme.headings?.h5} textAlign="center" mt="0.6875rem">
              {freeSpots?.lager} 1
            </Heading>
          </Box>

          <Box>
            <Box w="100%" bg="#0F0F0F">
              <NextImage src="/assets/svg/lager2.svg" alt="Lager 2" layout="responsive" width={543} height={516} />
            </Box>
            <Heading {...theme.headings?.h5} textAlign="center" mt="0.6875rem">
              {freeSpots?.lager} 2
            </Heading>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default FreeSpots;
