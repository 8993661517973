import { AspectRatio, Box, Button, Heading, SimpleGrid, Link, Flex } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { Bullet } from 'jexity/packages/components/bullet/Bullet';
import { Container } from 'jexity/packages/components/container/Container';
import React, { FC, useState } from 'react';
import { isSanityImageAsset, PictureAsset } from 'sanity-picture-asset';
import { SwipeableSingle } from 'src/components/swipeable/swipeable-single/SwipeableSingle';
import { RightArrow } from 'src/theme/icons';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';
import { BaseContentPageProps } from '../../contentPageSerializer';
import { ImpressionsData, ImpressionsDocument } from './impressions';
import Floor from 'src/components/Floor';
import VideoWithPreview from 'jexity/packages/components/block-content/video-with-preview/VideoWithPreview';
import BlockTextSection from 'src/sections/block-text-section/BlockTextSection';

type ImpressionSlideProps = NonNullable<ImpressionsData['impressions']>[number];
const ImpressionSlide: FC<ImpressionSlideProps> = ({ title, description, video }) => {
  const { headings } = useBaseTheme();
  const previewImage = video.video.previewImage;
  return (
    <SimpleGrid
      gridTemplateColumns={['1fr', null, null, '1fr 1fr']}
      gridColumnGap="34px"
      gridRowGap={6}
      userSelect="none"
    >
      <AspectRatio ratio={16 / 9} mb={4}>
        {isSanityImageAsset(previewImage) && <VideoWithPreview previewAsset={previewImage} video={video.video} />}
      </AspectRatio>
      <Box>
        <Heading as="h3" {...headings?.h3} mb={4}>
          {title}
        </Heading>
        {description && <BlockContent blocks={description} />}
      </Box>
    </SimpleGrid>
  );
};

const ImpressionsPage: FC<BaseContentPageProps<ImpressionsDocument>> = ({ content }) => {
  const { headerHeight, headings, buttonStyle, linkStyle } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const { impressionsPage: t } = translations ?? {};
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const { title, channelIcon, channelName, channelUrl, impressions = [], featuredVideosCount } = content;

  const slides = impressions.slice(0, featuredVideosCount);
  const latestVids = showMore ? impressions : impressions.slice(0, 6); // Prevent JSX from rendering too much but uses CSS to perfectly cut the elements to two rows only

  return (
    <Box pos="relative" pt={headerHeight} pb={12}>
      <Box mb="63px">
        <BlockTextSection data={{ title }} />
      </Box>
      <Container>
        {/* Page title */}

        {/* Youtube details */}
        <SimpleGrid
          alignItems="center"
          columnGap={6}
          gridTemplateColumns={['min-content 1fr', 'min-content 1fr min-content']}
          mb={12}
        >
          <Box w="65px">
            <PictureAsset isFluid image={channelIcon} />
          </Box>
          <Heading as="h4" {...headings?.h4}>
            {channelName}
          </Heading>
          <Link href={channelUrl} target="_blank" {...linkStyle} gridColumn={['2', 'initial']}>
            <Button rightIcon={<RightArrow />} {...buttonStyle}>
              {t?.subscribeLabel}
            </Button>
          </Link>
        </SimpleGrid>

        {/* Slider */}
        <Box pos="relative" mb={8}>
          <Box pos="relative" zIndex={2}>
            <SwipeableSingle activeIndex={activeIndex} setActiveIndex={setActiveIndex} shouldDrag>
              {slides.map((impression, index) => {
                return <ImpressionSlide key={`slide-${index}`} {...impression} />;
              })}
            </SwipeableSingle>
            <Bullet setActiveItem={(index) => setActiveIndex(index)} activeItem={activeIndex} data={slides} mt="2rem" />
          </Box>

          <Floor
            pos="absolute"
            w={['400vw', null, null, '150vw']}
            maxW="3200px"
            h="auto"
            bottom="0"
            transform="translateY(50%)"
          />
        </Box>

        {/* Latest videos */}
        <Box pos="relative" mb="56px" zIndex={2}>
          <Heading as="h2" {...headings?.h2}>
            {t?.latestVideosLabel}
          </Heading>
        </Box>

        <SimpleGrid
          position="relative"
          gridTemplateColumns={['repeat(auto-fill, minmax(300px, 1fr))', null, null, null, '1fr 1fr 1fr']}
          gridTemplateRows="repeat(2, 1fr)"
          gridAutoRows={showMore ? 'auto' : 0}
          overflowY="hidden"
          justifyContent="space-between"
          zIndex={2}
          mb={10}
          mx="-16px"
        >
          {latestVids.map((impression, i) => (
            <Box key={i} overflow="hidden" px="16px" mb="18px">
              <AspectRatio ratio={16 / 9} mb={4}>
                <VideoWithPreview previewAsset={impression.video.video.previewImage} video={impression.video.video} />
              </AspectRatio>
              <Heading as="h6" {...headings?.h6}>
                {impression.title}
              </Heading>
            </Box>
          ))}
        </SimpleGrid>
        <Flex justifyContent="center">
          {!showMore && (
            <Button
              {...buttonStyle}
              onClick={() => {
                setShowMore(true);
              }}
            >
              {t?.loadMoreButtonLabel}
            </Button>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default ImpressionsPage;
