export const phone = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6 13.6667H10.6C4.9329 13.6593 0.340646 9.06707 0.333313 3.39999C0.333313 1.70632 1.70631 0.333328 3.39998 0.333328V0.333328C3.57221 0.332016 3.74414 0.347647 3.91331 0.379995L3.91331 0.379995C4.07685 0.404195 4.23762 0.444387 4.39331 0.499994V0.499994C4.61611 0.578165 4.78093 0.768351 4.82664 0.999994L5.73998 4.99999C5.79 5.2206 5.72474 5.45153 5.56664 5.61333C5.47998 5.70666 5.47331 5.71333 4.65331 6.13999C5.30997 7.58057 6.46211 8.73743 7.89998 9.39999C8.33331 8.57333 8.33998 8.56666 8.43331 8.47999C8.59511 8.3219 8.82603 8.25664 9.04664 8.30666L13.0466 9.21999C13.2707 9.27197 13.4521 9.43576 13.5266 9.65333C13.5829 9.81157 13.6253 9.9744 13.6533 10.14C13.6801 10.3075 13.6935 10.477 13.6933 10.6467V10.6467C13.6677 12.3325 12.286 13.6815 10.6 13.6667L10.6 13.6667ZM3.39998 1.66666C2.44421 1.67032 1.6703 2.44422 1.66665 3.4L1.66665 3.39951C1.67005 8.33173 5.66727 12.3294 10.5995 12.3333C11.5553 12.3297 12.3292 11.5557 12.3328 10.6V10.38L9.24001 9.66664L9.04667 10.0333C8.74667 10.6133 8.52667 11.0333 7.96667 10.8066C5.72942 10.0059 3.97146 8.24055 3.18001 5.99997C2.94001 5.47997 3.39334 5.23997 3.96667 4.93997L4.33334 4.75997L3.62001 1.66664L3.39998 1.66666Z"
      fill="currentColor"
    />
  </svg>
);
