export const galleryFullView = (
  <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9993 23.6665C26.7357 23.6665 27.3327 24.2635 27.3327 24.9998C27.3327 25.7362 26.7357 26.3332 25.9993 26.3332H1.99935C1.26297 26.3332 0.666016 25.7362 0.666016 24.9998C0.666016 24.2635 1.26297 23.6665 1.99935 23.6665H25.9993ZM23.1 0C25.2539 0 27 2.35051 27 5.25V15.75C27 18.6495 25.2539 21 23.1 21H4.9C2.74609 21 1 18.6495 1 15.75V5.25C1 2.35051 2.74609 0 4.9 0H23.1ZM23.625 2H4.375C3.61561 2 3 2.95139 3 4.125V16.875C3 18.0486 3.61561 19 4.375 19H23.625C24.3844 19 25 18.0486 25 16.875V4.125C25 2.95139 24.3844 2 23.625 2Z"
      fill="currentColor"
    />
  </svg>
);
