import React, { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Box } from '@chakra-ui/react';
import { isSanityImageAsset, PictureAsset, SanityImage } from 'sanity-picture-asset';

import { useSlideshow } from './useSlideshow';
import SlideshowPagination from './slideshow-pagination/SlideshowPagination';

const FullScreenSlideShow = dynamic(() => import('./FullScreenSlideshow'));
const ImageGalleryGrid = dynamic(() => import('../image-gallery/ImageGalleryGrid'));
const SwipeableSingle = dynamic(() =>
  import('../swipeable/swipeable-single/SwipeableSingle').then((mod) => mod.SwipeableSingle)
);

export interface SlideshowProps {
  layout: 'grid' | 'slideshow';
  previewLimit: number;
  slides: SanityImage[];
  loadFullScreenSlideshowOnClick?: boolean;
  showCaptionOnFullScreen?: boolean;
  imgStyles?: {
    height: string;
    width: string;
  };
}

const Slideshow: FC<SlideshowProps> = (props) => {
  const {
    slides = [],
    previewLimit = 4,
    layout = 'slideshow',
    loadFullScreenSlideshowOnClick = false,
    showCaptionOnFullScreen = false,
    imgStyles,
  } = props;
  const [renderGallery, setRenderGallery] = useState<boolean>(!loadFullScreenSlideshowOnClick);

  const { galleryIndex, setGalleryIndex, fullscreen, setFullscreen, onImageClick, slides: mappedSlides } = useSlideshow(
    slides
  );

  useEffect(() => {
    if (fullscreen) {
      setRenderGallery(true);
    }
  }, [fullscreen, setRenderGallery]);

  return (
    <Box my={[4, null, 6]}>
      {layout === 'grid' && (
        <ImageGalleryGrid
          slides={mappedSlides}
          onImageClick={onImageClick}
          previewLimit={previewLimit}
          imgStyles={imgStyles}
        />
      )}

      {layout === 'slideshow' && (
        <Box pos="relative" data-type-section="slider-wrapper">
          <Box pos="relative">
            <SwipeableSingle
              shouldDrag={true}
              spacing={0}
              activeIndex={galleryIndex}
              setActiveIndex={setGalleryIndex}
              skipHeightAnimation={true} // We don't need this since we always have a fixed aspect ratio for slides
              boxShadow="5px 10px 30px rgba(0,0,0,0.15)"
            >
              {slides.map((slide, idx) => {
                const id = slide.asset._id;
                return (
                  <Box
                    key={id}
                    userSelect="none"
                    cursor="pointer"
                    pos="relative"
                    width="100%"
                    onClick={() => onImageClick(idx)}
                  >
                    {isSanityImageAsset(slide) && <PictureAsset image={slide} isFluid={true} />}
                  </Box>
                );
              })}
            </SwipeableSingle>
          </Box>
          {slides.length > 1 && (
            <SlideshowPagination
              setActiveItem={(index) => setGalleryIndex(index)}
              activeItem={galleryIndex}
              data={slides}
            />
          )}
        </Box>
      )}

      {renderGallery && (
        <FullScreenSlideShow
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          galleryIndex={galleryIndex}
          slides={mappedSlides}
          onImageClick={onImageClick}
          showCaption={showCaptionOnFullScreen}
        />
      )}
      <style jsx global>{`
        .image-gallery-thumbnails {
          overflow: visible;
        }
      `}</style>
    </Box>
  );
};

export default Slideshow;
