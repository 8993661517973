export const orderProcessCalendar = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 10.6666H45.3333V7.99992C45.3333 6.52716 44.1394 5.33325 42.6667 5.33325C41.1939 5.33325 40 6.52716 40 7.99992V10.6666H24V7.99992C24 6.52716 22.8061 5.33325 21.3333 5.33325C19.8606 5.33325 18.6667 6.52716 18.6667 7.99992V10.6666H16C11.5817 10.6666 8 14.2483 8 18.6666V50.6666C8 55.0849 11.5817 58.6666 16 58.6666H48C52.4183 58.6666 56 55.0849 56 50.6666V18.6666C56 14.2483 52.4183 10.6666 48 10.6666ZM16 15.9999H18.6667V18.6666C18.6667 20.1393 19.8606 21.3333 21.3333 21.3333C22.8061 21.3333 24 20.1393 24 18.6666V15.9999H40V18.6666C40 20.1393 41.1939 21.3333 42.6667 21.3333C44.1394 21.3333 45.3333 20.1393 45.3333 18.6666V15.9999H48C49.4728 15.9999 50.6667 17.1938 50.6667 18.6666V29.3333H13.3333V18.6666C13.3333 17.1938 14.5272 15.9999 16 15.9999ZM13.3333 50.6666C13.3333 52.1393 14.5272 53.3332 16 53.3332H48C49.4728 53.3332 50.6667 52.1393 50.6667 50.6666V34.6666H13.3333V50.6666ZM23.2189 40.781C24.2603 41.8224 24.2603 43.5108 23.2189 44.5522C22.1776 45.5936 20.4891 45.5936 19.4477 44.5522C18.4063 43.5108 18.4063 41.8224 19.4477 40.781C20.4891 39.7396 22.1776 39.7396 23.2189 40.781ZM32 39.9999H42.6667C44.1394 39.9999 45.3333 41.1938 45.3333 42.6666C45.3333 44.1393 44.1394 45.3333 42.6667 45.3333H32C30.5272 45.3333 29.3333 44.1393 29.3333 42.6666C29.3333 41.1938 30.5272 39.9999 32 39.9999Z"
      fill="currentColor"
    />
  </svg>
);
