import { Box, Heading, Select, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { DEFAULT_LANGUAGE } from '../../../theme/languages';
import { MODELS_OPTION_DE, MODELS_OPTION_EN, ModelsOptions } from '../../../theme/request-car-options/models';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';
import { RequestCarContext } from 'src/feature/page/content/request-car/requestCarPage';
import { BrandIcon } from 'src/components/brands-list/BrandsList';

export const ChooseModelContent: FC = () => {
  const { headings, paragraph } = useBaseTheme();
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const [activeModel, setActiveModel] = useState('');
  const { formAdditionalValues, setFormAdditionalValues } = useFormContext();
  const { make } = formAdditionalValues;
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { chooseModelLabel, chooseModelTitle } = requestCarPage?.requestCarFormSection ?? {};
  const { brand } = useContext(RequestCarContext);

  // TODO - Fix the usage of `array[index]` it is almost never typesafe
  let options: string[] | undefined = undefined;

  if (make !== undefined) {
    options =
      locale === 'de' ? MODELS_OPTION_DE[make as keyof ModelsOptions] : MODELS_OPTION_EN[make as keyof ModelsOptions];
  }

  useEffect(() => {
    if (options && options.length > 0) {
      const model = options[0];
      setFormAdditionalValues((prevForm) => ({ ...prevForm, model }));
    }
  }, [options, setFormAdditionalValues]);

  return (
    <Box minH="420px" maxWidth={545} textAlign={'center'} margin={'0 auto'}>
      <Box mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {chooseModelTitle}
        </Heading>
      </Box>

      {brand && (
        <Box mb="2rem">
          <BrandIcon name={brand.toLowerCase()} />
        </Box>
      )}

      <Box>
        <Text {...paragraph?.body4} fontWeight={700} textAlign="left">
          {brand} {chooseModelLabel}
        </Text>

        <Select
          minW="256px"
          onChange={(event) => {
            setActiveModel(event.target.value);
            setFormAdditionalValues((prevForm) => ({ ...prevForm, model: event.target.value }));
          }}
          fontSize="md"
          mt={2}
          color="gray.900"
          size="lg"
          borderRadius={0}
          icon={<ChevronDownIcon />}
          value={activeModel}
          border="1px solid #A1A1A1"
          bg="white"
        >
          {options?.map((option, idx) => (
            <option key={`model-idx-options-${idx}`} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
