import { theme as chakraTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { baseTheme, BaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Layout } from '../feature/layout/Layout';
import { iconSerializer } from './icons';
import { sectionsSerializer } from './serializers/sectionsSerializer';
import { blockContentSerializer } from './serializers/blockContentSerializer';
import { containerVariants } from './component-styles/container';
import { formStyles } from './component-styles/formStyles';
import { sectionsStyles } from './component-styles/sectionsStyles';
import { bulletListSerializer } from './component-styles/bulletListSerializer';
import { ColorHues } from '@chakra-ui/theme';

const theme: BaseTheme = {
  ...baseTheme,

  breakpoints: createBreakpoints({
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1366px',
    '3xl': '1600px',
    '4xl': '1920px',
  }),

  fontSizes: {
    ...baseTheme.fontSizes,
    xs: '0.75rem', //12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.25rem', // 20px
    xl: '1.5rem', // 24px
    '2xl': '1.75rem', // 28px
    '3xl': '2rem', // 32px
    '4xl': '2.5rem', // 40px
    '5xl': '2.75rem', // 44px
    '6xl': '3rem', //48px
    '7xl': '3.25rem', // 52px
    '8xl': '3.5rem', // 56px
    '9xl': '6rem', // 96px
  },

  colors: {
    ...baseTheme.colors,

    gray: {
      ...baseTheme.colors.gray,
      900: '#191919',
      800: '#656565',
      500: '#A1A1A1',
      400: '#C7C7C7',
      200: '#E4E4E4',
      100: '#F1F1F1',
    },

    brand: {
      ...baseTheme.colors.brand,
      bodyBackground: '#000000',
      textColor: '#ffffff',
      paragraphColor: '#F1F1F1',
      primary: {
        900: '#44060F',
        800: '#880B1D',
        600: '#060606',
        500: '#E21331',
        400: '#FB3350',
        300: '#FB3350',
        200: '#EE7183',
        100: '#F6B8C1',
      },
      secondary: {
        900: '#0F0F0F',
        800: '#080E19',
        500: '#081328',
        400: '#10244A',
        200: '#2A4B89',
        100: '#5F83C9',
      },
      tertiary: {
        900: '#473307',
        800: '#A57710',
        500: '#EBAA17',
        400: '#FAD728',
        300: '#50BB88',
        200: '#F3CC74',
        100: '#F9E5B9',
      },
    },

    button: {
      ...baseTheme.colors.button,
      primary: {
        500: '#00869D ',
      },
    },

    support: {
      success: {
        100: '#086343',
        50: '#008556',
        5: '#D6F3E2',
      },
      warning: {
        100: '#A64F21',
        50: '#E86825',
        5: '#FFE1BE',
      },
      error: {
        100: '#9F1B1F',
        50: '#DE1C22',
        5: '#F9D3D4',
      },
    } as {
      [key: string]: Partial<ColorHues>;
    },

    gradient: {
      100: 'linear-gradient(90deg, #00869D 0%, #007C8C 50%, #007179 100%)',
    },
  },

  gridGap: ['2em', null, null, null, null, null, '40px'],

  fonts: {
    ...baseTheme.fonts,
    heading: `Roboto Condensed, ${chakraTheme.fonts.heading}`,
    body: `Roboto, ${chakraTheme.fonts.body}`,
  },

  headerHeight: [79, 100],

  headings: {
    h1: {
      fontSize: ['5xl', null, '7xl', null, '8xl'],
      lineHeight: 1.25,
      fontFamily: 'heading',
      fontWeight: 400,
      color: 'white',
    },
    h2: {
      fontSize: ['4xl', null, '5xl', '6xl'],
      fontFamily: 'heading',
      lineHeight: 1.25,
      fontWeight: 400,
      color: 'white',
    },
    h3: {
      fontSize: ['3xl', null, '4xl'],
      fontFamily: 'heading',
      color: 'white',
      lineHeight: 1.25,
      fontWeight: 400,
    },
    h4: {
      fontSize: ['xl', null, '3xl'],
      fontFamily: 'heading',
      fontWeight: 400,
      color: 'white',
      lineHeight: 1.25,
    },
    h5: {
      fontSize: ['lg', null, 'xl', '2xl'],
      fontFamily: 'heading',
      color: 'white',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: ['md', null, 'lg'],
      fontFamily: 'heading',
      color: 'white',
      fontWeight: 400,
      lineHeight: 1.25,
    },
  },

  paragraph: {
    ...baseTheme.paragraph,
    body2: {
      fontSize: 'xs',
      fontWeight: 400,
      color: 'brand.paragraphColor',
      lineHeight: 1.7,
      fontFamily: 'body',
    },
    body3: {
      fontSize: 'sm',
      fontWeight: 400,
      color: 'brand.paragraphColor',
      lineHeight: 1.6,
      fontFamily: 'body',
    },
    body4: {
      fontSize: 'md',
      fontWeight: 400,
      color: 'brand.paragraphColor',
      lineHeight: 1.5,
      fontFamily: 'body',
    },
    body5: {
      fontSize: 'lg',
      fontWeight: 400,
      color: 'brand.paragraphColor',
      lineHeight: 1.4,
      fontFamily: 'body',
    },
    body7: {
      fontSize: 'sm',
      color: 'brand.paragraphColor',
      fontWeight: 'normal',
      lineHeight: 'base',
      fontFamily: 'body',
    },
  },

  icons: { ...baseTheme.icons, ...iconSerializer },

  buttonStyle: {
    backgroundColor: 'brand.primary.500',
    letterSpacing: '2px',
    height: 'auto',
    color: 'white',
    borderRadius: 0,
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: 'short',
    py: '12px',
    px: '18px',
    h: 'auto',
    transition: '0.4s ease-out',
    whiteSpace: 'normal',
    rounded: 0,
    _hover: {
      backgroundColor: 'brand.primary.800',
      outline: 0,
    },
    _active: {
      backgroundColor: 'brand.primary.900',
      outline: 0,
    },
    _disabled: {
      backgroundColor: 'gray.900',
      color: 'gray.800',
      outline: 0,
    },
  },

  blockContentSerializer,

  formStyles,

  layoutComponent: Layout,

  sectionsSerializer,

  sections: sectionsStyles,

  containerVariants,

  bulletListSerializer,

  linkStyle: {
    fontFamily: 'heading',
    color: 'white',
    letterSpacing: '2px',
    fontSize: 'md',
    lineHeight: '1.25rem',
    transition: 'color .4s ease-out',
    _hover: { color: 'brand.primary.500' },
    _disabled: { color: 'gray.900' },
  },
};

export default theme;
