import { Flex, Grid, Link, Box } from '@chakra-ui/react';
import { MenuItem } from 'jexity/packages/components/header/headerApi';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { getInternalLink, useSiteSettingsContext } from 'jexity/packages/feature/common';
import { DEFAULT_LANGUAGE } from 'src/theme/languages';

interface FooterProps {
  footerLinks: MenuItem[];
}

export const FooterLinks: FC<FooterProps> = () => {
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { siteSettings } = useSiteSettingsContext();
  const { footerNav } = siteSettings;

  const getLink = (menuItem) => {
    return getInternalLink({
      startPageId: siteSettings.startPage?._id,
      pageId: menuItem.reference._id,
      _type: 'page',
      slug: menuItem.reference.slug,
    });
  };

  return (
    <Grid
      justifyContent="flex-end"
      columnGap={['2rem', '1.25rem']}
      rowGap={'2.5rem'}
      templateColumns={['1fr 1fr', null, 'repeat(4, 1fr)', null, 'repeat(8, max-content)']}
    >
      {footerNav?.map((menuItem, i) => (
        <Box key={i}>
          {menuItem.reference && (
            <Flex flexWrap={['nowrap']}>
              <NextLink key={i} {...getLink(menuItem)} locale={locale} passHref>
                <Link
                  fontSize="md"
                  textDecor="none"
                  transition=".4s ease-out"
                  _hover={{ color: 'brand.primary.500' }}
                  _focus={{ boxShadow: 'none' }}
                  textTransform="uppercase"
                  fontFamily="heading"
                  fontWeight={500}
                  letterSpacing="2px"
                  sx={{
                    hyphens: 'auto',
                  }}
                  _even={{
                    ml: [2, null, null, 0],
                  }}
                >
                  {menuItem.label}
                </Link>
              </NextLink>
            </Flex>
          )}
        </Box>
      ))}
    </Grid>
  );
};
