import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import BlockContentSection from '../../../../sections/block-content-section/BlockContentSection';
import { BaseContentPageProps } from '../contentApi';
import { ImpressumDocument } from './impressum';

const ImpressumPage: FC<BaseContentPageProps<ImpressumDocument>> = ({ content: contentProps }) => {
  const { content, layout } = contentProps ?? {};
  const { headerHeight } = useBaseTheme();

  return (
    <Box pt={headerHeight} pb={[12, null, 16, 20]}>
      {!!content && <BlockContentSection data={{ content, layout }} />}
    </Box>
  );
};

export default ImpressumPage;
