import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import BlockTextImageSection from '../../../../sections/block-text-image-section/BlockTextImageSection';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import CtaSection from '../../../../sections/cta-section/CtaSection';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import { InvestitionDocument } from './investition';
import { BaseContentPageProps } from '../contentApi';

const InvestitionPage: FC<BaseContentPageProps<InvestitionDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();
  const { title, blockTextImageSection1, blockTextImageSection2, blockTextImageSection3, ctaSection } = content ?? {};

  return (
    <Box pt={headerHeight}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/investition.png"
        mobileImage="/assets/png/pages/investition-mobile.png"
      />
      {title && <BlockTextSection data={{ title }} />}
      {blockTextImageSection1 && <BlockTextImageSection data={blockTextImageSection1} />}
      {blockTextImageSection2 && <BlockTextImageSection data={blockTextImageSection2} />}
      {blockTextImageSection3 && <BlockTextImageSection data={blockTextImageSection3} />}
      {ctaSection && <CtaSection data={ctaSection} />}
    </Box>
  );
};

export default InvestitionPage;
