import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { CarData } from '../../feature/page/content/cars-page/cars';
import { formatDate } from 'jexity/packages/utils/index';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { CarInformationRow } from './CarInformationRow';
import { SetRequired } from 'type-fest';

export type CarInformationListProps = BoxProps &
  SetRequired<Partial<CarData>, 'availablityPurchase' | 'firstRegistration' | 'yearConstruction'>;

export const CarInformationList: FC<CarInformationListProps> = ({
  availablityPurchase,
  firstRegistration,
  yearConstruction,
  mileage,
  power,
  gearBox,
  condition,
  ...boxProps
}) => {
  const { translations } = useTranslationsContext();
  const {
    availabilityLabel,
    availabilityDateFormat,
    availabilityPastFallbackLabel,
    firstRegistrationLabel,
    firstRegistrationDateFormat,
    constructionYearLabel,
    mileageLabel,
    powerLabel,
    gearboxLabel,
    conditionLabel,
  } = translations?.carsPage ?? {};

  const isAvailablityPurchasePast = new Date() > new Date(availablityPurchase);
  const availabilityValue = isAvailablityPurchasePast
    ? availabilityPastFallbackLabel ?? 'Sofort'
    : formatDate(availablityPurchase, availabilityDateFormat ?? 'dd.MM.yyyy');

  return (
    <Box {...boxProps}>
      <CarInformationRow label={availabilityLabel ?? 'Verfügbarkeit:'} value={availabilityValue} />
      <CarInformationRow
        label={firstRegistrationLabel ?? 'Erstzulassung:'}
        value={formatDate(firstRegistration, firstRegistrationDateFormat ?? 'MM.yyyy')}
      />
      <CarInformationRow label={constructionYearLabel ?? 'Baujahr:'} value={yearConstruction.toString()} />
      <CarInformationRow label={mileageLabel ?? 'Kilometerstand:'} value={mileage ?? ''} />
      <CarInformationRow label={powerLabel ?? 'Motor:'} value={power ?? ''} />
      <CarInformationRow label={gearboxLabel ?? 'Getriebe:'} value={gearBox ?? ''} />
      <CarInformationRow label={conditionLabel ?? 'Zustand:'} value={condition ?? ''} />
    </Box>
  );
};
