import React from 'react';
import { FormFieldSerializer } from 'jexity/packages/components/form/formApi';
import StringField from './fields/StringField';
import MultilineStringField from './fields/MultilineStringField';
import EmailField from './fields/EmailField';
import { BoxProps, FormLabelProps, InputProps } from '@chakra-ui/react';
import CheckboxField from './fields/CheckboxField';
import theme from '../../theme/theme';

export const customFormFieldSerializer: FormFieldSerializer = {
  stringFormField: (props) => <StringField {...props} />,
  multilineStringFormField: (props) => <MultilineStringField {...props} />,
  emailFormField: (props) => <EmailField {...props} />,
  checkboxFormField: (props) => <CheckboxField {...props} />,
};

type CustomFormFieldStyle = {
  labelStyle: FormLabelProps;
  requiredStyle: BoxProps;
  fieldStyle: InputProps;
};

export const customFormFieldStyles: CustomFormFieldStyle = {
  labelStyle: {
    ...(theme.paragraph?.body4 as FormLabelProps),
    fontWeight: 700,
  },
  requiredStyle: {
    color: 'brand.secondary.900',
    fontSize: 'md',
    fontWeight: 500,
  },
  fieldStyle: {
    ...(theme.paragraph?.body4 as InputProps),
    p: '16px 16px',
    h: '56px',
    color: 'gray.900',
    borderRadius: 0,
    fontWeight: 400,
    autoComplete: 'off',
    w: 'calc(100% - 2px)',
    ml: '1px',
  },
};
