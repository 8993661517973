import { Box } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';
import { BlurBackgroundImage } from '../../../../components/blur-background-image/BlurBackgroundImage';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import ReferenceSection from '../../../../sections/reference-section/ReferenceSection';
import { BaseContentPageProps } from '../contentApi';
import { ReferenceDocument } from './reference';

const ReferencePage: FC<BaseContentPageProps<ReferenceDocument>> = ({ content }) => {
  const { headerHeight } = useBaseTheme();

  const { title, referenceSections } = content ?? {};
  return (
    <Box pt={headerHeight} pb={[12, null, 16, 20]}>
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/reference.png"
        mobileImage="/assets/png/pages/reference-mobile.png"
      />
      {title && <BlockTextSection data={{ title }} />}
      {Array.isArray(referenceSections) && <ReferenceSection data={referenceSections} />}
    </Box>
  );
};

export default ReferencePage;
