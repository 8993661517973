import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { FC } from 'react';

export interface CarInformationRowProps {
  label: string;
  labelStyle?: BoxProps;
  value: string;
  valueStyle?: BoxProps;
}

export const CarInformationRow: FC<CarInformationRowProps> = ({ label, labelStyle, value, valueStyle }) => {
  const { paragraph } = useBaseTheme();

  return (
    <Flex flexDir="row" alignItems="flex-start">
      <Text mr={1} {...paragraph?.body3} letterSpacing={1} color="gray.400" {...labelStyle}>
        {label}
      </Text>
      <Text {...paragraph?.body3} color="brand.textColor" letterSpacing={1} fontWeight={700} {...valueStyle}>
        {value}
      </Text>
    </Flex>
  );
};
