import { LinkProps } from 'next/link';

interface MenuListItem {
  title: {
    en: string;
    de: string;
  };
  link: LinkProps;
}

export interface LocalizedMenuListItem {
  title: string;
  link: LinkProps;
}

const PRIMARY_MENU_LIST: MenuListItem[] = [
  {
    title: {
      en: 'Home',
      de: 'Home',
    },
    link: {
      href: '/',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Fahrzeuge',
      en: 'Cars',
    },
    link: {
      href: '/cars',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Fahrzeugeinlagerung',
      en: 'Vehicle storage',
    },
    link: {
      href: '/vehicle-storage',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Bestellung',
      en: 'Order process',
    },
    link: {
      href: '/order-process',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Kommissionsverkauf',
      en: 'Commission sales',
    },
    link: {
      href: '/commission-sales',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Suchauftrag',
      en: 'Request car',
    },
    link: {
      href: '/request-car',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Referenzen',
      en: 'reference',
    },
    link: {
      href: '/reference',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Impressionen',
      en: 'Impressions',
    },
    link: {
      href: '/impressions',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Kontakt',
      en: 'Contact',
    },
    link: {
      href: '/contact-us',
      passHref: true,
    },
  },
];

const SECONDARY_MENU_LIST: MenuListItem[] = [
  {
    title: {
      en: 'About us',
      de: 'Über uns',
    },
    link: {
      href: '/about-us',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Investition',
      en: 'Investment',
    },
    link: {
      href: '/investition',
      passHref: true,
    },
  },
  {
    title: {
      de: 'Partner',
      en: 'Partners',
    },
    link: {
      href: '/partners',
      passHref: true,
    },
  },
];

export function getPrimaryMenuList(locale: string): LocalizedMenuListItem[] {
  return PRIMARY_MENU_LIST.map(({ title, link }) => ({ title: title[locale], link }));
}

export function getSecondaryMenuList(locale: string): LocalizedMenuListItem[] {
  return SECONDARY_MENU_LIST.map(({ title, link }) => ({ title: title[locale], link }));
}
