import { Box, Flex, Grid } from '@chakra-ui/react';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { isBlockContent } from 'jexity/packages/components/block-content/blockContentUtils';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import { PictureAsset } from 'sanity-picture-asset';
import { useSlideshow } from 'src/components/slideshow/useSlideshow';
import SearchIcon from 'src/theme/icons/SearchIcon';
import { ReferenceSectionData } from './reference';

const FullScreenSlideShow = dynamic(() => import('src/components/slideshow/FullScreenSlideshow'));

const ReferenceItem: FC<ReferenceSectionData> = ({ title, images }) => {
  const { galleryIndex, onImageClick, slides: mappedSlides, fullscreen, setFullscreen } = useSlideshow(images ?? []);

  return (
    <Grid gridTemplateRows="max-content 1fr" alignItems="flex-end">
      <Box
        px={{
          base: '1.25rem',
          sm: 0,
        }}
      >
        {isBlockContent(title) && <BlockContent blocks={title} />}
      </Box>

      {images?.[0] && (
        <Box mt={8} pos="relative" onClick={() => setFullscreen(true)} cursor="pointer">
          <PictureAsset image={images[0]} isFluid={true} alt={images[0].alt ?? 'Sportwagen'} />
          <Flex
            pos="absolute"
            top="0"
            left="0"
            h="100%"
            w="100%"
            bgColor="rgba(198, 17, 44, 0.7)"
            alignItems="center"
            justifyContent="center"
            zIndex={2}
            transition="0.2s opacity ease-in-out"
            opacity={0}
            _hover={{
              opacity: 1,
            }}
          >
            <SearchIcon width={16} />
          </Flex>
        </Box>
      )}
      {mappedSlides.length > 0 && (
        <FullScreenSlideShow
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          galleryIndex={galleryIndex}
          slides={mappedSlides}
          onImageClick={onImageClick}
          showCaption={true}
        />
      )}
    </Grid>
  );
};

export default ReferenceItem;
