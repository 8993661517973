import { Box, Grid, Heading, Icon, Link, LinkProps, SimpleGrid, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { BlockContent } from 'jexity/packages/components/block-content/BlockContent';
import { BlockContentSerializer } from 'jexity/packages/components/block-content/serializers';
import { Container } from 'jexity/packages/components/container/Container';
import NextLink from 'next/link';
import { SectionContainer } from 'jexity/packages/sections/common/SectionContainer';
import React, { FC } from 'react';
import { CtaBannerLink } from '../../components/cta-banner-link/CtaBannerLink';
import { ShowRoomSection } from '../../feature/page/content/home-page/home';
import { BaseSectionProps } from '../common/sectionsApi';
import { useTranslationsContext } from '../../theme/translations/TranslationContext';
import { RightArrow } from '../../theme/icons';
import QRCodeWithSocial from './QRCodeWithSocial';
import { getInternalLink, useSiteSettingsContext } from 'jexity/packages/feature/common';
import { team } from 'src/feature/page/content/about-us-page/aboutUsPage';
import { Map } from 'src/components/map/Map';
import { isBlockContent, isValidCta } from 'jexity/packages/components/block-content/blockContentUtils';
import { DEFAULT_LANGUAGE } from '../../theme/languages';
import { useRouter } from 'next/router';

export interface ShowroomSectionProps extends BaseSectionProps<ShowRoomSection> {
  serializer?: Partial<BlockContentSerializer>;
  _type: 'homePage' | 'contactUsPage';
}

const ShowroomSection: FC<ShowroomSectionProps> = ({ _type, data }) => {
  const { siteSettings, companyInfo } = useSiteSettingsContext();
  const theme = useBaseTheme();
  const headerHeight = theme.headerHeight;
  const { translations } = useTranslationsContext();
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { [_type]: localizedSection } = translations ?? {};
  const { addressLabel, emailLabel } = localizedSection?.showroomSection ?? {};
  const { paragraph, sections, linkStyle } = theme;
  const { sectionWrapper } = sections?.showroomSection ?? {};
  const { cta, imageOverlayTitle, imageOverlayLink, title } = data;
  const { email, street, zip, city } = companyInfo;

  const DetailRow: FC<{ title: string }> = ({ title, children }) => (
    <Box d="flex" flexDir="column" mb={['10px', null, null, '1rem', '2rem']}>
      <Heading textTransform="uppercase" {...paragraph?.body5} color="#d1d1d1">
        {title}
      </Heading>
      <Box mt="9px">{children}</Box>
    </Box>
  );

  return (
    <SectionContainer {...sectionWrapper} mt="2.5rem">
      <Container variant="normal">
        <Box mb={['1.875rem', null, null, '2.5rem']}>{isBlockContent(title) && <BlockContent blocks={title} />}</Box>
        <Grid gridGap="2em" gridTemplateColumns={['1fr', null, '35% 1fr', null, '1fr 1fr']}>
          <Box>
            <Box>
              <DetailRow title={addressLabel ?? 'address'}>
                <Text {...paragraph?.body5}>{`${street}, ${zip} ${city}`}</Text>
              </DetailRow>
              <DetailRow title={emailLabel ?? 'email'}>
                <Link
                  href={`mailto:${email}`}
                  {...(paragraph?.body5 as LinkProps)}
                  mb={['1.875rem', null, null, '2.5rem']}
                  transition="color 0.2s ease-in"
                  _hover={{ color: 'brand.primary.500', textDecor: 'none' }}
                >
                  {email}
                </Link>
              </DetailRow>
              <SimpleGrid gridTemplateColumns={'repeat( auto-fit, 1fr )'}>
                {_type === 'contactUsPage'
                  ? team
                      .filter((m) => m.name !== 'Mihail Toshkov') // Omit Mihail for the footer.
                      .map((member) => <QRCodeWithSocial key={member.name} {...member} />)
                  : team
                      .filter((m) => m.name !== 'Mihail Toshkov') // Omit Mihail for the footer.
                      .map(({ position, ...member }) => member) // Omit `position`
                      .map((member) => <QRCodeWithSocial key={member.name} {...member} />)}
              </SimpleGrid>
              {!!cta && isValidCta(cta) && (
                <Box mt={['2rem', null, null, '2.5rem']} d="inline-flex">
                  <NextLink
                    {...getInternalLink({
                      startPageId: siteSettings.startPage?._id,
                      pageId: cta.reference._id,
                      _type: cta.reference._type,
                      slug: cta.reference.slug,
                    })}
                    locale={locale}
                    passHref
                  >
                    <Link {...linkStyle}>
                      <Text
                        {...paragraph?.body4}
                        textTransform="uppercase"
                        color="inherit"
                        fontFamily="heading"
                        fontWeight={400}
                        letterSpacing="2px"
                      >
                        {cta.label}
                        <Icon as={RightArrow} ml={2} width={['16px']} height={['14px']} />
                      </Text>
                    </Link>
                  </NextLink>
                </Box>
              )}
            </Box>
          </Box>
          <Box data-name="sticky">
            <Box
              position="sticky"
              top={headerHeight?.map((h) => `calc(${h}px + 16px)`)}
              h={{
                base: '20rem',
                md: '30rem',
                xl: '35rem',
                '2xl': '45rem',
              }}
              w={{
                base: 'calc(100vw - 40px)',
                sm: '100%',
              }}
            >
              <Map
                location={{
                  lat: 51.1906335,
                  lng: 6.9296878,
                }}
              />

              {!!imageOverlayLink && imageOverlayLink.href && (
                <Box pos="absolute" bottom={0} left={0} zIndex={2}>
                  <CtaBannerLink
                    d="flex"
                    flexDir="column"
                    alignItems="start"
                    padding={['8px 23px 21px']}
                    externalLink={imageOverlayLink.href}
                    cta={null}
                    target={imageOverlayLink.blank ? '_blank' : '_self'}
                  >
                    <Text
                      fontSize="md"
                      fontFamily="heading"
                      lineHeight={[1.4, null, null, '3rem']}
                      textTransform="uppercase"
                      fontWeight={400}
                      whiteSpace="normal"
                      textAlign="left"
                      my={['1rem', null, null, 0]}
                    >
                      {imageOverlayTitle}
                    </Text>
                    <Text
                      fontSize="md"
                      lineHeight="18.75px"
                      fontWeight={400}
                      whiteSpace="normal"
                      textAlign="left"
                      textTransform="none"
                    >{`${street}, ${zip} ${city}`}</Text>
                  </CtaBannerLink>
                </Box>
              )}
            </Box>
            {/* )} */}
          </Box>
        </Grid>
      </Container>
    </SectionContainer>
  );
};

export default ShowroomSection;
