import { Box, Link, Text } from '@chakra-ui/react';
import { Container } from 'jexity/packages/components/container/Container';
import { MobileMenuProps } from 'jexity/packages/components/header/headerApi';
import { MotionBox, MotionList, MotionListItem } from 'jexity/packages/components/motion/Motion';
import { FC } from 'react';
import NextLink from 'next/link';
import { getPrimaryMenuList } from '../../../../theme/menu/menuListItem';
import { MenuModalDetails } from './MenuModalDetails';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Variants } from 'framer-motion';
import { DEFAULT_LANGUAGE } from '../../../../theme/languages';
import { useRouter } from 'next/router';
import NextImage from 'next/image';

const coverVariants: Variants = {
  open: {
    height: '100%',
    opacity: 1,
    transition: { delay: 0.2, duration: 0.3, easings: 'easeIn' },
  },
  close: { height: '0', opacity: 0, transition: { delay: 0.2, duration: 0.3, easings: 'easeOut' } },
};

const listItemVariants: Variants = {
  show: { scale: 1, opacity: 1 },
  hidden: { scale: 0.7, opacity: 0 },
};

export const MobileMenuModal: FC<Pick<MobileMenuProps, 'open' | 'setOpen'>> = ({ open, setOpen }) => {
  const { headerHeight, headings } = useBaseTheme();
  const { locale = DEFAULT_LANGUAGE, asPath } = useRouter();
  const localizedMenuList = getPrimaryMenuList(locale);

  return (
    <Box pos="absolute" top={headerHeight} left={0} w="100%" h={headerHeight?.map((h) => `calc(100% - ${h}px)`)}>
      <MotionBox
        pos="relative"
        variants={coverVariants}
        height={0}
        opacity={0}
        overflowY="auto"
        overflowX="hidden"
        initial="close"
        animate={open ? 'open' : 'close'}
      >
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          d="grid"
          gridTemplateRows={['auto', null, null, null, '1fr 1fr 1fr']}
        >
          <Container pos="relative" bgColor="brand.primary.600">
            <Box pos="absolute" top={0} left={0} w="100%" h="100%">
              <NextImage
                layout="fill"
                src="/assets/png/menu/menu-background.png"
                alt="Carbon"
                objectFit="cover"
                objectPosition="center center"
              />
            </Box>
            <MotionList
              variants={{
                show: {
                  transition: { staggerChildren: 0.08, delayChildren: 0.2 },
                },
                hidden: {
                  transition: {
                    staggerChildren: 0.08,
                    delayChildren: 0,
                    staggerDirection: -1,
                  },
                },
              }}
              initial="hidden"
              animate={open ? 'show' : 'hidden'}
              p={0}
              m={0}
              styleType="none"
              w="100%"
              h="100%"
              pt="2.3125rem"
            >
              {localizedMenuList.map(({ title, link }, idx) => (
                <MotionListItem variants={listItemVariants} key={`menu-item-${idx}`}>
                  <NextLink {...link}>
                    <Link
                      pos="relative"
                      alignItems="center"
                      w="100%"
                      textTransform="uppercase"
                      fontFamily="heading"
                      role="group"
                      transition="color .3s ease-in"
                      color={asPath === link.href.toString() ? 'brand.primary.500' : 'brand.textColor'}
                      p={0}
                      _hover={{
                        textDecor: 'none',
                        color: 'brand.primary.500',
                      }}
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => setOpen(false)}
                    >
                      <Text as="span" d="block" {...headings?.h4} pb="1.75rem" color="inherit">
                        {title}
                      </Text>
                    </Link>
                  </NextLink>
                </MotionListItem>
              ))}
            </MotionList>
          </Container>

          <Container pb={16} bgColor="brand.primary.600">
            <MenuModalDetails py={'1.625rem'} setOpen={setOpen} />
          </Container>
        </Box>
      </MotionBox>
    </Box>
  );
};
