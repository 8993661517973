import { Box, BoxProps, IconButton, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import React, { FC } from 'react';
import ChevronIcon from 'src/theme/icons/ChevronIcon';

export interface SlideshowPaginationProps extends BoxProps {
  data: unknown[];
  activeItem: number;
  setActiveItem: (index: number) => unknown;
}

const SlideshowPagination: FC<SlideshowPaginationProps> = ({ data, activeItem, setActiveItem, ...others }) => {
  const { paragraph } = useBaseTheme();
  const length = data.length;
  const canGoBack = activeItem - 1 >= 0;
  const canGoNext = activeItem + 1 < length;

  return (
    <Box
      d="flex"
      justifyContent={['space-between', null, 'center']}
      alignItems="center"
      marginTop={4}
      zIndex={20}
      {...others}
    >
      <IconButton
        onClick={() => canGoBack && setActiveItem(activeItem - 1)}
        aria-label="Previous button"
        bg="transparent"
        icon={<ChevronIcon transform="rotate(180deg)" color="white" />}
        _active={{ bg: 'transparent' }}
        _hover={{ bg: 'transparent' }}
      />
      <Box d={'flex'} flexDir="row">
        <Text {...paragraph?.body4} color="white" fontWeight={400}>
          {activeItem + 1}
        </Text>
        <Text {...paragraph?.body4} color="white" fontWeight={400}>
          /{length}
        </Text>
      </Box>
      <IconButton
        onClick={() => canGoNext && setActiveItem(activeItem + 1)}
        aria-label="Previous button"
        bg="transparent"
        _active={{ bg: 'transparent' }}
        _hover={{ bg: 'transparent' }}
        icon={<ChevronIcon color="white" />}
      />
    </Box>
  );
};

export default SlideshowPagination;
