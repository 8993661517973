import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { useFormContext } from 'jexity/packages/components/form/useFormContext';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { DEFAULT_LANGUAGE } from '../../../theme/languages';
import { CUBIC_CAPACITY_OPTIONS_DE, CUBIC_CAPACITY_OPTIONS_EN } from '../../../theme/request-car-options/cubicMeasures';
import { TRANSMISSION_OPTIONS_DE, TRANSMISSION_OPTIONS_EN } from '../../../theme/request-car-options/transmission';
import { useTranslationsContext } from '../../../theme/translations/TranslationContext';

export const PickCubicContent: FC = () => {
  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const { headings, paragraph } = useBaseTheme();
  const [cubicValue, setCubicValue] = useState('');
  const [transmission, setTransmission] = useState('');
  const { setFormAdditionalValues } = useFormContext();
  const { translations } = useTranslationsContext();
  const { requestCarPage } = translations ?? {};
  const { pickCubicLabel, pickCubicTitle, pickTransmissionLabel } = requestCarPage?.requestCarFormSection ?? {};

  const isDE = locale === 'de';
  const cubicCapacityOptions = isDE ? CUBIC_CAPACITY_OPTIONS_DE : CUBIC_CAPACITY_OPTIONS_EN;
  const transmissionOptions = isDE ? TRANSMISSION_OPTIONS_DE : TRANSMISSION_OPTIONS_EN;

  useEffect(() => {
    if (cubicCapacityOptions.length > 0) {
      setFormAdditionalValues((prevForm) => ({ ...prevForm, cubicCapacity: cubicCapacityOptions[0] }));
    }
  }, [cubicCapacityOptions, setFormAdditionalValues]);

  useEffect(() => {
    if (transmissionOptions.length > 0) {
      setFormAdditionalValues((prevForm) => ({ ...prevForm, transmission: transmissionOptions[0] }));
    }
  }, [transmissionOptions, setFormAdditionalValues]);

  return (
    <Box minH="420px">
      <Box maxWidth={545} textAlign={'center'} margin={'0 auto'} mb={[8, null, 16]}>
        <Heading as="h5" {...headings?.h5} textTransform="uppercase">
          {pickCubicTitle}
        </Heading>
      </Box>
      <Flex flexDir={['column', null, 'row']} justifyContent="center">
        <Box mr={[0, null, '2rem']} mb={['2rem', null, 0]}>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickCubicLabel}
          </Text>
          <Select
            minW="256px"
            onChange={(event) => {
              setCubicValue(event.target.value);
              setFormAdditionalValues((prev) => ({ ...prev, cubicCapacity: event.target.value }));
            }}
            fontSize="md"
            mt={2}
            color="gray.900"
            maxW={['100%', null, '256px']}
            size="lg"
            borderRadius={0}
            icon={<ChevronDownIcon />}
            value={cubicValue}
            bg="white"
          >
            {cubicCapacityOptions.map((cubic, idx) => (
              <option value={cubic} key={`cubic-options-${idx}`}>
                {cubic}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text {...paragraph?.body4} fontWeight={700}>
            {pickTransmissionLabel}
          </Text>
          <Select
            minW="256px"
            onChange={(event) => {
              setTransmission(event.target.value);
              setFormAdditionalValues((prev) => ({ ...prev, transmission: event.target.value }));
            }}
            fontSize="md"
            mt={2}
            color="gray.900"
            maxW={['100%', null, '256px']}
            size="lg"
            borderRadius={0}
            icon={<ChevronDownIcon />}
            value={transmission}
            bg="white"
          >
            {transmissionOptions.map((transmission, idx) => (
              <option value={transmission} key={`transmission-options-${idx}`}>
                {transmission}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
    </Box>
  );
};
