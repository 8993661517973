import React, { Children, Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { Box, BoxProps, Flex, Grid, Icon } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import { animated as a, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { useInView } from 'react-intersection-observer';
import { useDrag } from 'react-use-gesture';

const AnimatedBox = a(Box);

export interface SwipeableSingleProps extends Omit<BoxProps, 'transitionDelay'> {
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<SwipeableSingleProps['activeIndex']>>;
  spacing?: number;
  fade?: boolean;
  autoPlay?: boolean;
  hasControls?: boolean;
  skipHeightAnimation?: boolean;
  shouldDrag?: boolean;
  transitionDelay?: number;
}

export const SwipeableSingle: FC<SwipeableSingleProps> = ({
  activeIndex,
  setActiveIndex,
  children,
  spacing = 50,
  fade,
  autoPlay = false,
  hasControls = false,
  skipHeightAnimation = false,
  shouldDrag = false,
  transitionDelay = 5000,
  ...other
}) => {
  const [activeItemMeasureRef, { height, width }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const iconSize = ['40px', null, null, null, '70px'];
  const iconStyle = {
    m: [1, null, null, null, 5],
    cursor: 'pointer',
    transition: 'transform .25s ease-out',
    _hover: {
      transform: 'scale(1.2)',
    },
  };

  const threshold = width / 2;
  const delta_t = useRef(0);

  const containerHeightSpring = useSpring({ height });

  const [containerSpring, setContainerSpring] = useSpring(() => ({
    transform: `translateX(${width * activeIndex * -1 + (activeIndex ? spacing * activeIndex : 0)}px)`,
  }));

  useEffect(() => {
    setContainerSpring({
      transform: `translateX(${width * activeIndex * -1 - (activeIndex ? spacing * activeIndex : 0)}px)`,
    });
  }, [activeIndex, setContainerSpring, spacing, width]);

  const bind = useDrag(({ last, movement: [mx], velocity, elapsedTime, direction: [x, y], cancel }) => {
    const currentPosition = width * activeIndex * -1 - (activeIndex ? spacing * activeIndex : 0);
    const moveX = mx + currentPosition;

    if (Math.abs(x) > Math.abs(y)) {
      // Figure out how to completely disable scroll here
    }

    if (Math.abs(x) < Math.abs(y)) {
      const currentPosition = width * activeIndex * -1 - (activeIndex ? spacing * activeIndex : 0);
      setContainerSpring({
        transform: `translateX(${currentPosition}px)`,
      });

      if (cancel) {
        cancel();
      }

      return;
    }

    setContainerSpring({
      transform: `translateX(${moveX}px)`,
    });

    delta_t.current = elapsedTime;

    if (last) {
      const currentPosition = width * activeIndex * -1 - (activeIndex ? spacing * activeIndex : 0);
      let didSlide;
      const absoluteMovement = Math.abs(mx);

      if ((velocity > 0.2 || absoluteMovement > threshold) && Math.abs(x) > Math.abs(y)) {
        if (x < 0 || mx < 0) {
          nextSlide(1);
          didSlide = true;
        } else if (x > 0 || mx > 0) {
          prevSlide(1);
          didSlide = true;
        }
      } else {
        setContainerSpring({
          transform: `translateX(${currentPosition}px)`,
        });
        delta_t.current = elapsedTime;
      }

      if (!didSlide) {
        setContainerSpring({
          transform: `translateX(${currentPosition}px)`,
        });
        delta_t.current = elapsedTime;
      }
    }
  });

  const childrenArray = Children.toArray(children);
  const childrenArrayLength = childrenArray.length;
  const nextSlide = useCallback(
    (numberOfSlides = 1) => {
      if (activeIndex < childrenArrayLength - 1) {
        let target = activeIndex + numberOfSlides;
        if (target > childrenArrayLength - 1) {
          target = childrenArrayLength - 1;
        }
        setActiveIndex(target);
      } else {
        setActiveIndex(0);
      }
    },
    [activeIndex, childrenArrayLength, setActiveIndex]
  );

  const prevSlide = (numberOfSlides = 1) => {
    if (activeIndex > 0) {
      let target = activeIndex - numberOfSlides;

      if (target < 0) {
        target = 0;
      }
      setActiveIndex(target);
    } else {
      setActiveIndex(childrenArrayLength - 1);
    }
  };

  useEffect(() => {
    const intervalSlide = setInterval(() => {
      if (autoPlay && inView) {
        /**
         * This is the next slide function.
         * Moved inside to remove warnings
         */
        nextSlide();
        /**
         * End of nextSlide function
         */
      }
    }, transitionDelay);

    return () => {
      clearInterval(intervalSlide);
    };
  }, [activeIndex, inView, autoPlay, setActiveIndex, childrenArrayLength, transitionDelay, nextSlide]);

  return (
    <AnimatedBox
      ref={ref}
      pos="relative"
      w="100%"
      overflow="hidden"
      style={
        skipHeightAnimation
          ? {
              height: height,
            }
          : containerHeightSpring
      }
      pointerEvents={childrenArrayLength === 1 ? 'none' : 'initial'}
      {...(shouldDrag ? bind() : {})}
      {...other}
    >
      <AnimatedBox pos="absolute" top={0} left={0} h="100%" w="100%" style={{ ...containerSpring }}>
        {childrenArray.map((item, index) => {
          return (
            <Box
              key={index}
              ref={activeIndex === index ? activeItemMeasureRef : null}
              w="100%"
              pos="absolute"
              top={0}
              style={{
                left: width * index + (index ? spacing * index : 0),
              }}
            >
              {item}
            </Box>
          );
        })}
      </AnimatedBox>
      {childrenArrayLength > 1 && hasControls && (
        <Grid pos="absolute" top={0} left={0} h="100%" w="100%" alignContent="center">
          <Flex justifyContent="space-between">
            <Box w={iconSize} {...iconStyle}>
              <Icon name="swipeable-left-chevron" w={iconSize} h={iconSize} onClick={() => prevSlide(1)} />
            </Box>
            <Box
              w={iconSize}
              transform="rotate(180deg)"
              {...iconStyle}
              _hover={{ transform: 'scale(1.2) rotate(180deg)' }}
            >
              <Icon name="swipeable-left-chevron" w={iconSize} h={iconSize} onClick={() => nextSlide(1)} />
            </Box>
          </Flex>
        </Grid>
      )}
    </AnimatedBox>
  );
};
