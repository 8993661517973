import { BaseIconSerializer } from 'jexity/packages/icons';

import { navbarLogoDesktop } from './navbar-logo-desktop';
import { navbarLogo } from './navbar-logo';
import { facebook } from './facebook';
import { instagram } from './instagram';
import { vodafoneIcon } from './vodafoneIcon';
import { rightArrow } from './right-arrow';
import { phone } from './phone';
import { evelanLogo } from './evelanLogo';
import { Car } from './car';
import { Menu } from './menu';
import { EnglishLanguage } from './english-language';
import { DeLanguage } from './de-language';
import { listIcon } from './list-icon';
import { orderProcessCalendar } from './order-process/orderProcessCalendar';
import { orderProcessCar } from './order-process/orderProcessCar';
import { orderProcessConfiguration } from './order-process/orderProcessConfiguration';
import { orderProcessCurrency } from './order-process/orderProcessCurrency';
import { orderProcessPhone } from './order-process/orderProcessPhone';
import { leftArrow } from './left-arrow';
import { ChevronDownFilled } from './chevron-down-filled';
import { externalLink } from './externalLink';
import { bulletList } from './bulletList';
import { galleryContinuous } from './galleryContinuous';
import { galleryGrid } from './galleryGrid';
import { galleryFullView } from './galleryFullView';
import { envelope } from './envelope';
import { garage } from './garage';
import { createIcon } from '@chakra-ui/icon';
import { lifeIcon } from './lifeIcon';

export const iconSerializer = {
  ...BaseIconSerializer,
  instagram: {
    path: instagram,
    viewBox: '0 0 16 16',
  },
  facebook: {
    path: facebook,
    viewBox: '0 0 16 16',
  },
  vodafone: {
    path: vodafoneIcon,
    viewBox: '0 0 77 20',
  },
  'chevron-down-filled': {
    path: ChevronDownFilled,
    viewBox: '0 0 20 20',
  },
  evelan: {
    path: evelanLogo,
    viewBox: '0 0 70 10',
  },
};

export const OrderProcessCalendar = createIcon({
  path: orderProcessCalendar,
  viewBox: '0 0 64 64',
});

export const GarageIcon = createIcon({
  path: garage,
  viewBox: '0 0 60 60',
});

export const LifeIcon = createIcon({
  path: lifeIcon,
  viewBox: '0 0 60 60',
});

export const Envelope = createIcon({
  path: envelope,
  viewBox: '0 0 24 24',
});

export const OrderProcessCar = createIcon({
  path: orderProcessCar,
  viewBox: '0 0 60 38',
});

export const OrderProcessCurrency = createIcon({
  path: orderProcessCurrency,
  viewBox: '0 0 64 64',
});

export const OrderProcessConfiguration = createIcon({
  path: orderProcessConfiguration,
  viewBox: '0 0 64 64',
});

export const OrderProcessPhone = createIcon({
  path: orderProcessPhone,
  viewBox: '0 0 64 64',
});

export const NavbarLogo = createIcon({
  path: navbarLogo,
  viewBox: '0 0 108 18',
});

export const NavbarLogoDesktop = createIcon({
  path: navbarLogoDesktop,
  viewBox: '0 0 194 32',
});

export const ListIcon = createIcon({
  path: listIcon,
  viewBox: '0 0 16 16',
});

export const RightArrow = createIcon({
  path: rightArrow,
  viewBox: '0 0 16 15',
});

export const LeftArrow = createIcon({
  path: leftArrow,
  viewBox: '0 0 16 15',
});

export const Phone = createIcon({
  path: phone,
  viewBox: '0 0 14 14',
});

export const CarIcon = createIcon({
  path: Car,
  viewBox: '0 0 24 24',
});

export const MenuIcon = createIcon({
  path: Menu,
  viewBox: '0 0 24 24',
});

export const ExternalLink = createIcon({
  path: externalLink,
  viewBox: '0 0 24 24',
});

export const GalleryContinuous = createIcon({
  path: galleryContinuous,
  viewBox: '0 0 28 28',
});

export const GalleryGrid = createIcon({
  path: galleryGrid,
  viewBox: '0 0 28 28',
});

export const GalleryFullview = createIcon({
  path: galleryFullView,
  viewBox: '0 0 28 27',
});

export const BulletListIcon = createIcon({
  path: bulletList,
  viewBox: '0 0 20 20',
});

export const EnglishLanguageIcon = createIcon({
  path: EnglishLanguage,
  viewBox: '0 0 28 20',
});

export const DeLanguageIcon = createIcon({
  path: DeLanguage,
  viewBox: '0 0 28 20',
});
