import { Box, Heading, Icon, List, ListItem, ListProps, SimpleGrid, Text } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import React, { FC } from 'react';
import { FluidPictureAsset, isSanityImageAsset } from 'sanity-picture-asset';
import Floor from 'src/components/Floor';
import { BulletListIcon, GarageIcon, LifeIcon } from 'src/theme/icons';
import PricingPackages from './PricingPackages';
import FreeSpots from './FreeSpots';
import { useTranslationsContext } from 'src/theme/translations/TranslationContext';
import { BaseContentPageProps } from '../../contentPageSerializer';
import { VehicleStorageDocument } from './vehicleStorage';
import InfoBox from '../../../../components/info-box/InfoBox';

const VehicleStoragePage: FC<BaseContentPageProps<VehicleStorageDocument>> = ({ content }) => {
  const { headerHeight, headings, paragraph } = useBaseTheme();
  const { translations } = useTranslationsContext();
  const vehicleStoragePage = translations?.vehicleStoragePage;
  const {
    professional,
    vehicleStorage,
    highestSecurity,
    airConditionedStorage,
    discreet,
    why,
    maximumPerformance,
    whyMaximumPerformance,
  } = translations?.vehicleStoragePage.heroSection ?? {};

  const { firstImage, secondImage, thirdImage } = content;
  const firstSectionItems = [highestSecurity, airConditionedStorage, discreet].filter(Boolean);

  return (
    <Box pos="relative">
      <Box pos="relative">
        <Floor
          pos="absolute"
          w={['400vw', null, null, '150vw']}
          maxW="3200px"
          h="auto"
          bottom="0"
          transform="translateY(50%)"
        />
        {/* First section */}
        <Container zIndex={2} pos="relative" pt={headerHeight}>
          <SimpleGrid pt={20} gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="2rem">
            <Box>
              <Heading
                as="h6"
                {...headings?.h6}
                textTransform="uppercase"
                mb={4}
                letterSpacing="0.125rem"
                fontWeight="400"
              >
                {vehicleStorage}
              </Heading>
              <Heading as="h1" {...headings?.h1} style={{ hyphens: 'auto' }}>
                <Text as="span" color="brand.primary.500">
                  {professional}
                </Text>
                <br />
                {vehicleStorage}
              </Heading>
              <List {...(headings?.h5 as ListProps)} pt={6}>
                {firstSectionItems.map((item) => (
                  <ListItem key={item + '_key'} mb={2} d="flex" alignItems="center">
                    <Icon as={BulletListIcon} color="brand.primary.500" display="block" mr={2} />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box filter="drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.25))">
              {isSanityImageAsset(firstImage) && (
                <FluidPictureAsset image={firstImage} aspectRatio={1.2} alt={firstImage.alt ?? 'Sportwagen'} />
              )}
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        pos="relative"
        zIndex={1}
        pb={{
          base: '2.5rem',
          md: 16,
        }}
        pt={{
          base: '9.5rem',
          lg: '14.1875rem',
        }}
      >
        <Container pos="relative">
          <SimpleGrid gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="2rem" alignItems="center">
            <Box filter="drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.25))">
              {isSanityImageAsset(secondImage) && (
                <FluidPictureAsset image={secondImage} aspectRatio={1.2} alt={secondImage.alt ?? 'Sportwagen'} />
              )}
            </Box>
            <Box
              gridRow={{
                base: 1,
                md: 'inherit',
              }}
            >
              <Heading as="h2" {...headings?.h2} fontSize={['4xl', null, null, '6xl']} mb={4}>
                {why}{' '}
                <Text as="span" color="brand.primary.500">
                  {maximumPerformance}?
                </Text>
              </Heading>
              <Text {...paragraph?.body4} lineHeight="24px">
                {whyMaximumPerformance}
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        pos="relative"
        zIndex={1}
        pb={{
          base: 0,
          md: 16,
        }}
        pt={{
          base: 0,
          md: 8,
        }}
      >
        <Floor
          pos="absolute"
          w={['400vw', null, null, '150vw']}
          maxW="140.625rem"
          h="auto"
          bottom="0"
          transform="translateY(50%)"
        />
        <Container pos="relative" zIndex="2">
          <SimpleGrid
            gridTemplateColumns={['1fr', null, null, '1fr 1fr']}
            gridGap="2rem"
            alignItems={{ base: 'normal', md: 'stretch' }}
          >
            <InfoBox
              title={vehicleStoragePage?.attributeSection.professionalStorage.title}
              content={vehicleStoragePage?.attributeSection.professionalStorage.content}
              icon={GarageIcon}
            />
            <InfoBox
              title={vehicleStoragePage?.attributeSection.security.title}
              content={vehicleStoragePage?.attributeSection.security.content}
              icon={LifeIcon}
            />
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        pos="relative"
        zIndex={1}
        pb={{
          base: 0,
          md: 16,
        }}
        pt={{
          base: '9.5rem',
          md: 40,
        }}
      >
        <Container pos="relative" zIndex="2">
          <SimpleGrid gridTemplateColumns={['1fr', null, null, '1fr 1fr']} gridGap="2rem" alignItems="center">
            <Box>
              <Heading {...headings?.h1}>{vehicleStoragePage?.openingHours}</Heading>
              <Text>{vehicleStoragePage?.otherDatesByArrangement}</Text>
            </Box>
            <Box>
              {isSanityImageAsset(thirdImage) && (
                <FluidPictureAsset image={thirdImage} aspectRatio={1.5} alt={thirdImage.alt ?? 'Sportwagen'} />
              )}
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      <PricingPackages />
      <FreeSpots />
    </Box>
  );
};

export default VehicleStoragePage;
