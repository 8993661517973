import { Box, Heading, Link } from '@chakra-ui/react';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { Container } from 'jexity/packages/components/container/Container';
import React, { FC } from 'react';
import { BaseContentPageProps } from '../contentApi';
import { PartnersDocument } from './partners';
import Image from 'next/image';
import CtaSection from 'src/sections/cta-section/CtaSection';
import BlockTextSection from '../../../../sections/block-text-section/BlockTextSection';
import { EEMFS } from 'src/theme/icons/eemfs';
import { isBlockContent } from 'jexity/packages/components/block-content/blockContentUtils';
import { SplitText } from 'src/components/SplitText';
import { MotionBox } from 'src/components/animation/MotionBox';
import { BlurBackgroundImage } from 'src/components/blur-background-image/BlurBackgroundImage';

const iconStyles = {
  cursor: 'pointer',
  transition: '.4s transform ease-in',
  _hover: {
    transform: 'scale(1.3)',
  },
};

const PartnersPage: FC<BaseContentPageProps<PartnersDocument>> = ({ content }) => {
  const { headings } = useBaseTheme();
  const { ctaSection, title } = content ?? {};

  return (
    <Box pos="relative">
      <BlurBackgroundImage
        desktopImage="/assets/png/pages/commission-sales.png"
        mobileImage="/assets/png/pages/commission-sales-mobile.png"
      />
      <Box>
        <Container zIndex={2} pos="relative" pt="9.75rem">
          {isBlockContent(title) && <BlockTextSection data={{ title }} />}

          <Box d="grid" placeItems="center" mt="3rem">
            <>
              <MotionBox
                initial={{
                  opacity: 0,
                  rotate: -720,
                  scale: 0,
                }}
                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                transition={{ duration: 1.75, easings: 'easeInOut' }}
              >
                <EEMFS />
              </MotionBox>
              <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Heading as="h4" {...headings?.h4} mt="2rem" color="#857550" textAlign="center">
                  <SplitText
                    initial={{ y: '100%' }}
                    animate="visible"
                    variants={{
                      visible: (i: number) => ({
                        y: 0,
                        transition: {
                          delay: i * 0.15 + 2,
                        },
                      }),
                    }}
                    transition={{ duration: 0.3, easings: 'easeIn' }}
                  >
                    Lama holding coming soon ...
                  </SplitText>
                </Heading>
              </MotionBox>
            </>
          </Box>

          <Box
            d={{
              base: 'inherit',
              sm: 'flex',
            }}
            textAlign="center"
            alignItems="center"
            justifyContent="space-evenly"
            alignContent="center"
            mt={{
              base: '4.5rem',
              sm: '5.875rem',
            }}
          >
            <Box
              p="5"
              mb={{
                base: '6.45875rem',
                sm: '0',
              }}
              {...iconStyles}
            >
              <Link isExternal={true} href="https://maximum-performance.luxury">
                <Image
                  src={'/assets/png/partners/mp-logo.png'}
                  width={275}
                  height={51.09}
                  alt="Maximum Performance Logo"
                />
              </Link>
            </Box>
            <Box
              p="5"
              mb={{
                base: '6.45875rem',
                sm: '0',
              }}
              {...iconStyles}
            >
              <Link isExternal={true} href="https://9punto17.com">
                <Image src={'/assets/png/partners/9punto17.png'} width={304.12} height={65.32} alt="9punto17 Logo" />
              </Link>
            </Box>
          </Box>

          <Box
            d={{
              base: 'inherit',
              sm: 'flex',
            }}
            justifyContent="space-around"
            alignItems="center"
            mt={{
              base: 'inherit',
              sm: '7rem',
            }}
            textAlign="center"
          >
            <Box
              p="5"
              mb={{
                base: '6.89875rem',
                sm: '0',
              }}
              {...iconStyles}
            >
              <Link isExternal={true} href="https://maffbags.com">
                <Image src={'/assets/png/partners/maff.png'} width={300} height={47.62} alt="Maff Logo" />
              </Link>
            </Box>
            <Box
              p="5"
              mb={{
                base: '1.25rem',
                sm: '0',
              }}
              {...iconStyles}
            >
              <Link isExternal={true} href="https://www.keyvany.de">
                <Image src={'/assets/png/partners/keyvany.png'} width={300} height={60} alt="Keyvany Logo" />
              </Link>
            </Box>
            <Box p="5" {...iconStyles}>
              <Link isExternal={true} href="https://www.fast-club.de">
                <Image src={'/assets/png/partners/fast-club.png'} width={352} height={130} alt="Fast Club Logo" />
              </Link>
            </Box>
          </Box>

          {ctaSection && <CtaSection data={ctaSection} />}
        </Container>
      </Box>
    </Box>
  );
};

export default PartnersPage;
