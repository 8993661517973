import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { SocialMediaList, SocialMediaListProps } from 'jexity/packages/components/social-media-list/SocialMediaList';

type FooterSocialMediaProps = Pick<SocialMediaListProps, 'socialMedia'>;

export const FooterSocialMedia: FC<FooterSocialMediaProps> = ({ socialMedia = [] }) => {
  return (
    <Box>
      <SocialMediaList
        socialMedia={socialMedia}
        showSocialMediaName={false}
        customIcons={['instagram', 'facebook']}
        iconWrapperStyles={{
          mr: ['3.75rem', null, 6],
          alignItems: 'center',
          transition: '.4s ease-out',
          _hover: {
            transform: 'scale(1.1)',
          },
          _focus: { boxShadow: 'none' },
        }}
        iconStyles={{
          w: ['20px'],
          h: ['20px'],
        }}
      />
    </Box>
  );
};
